import { Row, Col } from 'react-bootstrap';
import { Form, Input, Textarea, Upload, TextHeader, Select, Modal, Button, DatePicker, Checkbox } from 'components';
import { useTranslation } from 'react-i18next';
import { Rules, useAppSelector, } from 'hooks';
import { User, shareValue } from 'models';
import { HttpStatusCode, ModalSize, ValidatePattern } from 'utils';
import { account } from 'services';
import { useCallback, useEffect, useState } from 'react';
import { SocialMedia } from 'models/socialMedia';
import { FaTrashAlt } from 'react-icons/fa';
import { SOCIAL_MEDIA } from 'utils/constants/socialMedia';
import { UploadImageType } from 'utils/constants/uploadImageType';
import { ShareValueGroup } from 'utils/constants/shareValue';
import uuid from 'react-uuid';
import toast from 'utils/toast';
import countries from 'data/json/countries.json';
import shareValueService from 'services/shareValue';
import { useParams } from 'react-router-dom';

const GENDERS = [
  { label: 'ชาย', value: 'male' },
  { label: 'หญิง', value: 'female' },
];

interface Props {
  show: boolean;
  onClose: () => void;
}

export default function EditProfileModal(props: Props) {
  const { t } = useTranslation(['modal'], { keyPrefix: 'profile' });
  const { id } = useParams();
  const user = useAppSelector((state) => state.commonStore.user as User);
  const [socialMediaList, setSocialMediaList] = useState<SocialMedia[]>([]);
  const [age, setAge] = useState<string>('');
  const [provincialAthlete, setProvincialAthlete] = useState(false);
  const [memberTypeItems, setMemberTypeItems] = useState<shareValue[]>([]);
  const [formRules, setFormRules] = useState<Rules>({
    img: { required: true },
    firstName: { required: true },
    middleName: null,
    lastName: { required: true },
    nickname: null,
    desc: null,
    birthday: { required: true },
    age: { required: true, pattern: ValidatePattern.NUMBER },
    idCardNo: {
      required: true,
      idCardNoAndPassportNo: true,
      maxLength: 50,
      minLength: 13,
      pattern: ValidatePattern.NUMBER,
    },
    tel: { required: true, pattern: ValidatePattern.NUMBER },
    gender: { required: true },
    nationality: { required: true },
    provincialAthlete: null,
    provincialAthleteRegistrationDate: null,
    email: { pattern: ValidatePattern.EMAIL, required: true },
    discordId: { pattern: ValidatePattern.ENGLISH },
    steamId: { pattern: ValidatePattern.ENGLISH },
    psnId: { pattern: ValidatePattern.ENGLISH },
    channel: { required: true },
    channelName: { required: true },
    followers: { required: true, pattern: ValidatePattern.NUMBER },
    link: null,
    memberType: { required: true }
  });

  useEffect(() => {
    if (user?.socialMedia) {
      setSocialMediaList(user.socialMedia);
    }

    if (user?.birthday) {
      checkAge(user.birthday);
    }

    setProvincialAthlete(user?.provincialAthlete);
  }, [user]);

  useEffect(() => {
    getMemberTypeAsync();
  }, []);

  const handleOnSubmit = useCallback(async (data: User) => {
    data.id = id ?? data.id;

    const value = {
      ...user,
      ...data,
      provincialAthleteRegistrationDate: data.provincialAthleteRegistrationDate || null,
      provincialAthleteExpirationDate: data.provincialAthleteExpirationDate || null,
    };

    const response = await account.updateUserAsync(value);

    if (response.status === HttpStatusCode.OK) {
      toast.success('บันทึกข้อมูลสำเร็จ');
      props.onClose();
    }
  }, [socialMediaList]);

  const addSocialMedia = useCallback(() => {
    setSocialMediaList([...socialMediaList, { id: uuid() } as SocialMedia]);
  }, [socialMediaList]);

  const deleteSocialMedia = useCallback((id: string) => {
    const items = socialMediaList.filter(f => f.id !== id);

    setSocialMediaList(items);
  }, [socialMediaList]);

  const checkAge = (value: string) => {
    const birthdayYear = +value.split('-')[2];
    const yearNow = new Date().getFullYear();
    const age = yearNow - birthdayYear;

    setAge(age.toString());
  };

  const provincialAthleteOnChange = (value: boolean) => {
    setProvincialAthlete(value);

    if (value) {
      setFormRules({
        ...formRules,
        provincialAthleteRegistrationDate: { required: true },
      });
    } else {
      setFormRules({
        ...formRules,
        provincialAthleteRegistrationDate: { required: false },
      });
    }
  };

  const getMemberTypeAsync = async () => {
    const { data, status } = await shareValueService.getShareValueListAsync(ShareValueGroup.MemberType);

    if (status === HttpStatusCode.OK) {
      setMemberTypeItems(data);
    }
  };

  return (
    <Modal show={props.show} size={ModalSize.LG} closeButton onClose={() => props.onClose()}>
      <Form onSubmit={handleOnSubmit} rules={formRules}>
        <Modal.Body>
          <h4 className='title'>
            {t('editProfile')}
          </h4>
          <div className='my-4'>
            <TextHeader
              text={t('information')}
              position='center'
              size='sm'
              underline='center'
              width='large' />
          </div>
          <Upload value={user?.img} label={t('image')} name='img' type={UploadImageType.IMAGE_PROFILE} />
          <Row>
            <Col>
              <Select
                label={t('memberType')}
                name='memberType'
                items={memberTypeItems}
                value={user?.memberType}
                selectKey={{ label: 'label', value: 'value' }} />
            </Col>
            <Col lg={6}>
              <Input value={user?.firstName} label={t('firstName')} name='firstName' placeholder={t('firstName')} />
            </Col>
            <Col lg={6}>
              <Input value={user?.middleName} label={t('middleName')} name='middleName' placeholder={t('middleName')} />
            </Col>
            <Col lg={6}>
              <Input value={user?.lastName} label={t('lastName')} name='lastName' placeholder={t('lastName')} />
            </Col>
            <Col lg={6}>
              <Input value={user?.nickname} label={t('nickname')} name='nickname' placeholder={t('nickname')} />
            </Col>
            <Col xs={12}>
              <Textarea value={user?.desc} label={t('desc')} name='desc' placeholder={t('desc')} />
            </Col>
            <Col lg={6}>
              <DatePicker label={t('birthday')} name='birthday' value={user?.birthday} onChange={checkAge} />
            </Col>
            <Col lg={6}>
              <Input label='อายุ' name='age' value={age} />
            </Col>
            <Col lg={6}>
              <Input label='รหัสบัตรประชาชน/หนังสือเดินทาง' name='idCardNo' value={user?.idCardNo} />
            </Col>
            <Col lg={6}>
              <Input label='เบอร์โทร' name='tel' value={user?.tel} />
            </Col>
            <Col lg={6}>
              <Select
                value={user?.gender}
                label={t('gender')}
                name='gender'
                items={GENDERS} />
            </Col>
            <Col lg={6}>
              <Select
                value={user?.nationality}
                label={t('nationality')}
                name='nationality'
                items={countries}
                selectKey={{ label: 'name', value: 'code' }} />
            </Col>
            <Col lg={6}>
              <Checkbox label='เป็นนักกีฬาประจำจังหวัด' checked={user?.provincialAthlete} name='provincialAthlete' className='mt-4' onChange={provincialAthleteOnChange} />
            </Col>
            <Col lg={6}>
              <DatePicker label='วันที่ขึ้นทะเบียนนักกีฬาจังหวัด' name='provincialAthleteRegistrationDate' value={user?.provincialAthleteRegistrationDate ?? ''} disabled={!provincialAthlete} />
            </Col>
            <Col lg={6}>
              <DatePicker label='วันหมดอายุนักกีฬาจังหวัด' name='provincialAthleteExpirationDate' value={user?.provincialAthleteExpirationDate ?? ''} disabled={!provincialAthlete} />
            </Col>
            <Col lg={6}>
              <Input value={user?.email} label={t('email')} name='email' placeholder={t('email')} />
            </Col>
            <Col lg={6}>
              <Input value={user?.discordId} label={t('discordId')} name='discordId' placeholder={t('discordId')} />
            </Col>
            <Col lg={6}>
              <Input value={user?.steamId} label={t('steamId')} name='steamId' placeholder={t('steamId')} />
            </Col>
            <Col lg={6}>
              <Input value={user?.psnId} label={t('psnId')} name='psnId' placeholder={t('psnId')} />
            </Col>
          </Row>
          <div className='my-4'>
            <TextHeader
              text={t('socialMedia')}
              position='center'
              size='sm'
              underline='center'
              width='large'>
              <Button onClick={() => addSocialMedia()} type='button'>เพิ่มโซเชียลมีเดีย</Button>
            </TextHeader>
          </div>
          <>
            {socialMediaList.map((item, i) =>
              <Form.List key={i} name='socialMedia'>
                <Row>
                  <Col>
                    <Row>
                      <Col lg={3}>
                        <Select
                          value={item.channel}
                          label='ช่องทาง'
                          name='channel'
                          items={SOCIAL_MEDIA} />
                      </Col>
                      <Col lg={3}>
                        <Input
                          value={item.channelName}
                          label='ชื่อช่อง'
                          name='channelName' />
                      </Col>
                      <Col lg={3}>
                        <Input
                          value={item.followers}
                          label='จำนวนผู้ติดตาม'
                          name='followers'
                          type='number' />
                      </Col>
                      <Col lg={3}>
                        <Input
                          value={item.link}
                          label='Link'
                          name='link' />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={1}>
                    <Button
                      onClick={() => deleteSocialMedia(item.id)}
                      type='button'
                      className='delete-button'
                      variant='outline-primary'>
                      <FaTrashAlt />
                    </Button>
                  </Col>
                </Row>
              </Form.List>)}
          </>
        </Modal.Body>
        <Modal.Footer className='justify-content-between'>
          <Button type='button' variant='outline-light' onClick={() => props.onClose()}>{t('cancel')}</Button>
          <Button type='submit'>{t('save')}</Button>
        </Modal.Footer>
      </Form>
    </Modal >
  );
}