import { configureStore } from '@reduxjs/toolkit';
import formStore from '../formStore';

const formConfigure = configureStore({
  reducer: {
    formStore,
  },
});

export type FormState = ReturnType<typeof formConfigure.getState>;
export type FormDispatch = typeof formConfigure.dispatch;

export default formConfigure;