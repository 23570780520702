interface Props {
  title: string;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
}

export function HeadLine({ title, className, size }: Props) {

  const getSize = () => {
    switch (size) {
      case 'sm': return 'fs-6';
      case 'lg': return 'fs-4';
      default: return 'fs-5';
    }
  };

  return (
    <div className={`${className} header-title mb-3`}>
      <div className="content">
        <p className={`title mb-1 ${getSize()}`}>{title}</p>
        <div className='line' />
      </div>
    </div>
  );
}