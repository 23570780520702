import { Modal } from 'components';
import { set, HttpStatusCode } from 'utils';
import { ModalSize } from 'utils';
import { AssociationMatchType, Club, IAssociationTournamentResponse, TeamManagementResponse, User } from 'models';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from 'hooks';
import Team from './Team';
import { club as clubService, club } from '../../../services';
import toast from '../../../utils/toast';
import Single from './Single';

interface Props {
  type?: string;
  show: boolean;
  userData?: User;
  tournamentData: IAssociationTournamentResponse;
  gameName: string;
  onHide: () => void;
  onHideAfterSave: () => void;
}

export default function ModalEventsDetail(props: Props) {
  const [user, setUser] = useState<User>({} as User);
  const [clubData, setClubData] = useState<Club>({} as Club);
  const [teams, setTeams] = useState<TeamManagementResponse[]>([]);

  const dispatch = useAppDispatch();

  const clearState = () => {
    dispatch(set({ key: 'teamName', data: '' }));
    dispatch(set({ key: 'teamTag', data: '' }));
    dispatch(set({ key: 'tel', data: '' }));
    dispatch(set({ key: 'lineId', data: '' }));
    dispatch(set({ key: 'logoImage', data: '' }));
  };

  const onHideTeam = () => {
    clearState();

    props.onHideAfterSave();
  };

  const getUser = useCallback((userData: User) => {
    setUser(userData);
  }, []);

  const getClubByUserIdAsync = useCallback(async (userId: string) => {
    const { data, status } = await club.getClubByUserIdAsync(userId);

    if (status === HttpStatusCode.OK) {
      setClubData(data);

      await getTeamAsync(data.id ?? '');
    }
  }, []);

  const getTeamAsync = useCallback(async (clubId: string) => {
    const { data, status } = await clubService.getListTeamAsync(clubId);
    if (status !== HttpStatusCode.OK) {
      toast.error('เกิดข้อผิดพลาด ขณะดึงข้อมูลทีม');

      return;
    }

    setTeams(data);
  }, []);

  const onHide = () => {
    props.onHideAfterSave();

    window.location.reload();
  };

  useEffect(() => {
    if (props.userData && props.userData.id) {
      getUser(props.userData);
      getClubByUserIdAsync(props.userData.id);
    }
  }, [props.userData]);

  return (
    <Modal size={ModalSize.LG}
      show={props.show}
      onClose={() => props.onHide()}>
      <Modal.Body>
        {props.tournamentData.associationMatchType === AssociationMatchType.Single
          ? <Single
            tournamentData={props.tournamentData}
            onHide={() => props.onHideAfterSave()}
            onHideAfterSave={() => onHide()}
            userData={user} />
          : <Team
            gameName={props.gameName}
            club={clubData}
            teams={teams}
            onHide={() => onHideTeam()}
            onHideAfterSave={() => onHide()}
            tournamentData={props.tournamentData}
            userData={user}
          />
        }
      </Modal.Body>
    </Modal>
  );
}