import { Table, Pagination as PaginationComponent, TextHeader, Button } from "components";
import { RefereeUserHistoryData, User } from "models";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";
import { account } from "services";
import { HttpStatusCode, convertDateToFullDate } from "utils";
import { AddRefereeUserHistoryModal } from "./modals/AddRefereeUserHistoryModal";
import { useLoaderData } from "react-router-dom";
import { useUser } from "hooks";

export default function Referee() {
  const { t } = useTranslation('modal');
  const [refereeHistoryList, setRefereeHisotyList] = useState<RefereeUserHistoryData[]>([]);
  const [totalRow, setTotalRow] = useState(0);
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const loader = useLoaderData() as Loader;
  const user = useUser();

  type Loader = {
    user: User;
  };

  useEffect(() => {
    getRefereeHistoryListAsync();
  }, [page]);

  const getRefereeHistoryListAsync = async () => {
    const { data, status } = await account.getRefereeUserHistoryListAsync(loader.user.id, page, 10);

    if (status === HttpStatusCode.OK) {
      setRefereeHisotyList(data.rows);
      setTotalRow(data.totalRows);
    }
  };

  const onSaveAddRefereeUserHistory = () => {
    getRefereeHistoryListAsync();

    setShowModal(false);
  };

  return (
    <>
      <TextHeader
        text={t('competition.refereeUserHistory')}
        className='mt-5 mb-5'>
        <>{loader.user.id === user.data.id ?
          <Button
            variant='outline-light'
            onClick={() => setShowModal(true)}>
            <>
              <FaPlus className='me-2' />
              เพิ่มประวัติการตัดสิน
            </>
          </Button> :
          <></>}
        </>
      </TextHeader>
      <Table>
        <thead>
          <tr className='text-center'>
            <th>ชื่อการแข่งขัน</th>
            <th>เกมส์ที่ตัดสิน</th>
            <th>วันที่แข่งขัน</th>
            <th>สถานที่จัดการแข่งขัน</th>
            <th>ประเภทการแข่งขัน</th>
            <th>จำนวนทีม</th>
          </tr>
        </thead>
        <tbody>
          {refereeHistoryList?.map(data => (
            <tr key={data.id}>
              <td className='text-center'>{data.tournamentTitle}</td>
              <td className='text-center'>{data.gameName}</td>
              <td className='text-center'>{`${convertDateToFullDate(data.tournamentStartDate)} - ${convertDateToFullDate(data.tournamentEndDate)}`}</td>
              <td className='text-center'>{data.tournamentLocation}</td>
              <td className='text-center'>{data.tournamentType}</td>
              <td className='text-center'>{data.noOfCompetitors}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className='d-flex justify-content-center mt-3'>
        <PaginationComponent
          totalItems={totalRow}
          onChange={(value) => setPage(value)} />
      </div>
      <AddRefereeUserHistoryModal show={showModal} onClose={onSaveAddRefereeUserHistory} />
    </>
  );
}