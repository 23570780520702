import { Line } from 'components/Line';
import { Col, Row } from 'react-bootstrap';

interface Props {
  text: string;
  position?: 'center' | 'right';
  children?: JSX.Element | JSX.Element[];
  className?: string;
  underline?: 'left' | 'center' | 'right';
  width?: 'fit' | 'large';
  size?: 'sm' | 'lg';
}

export function TextHeader(props: Props) {
  const getSize = () => {
    if (props.size === 'sm') {
      return 'fs-5';
    } else if (props.size === 'lg') {
      return 'fs-1';
    } else {
      return 'fs-3';
    }
  };

  return (
    <div className={`my-3 ${props.className ?? ''}`}>
      <Row>
        <Col></Col>
        <Col>
          <div className={`d-flex flex-column align-items-center ${getSize()}`}>
            <p className='m-0 text-center'>
              {props.text}
            </p>
            {props.underline ? <Line position={props.underline} width={props.width} /> : null}
          </div>
        </Col>
        <Col>
          <div className='children d-flex align-items-center float-end'>
            {props.children}
          </div>
        </Col>
      </Row>
    </div>
  );
}