import { useCallback } from 'react';
import { Button as ButtonBT } from 'react-bootstrap';
import { ButtonVariant } from 'react-bootstrap/esm/types';

interface Props {
  children: JSX.Element | JSX.Element[] | string;
  className?: string;
  variant?: ButtonVariant;
  size?: 'sm' | 'lg';
  type?: 'submit' | 'reset' | 'button' | undefined;
  onClick?: () => void;
  disabled?: boolean;
}

export function Button(props: Props) {

  const handlerOnClick = useCallback(() => {
    if (props.onClick) {
      props.onClick();
    }
  }, [props.onClick]);

  return (
    <ButtonBT
      className={props.className}
      size={props.size}
      type={props.type}
      variant={props.variant}
      onClick={() => handlerOnClick()}
      disabled={props.disabled}>
      <div className='d-flex justify-content-center align-items-center'>
        {props.children}
      </div>
    </ButtonBT>
  );
}