export enum TournamentType {
  PERSON = 'Person',
  TEAM = 'Team',
  SINGLE = 'Single',
}

export enum TournamentGenaration {
  ALL = 'All',
  NO_MORE_THAN = 'NoMoreThan',
  MORE_THAN = 'MoreThan',
}

export const TournamentRegisterType = {
  PERSONAL_REGISTER: 'personalRegister',
  CLUB_REGISTER: 'clubRegister',
};

export enum TournamentStatusType {
  CURRENTLY = 'อยู่ระหว่างการแข่งขัน',
  OPENINGS = 'เปิดรับสมัคร',
  CLOSE = 'ปิดรับสมัคร',
  SUCCESS = 'จบการแข่งขัน',
}

export const enum TournamentStatusEvent {
  APPROVE = "Approve",
  WAITING_APPROVE = "WaitingApprove",
}