import http from 'hooks/useHttp';
import { EventAllRequestModel, RegisterTournament } from 'models';
import { TournamentType } from 'utils';

const getAllTournamentAsync = async (request: EventAllRequestModel) => {
  return await http.get('api/tournament/all', { params: request });
};

const getTournamentByIdAsync = async (id: string) => {
  return await http.get(`api/tournament/tournament/${id}`);
};

const registerTournamentAysnc = async (registerTournament: RegisterTournament, tournamentType: TournamentType, hasCreateTeam: boolean) => {
  const params = {
    teamType: tournamentType,
    hasCreateTeam: hasCreateTeam,
  };

  return await http.post('api/tournament/register', registerTournament, { params: params });
};
const updateRegisterTournamentAysnc = async (registerTournamentRequest: RegisterTournament, tournamentType: TournamentType, registerTournamentId: string, teamTournamentId: string) => {
  const params = {
    registerTournamentId: registerTournamentId,
    teamTournamentId: teamTournamentId,
    teamType: tournamentType,
  };

  return await http.put('api/tournament/update-register', registerTournamentRequest, { params });
};

const checkTournamentAsync = async (tournamentId: string, conditionId: string, userId: string) => {
  const params = {
    tournamentId: tournamentId,
    conditionId: conditionId,
    userId: userId,
  };

  return await http.get('api/tournament/checktournamentRegister', { params: params });
};

const getTournamentByConditionAsync = async (tournamentId: string, conditionId: string) => {
  const params = {
    tournamentId: tournamentId,
    conditionId: conditionId,
  };

  return await http.get('api/tournament/registerTournament-list-condition', { params: params });
};

const getApplicantAsync = async (id: string) => {
  return await http.get('api/tournament/applicant', { params: { id } });
};

const getRegisterHistoryAsync = async (id: string) => {
  return await http.get('api/tournament/getRegisterHistory', { params: { id } });
};

const deleteRegisterHistoryAsync = async (id: string) => {
  return await http.get('api/tournament/deleteRegisterHistory', { params: { id } });
};

const deleteRegisterHistoryTeamAsync = async (registerTournamentId: string, teamTournamentId: string) => {
  const params = {
    registerTournamentId: registerTournamentId,
    teamTournamentId: teamTournamentId,
  };
  return await http.delete('api/tournament/deleteRegisterTournamentAndTeamByIdAsync', { params });
};

const getTeamTournamentByIdAsync = async (id: string) => {
  return await http.get(`api/tournament/getTeamTournamentById/${id}`);
};

const getTeamTournamentListAsync = async () => {
  return await http.get('api/tournament/getteamtournament');
};

export default {
  getAllTournamentAsync,
  getTournamentByIdAsync,
  registerTournamentAysnc,
  updateRegisterTournamentAysnc,
  checkTournamentAsync,
  getTournamentByConditionAsync,
  getApplicantAsync,
  getRegisterHistoryAsync,
  deleteRegisterHistoryAsync,
  deleteRegisterHistoryTeamAsync,
  getTeamTournamentByIdAsync,
  getTeamTournamentListAsync,
};