import { Card as CardBT } from 'react-bootstrap';

interface Props {
  children: JSX.Element | JSX.Element[];
  className?: string;
  onClick?: () => void;
}

export function Card({ children, className, onClick }: Props) {
  return (
    <CardBT
      className={`custom-card ${className ? className : ''}`}
      onClick={() => onClick ? onClick() : null}>
      {children}
    </CardBT>
  );
}