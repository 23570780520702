import { Image } from 'react-bootstrap';
import { MdClose } from 'react-icons/md';
import { useCallback, useState, useEffect } from 'react';
import { game } from 'services';
import { Game } from 'models'
import { firebase } from 'services';

interface Props {
  valueChecked: (value: string[]) => void;
}

export default function GameCheckbox({ valueChecked }: Props) {
  const [checkboxActive, setCheckboxActive] = useState<string[]>([]);
  const [gameData, setGameData] = useState<Game[]>([]);

  useEffect(() => {
    getGameData();
  }, []);

  const getGameData = useCallback(async () => {
    const respones = await game.getAllGamesAsync();

    setGameData(respones.data);
  }, []);

  const handleClick = (shortName: string) => {
    const allcheckboxActive = [...checkboxActive];
    const index = allcheckboxActive.indexOf(shortName);
    
    if (index > -1) {
      allcheckboxActive.splice(index, 1);
    } else {
      allcheckboxActive.push(shortName);
    }
    setCheckboxActive(allcheckboxActive);
    valueChecked(allcheckboxActive);
  };

  return (
    <div className='d-flex flex-wrap gap-2 mt-2 mb-3'>
      {gameData.map((data, i) => (
        <div key={data.shortName} className={`border-logo-banner-game con-tooltip ${checkboxActive?.includes(data.shortName) ? 'active' : ''}`} onClick={() => handleClick(data.shortName)}>
          <ImageFirebase image={data.img} />
          <MdClose className='ms-4 icon' />
          <div className='tooltip'>
            <p className='mb-0'>{data.shortName}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

function ImageFirebase({ image }: {
  image: string,
}) {
  const [img, setImg] = useState<string>();

  useEffect(() => {
    getImageAsync();
  }, []);

  const getImageAsync = useCallback(async () => {

    if (image) {
      const imgUrl = await firebase.getImageAsync(image);
      setImg(imgUrl);
    }
  }, []);

  return (
    <>
      {img ?
        <Image className='logo-banner-game' src={img} /> : null}
    </>
  );
}