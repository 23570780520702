import { Checkbox, DatePicker, Form, Input, Modal, Select, TextHeader } from "components";
import { useAppSelector } from "hooks";
import { Game, TrainerUserHistory, TeamTournamentList } from "models";
import { useCallback, useEffect, useState } from "react";
import { Button, Col, Row, Form as FormBT, InputGroup } from "react-bootstrap";
import { HttpStatusCode, ModalSize, convertDateToDateTimeOffset } from "utils";
import { account, tournament } from "services";
import toast from "utils/toast";

interface Items {
  label: string;
  value: string;
}

export function AddTrainerUserHistoryModal(props: { show: boolean, onClose: () => void }) {
  const [otherCheck, setOtherCheck] = useState(false);
  const [teamTournamentItems, setTeamTournamentItems] = useState<Items[]>([]);

  useEffect(() => {
    if (props.show) {
      getTeamTournamentListAsync();
    }
  }, [props.show]);

  const formRule = {
    tournamentStartDate: { required: true },
    tournamentEndDate: { required: true },
    tournamentTitle: { required: true },
    tournamentLocation: { required: true },
    ranking: { required: true },
    teamName: { required: true },
    teamTournamentId: { required: true },
    gameId: { required: true },
  };

  const { games } = useAppSelector((state) => ({
    games: state.commonStore.games as Game[],
  }));

  const getGamesPlayedMaster = useCallback(() => {
    const gameList = games?.map(d => ({ label: d.fullName, value: d.id }));

    return gameList;
  }, [games]);

  const submitHandler = async (data: TrainerUserHistory) => {
    const convertStartDate = convertDateToDateTimeOffset(data.tournamentStartDate.toString());
    const convertEndDate = convertDateToDateTimeOffset(data.tournamentEndDate.toString());

    if (convertStartDate > convertEndDate) {
      return toast.warn("วันที่การแข่งขันเริ่มต้นมากกว่าวันที่การแข่งขันสิ้นสุด");
    }

    const noOfCompetitors = data.noOfCompetitors?.toString();
    const ranking = data.ranking?.toString();
    const body = data;
    body.tournamentStartDate = convertStartDate;
    body.tournamentEndDate = convertEndDate;
    body.noOfCompetitors = noOfCompetitors ? parseInt(noOfCompetitors) : undefined;
    body.ranking = parseInt(ranking);
    body.teamTournamentId = !otherCheck ? body.teamTournamentId : undefined;
    body.teamName = otherCheck ? body.teamName : undefined;

    const { status } = await account.createTrainerUserHistoryAsync(body);
    if (status === HttpStatusCode.CREATED) {
      toast.success('บันทึกข้อมูลสำเร็จ');

      props.onClose();
    }
  };

  const getTeamTournamentListAsync = async () => {
    const { data, status } = await tournament.getTeamTournamentListAsync();

    if (status === HttpStatusCode.OK) {
      const teamItems = data?.map((d: TeamTournamentList) => ({
        label: d.name,
        value: d.id,
      }));

      setTeamTournamentItems(teamItems);
    }
  };

  const onChangeTeamType = (team: string) => {
    if (team === "otherTeam") {
      setOtherCheck(true);

      return;
    }

    setOtherCheck(false);
  };

  return (
    <Modal show={props.show} size={ModalSize.LG} closeButton onClose={() => props.onClose()}>
      <Form onSubmit={submitHandler} rules={formRule}>
        <Modal.Body>
          <div className='my-4'>
            <TextHeader
              text='เพิ่มประวัติการฝึกสอน'
              position='center'
              size='sm'
              underline='center'
              width='large' />
          </div>
          <Row>
            <Col className="mb-3">
              <InputGroup onChange={(value: any) => onChangeTeamType(value.target.value)}>
                <FormBT.Check
                  inline
                  label="ทีมใน TESF"
                  name="team"
                  type="radio"
                  value="tesfTeam"
                  defaultChecked={!otherCheck} />
                <FormBT.Check
                  inline
                  label="ทีมอื่นๆ"
                  name="team"
                  type="radio"
                  value="otherTeam"
                  defaultChecked={otherCheck} />
              </InputGroup>
            </Col>
            <Col xs={12}>
              {otherCheck ? <Input label='ชื่อทีมที่ฝึกสอน' name='teamName' />
                : <Select label='ชื่อทีมที่ฝึกสอน' name='teamTournamentId' items={teamTournamentItems} />}
            </Col>
            <Col xs={12}>
              <Select label='เกมที่ฝึกสอน' name='gameId' items={getGamesPlayedMaster()} />
            </Col>
            <Col lg={6}>
              <DatePicker label='วันที่เรื่มการแข่งขัน' name='tournamentStartDate' />
            </Col>
            <Col lg={6}>
              <DatePicker label='วันที่สิ้นสุดการแข่งขัน' name='tournamentEndDate' />
            </Col>
            <Col xs={12}>
              <Input label='ชื่อการแข่งขัน' name='tournamentTitle' />
            </Col>
            <Col xs={12}>
              <Input label='สถานที่การแข่งขัน' name='tournamentLocation' />
            </Col>
            <Col xs={12}>
              <Input label='ประเภทการแข่งขัน' name='tournamentType' />
            </Col>
            <Col xs={12}>
              <Input label='จำนวนทีม' name='noOfCompetitors' type="number" />
            </Col>
            <Col xs={12}>
              <Input label='อันดับที่ได้รับ' name='ranking' type="number" />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className='justify-content-between'>
          <Button variant='outline-light' onClick={() => props.onClose()}>ยกเลิก</Button>
          <Button type='submit'>บันทึก</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}