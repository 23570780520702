import { useCallback } from 'react';
import { useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight } from 'react-icons/fa';

interface Props {
  totalItems: number;
  totalItemsPerPage?: number;
  onChange?: (value: number) => void;
}

export function Pagination({ totalItems, totalItemsPerPage = 10, onChange }: Props) {
  const [pageActive, setPageActive] = useState(1);
  const [pageAll, setPageAll] = useState(0);

  const first = useCallback(() => {
    if (pageActive > 1) {
      setPageActive(1);

      if (onChange) {
        onChange(1);
      }
    }
  }, [pageActive, onChange]);

  const prev = useCallback(() => {
    if (pageActive > 1) {
      const page = pageActive === 1 ? 1 : (pageActive - 1);

      setPageActive(page);

      if (onChange) {
        onChange(page);
      }
    }
  }, [pageActive, onChange]);

  const go = useCallback((page: number) => {
    if (page !== pageActive) {
      setPageActive(page);

      if (onChange) {
        onChange(page);
      }
    }
  }, [pageActive, onChange]);

  const next = useCallback(() => {
    if (pageActive < pageAll) {
      const page = pageActive === pageAll ? pageAll : (pageActive + 1);

      setPageActive(page);

      if (onChange) {
        onChange(page);
      }
    }
  }, [pageActive, pageAll, onChange]);

  const last = useCallback(() => {
    if (pageActive < pageAll) {
      setPageActive(pageAll);

      if (onChange) {
        onChange(pageAll);
      }
    }
  }, [pageActive, pageAll, onChange]);

  const pageButton = useCallback((start: number, end: number) => {
    const templates: JSX.Element[] = [];

    for (let i = start; i <= end; i++) {
      templates.push(
        <Button
          key={i}
          className={`me-2 ${pageActive === i ? 'active' : ''}`}
          variant='link'
          onClick={() => go(i)}>
          {i}
        </Button>
      );
    }

    return templates;
  }, [go, pageActive]);

  const pages = useMemo(() => {
    const maxPage = window.screen.width >= 768 ? 5 : 3;
    const sum = totalItems / totalItemsPerPage;
    const split = sum.toString().split('.');
    const pageAll = split.length === 1 ? Number(split[0]) : Number(split[0]) + 1;

    setPageAll(pageAll);

    if (pageActive <= 3 || pageAll <= maxPage) {
      const end = pageAll < maxPage ? pageAll : maxPage;

      return pageButton(1, end);
    } else {
      const end = pageAll;

      if (end - pageActive > 2) {
        return pageButton(pageActive - 2, pageActive + 2);
      } else {
        return pageButton(end - (maxPage - 1), end);
      }
    }
  }, [totalItems, pageActive, pageButton]);

  return (
    <div className='custom-pagination d-flex flex-row justify-content-center mt-4'>
      <Button className='me-2' variant='outline-light' onClick={first}>
        <FaAngleDoubleLeft />
      </Button>

      <Button className='me-2' variant='outline-light' onClick={prev}>
        <FaAngleLeft />
      </Button>

      {pages}

      <Button className='me-2' variant='outline-light' onClick={next}>
        <FaAngleRight />
      </Button>

      <Button variant='outline-light' onClick={last}>
        <FaAngleDoubleRight />
      </Button>
    </div>
  );
}