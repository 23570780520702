import tesfIcon from 'assets/icons/tesf-icon.jpeg';
import { TableGame, ImgLists, Image } from 'components';
import { useLoaderData } from "react-router-dom";
import { AssociationMatchType, TournamentTeamsCandidate } from 'models';
import { associationTournamentMatch, firebase } from 'services';
import { useState, useEffect } from 'react';
import { TournamentLoader } from 'EventLayout';

export function Candidates() {
  const loader = useLoaderData() as TournamentLoader;
  const [dataResponseTeamsCandidate, setDataResponseTeamsCandidate] = useState<TournamentTeamsCandidate[]>([]);

  const fetchData = async () => {
    const { data } = await associationTournamentMatch.getAssociationTournamentTeamsCandidate(loader.tournament.id);

    setDataResponseTeamsCandidate(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const RenderPlayer = (matchType: string, data: TournamentTeamsCandidate, key: string | number) => {
    if (matchType === AssociationMatchType.Single) {
      return (
        <tr key={key}>
          <td className='text-end' width={250}><ImageSingle image={data.teamLogo} /></td>
          <td className='text-start' width={350}>{data.teamName}</td>
        </tr>);
    }

    return (
      <tr key={key}>
        <td className='text-end' width={250}><ImageSingle image={data.teamLogo} /></td>
        <td className='text-start' width={350}>{data.teamName}</td>
        <td className='text-center'>
          {data.playersImage.length > 0 && <ImageList image={data.playersImage} />}
        </td>
      </tr>);
  }

  return (
    <div className='mt-5 event'>
      <div className='line-gradient-light my-5' />
      <div className='position-relative box-gradient mt-5'>
        <div className='position-absolute line-gradient-primary left d-none d-md-block' />
        <p className='fs-3 text-center'>ผู้สมัครเข้าแข่งขัน</p>
        <p className='fs-3 text-center text-spacing'>PARTICIPANTS</p>
        <div className='position-absolute line-gradient-primary right d-none d-md-block' />
      </div>
      <div className={`w-100 overflow-auto `}>
        <TableGame ITitle={` TEAMS`}>
          <tbody>
            {
              dataResponseTeamsCandidate.map((data: TournamentTeamsCandidate, index: number) => (
                RenderPlayer(loader.tournament.associationMatchType, data, index)))
            }
          </tbody>
        </TableGame>
      </div>
      <div className='line-gradient-primary my-5' />
    </div>
  );
}

const ImageSingle = (props: { image: string }) => {
  const [image, setImages] = useState<string>('');

  useEffect(() => {
    if (props.image && props.image.length > 0) {
      getImageUrlAsync(props.image);
    }
  }, [props.image]);

  const getImageUrlAsync = async (imagePath: string) => {
      const imageReponse = await firebase.getImageAsync(imagePath);

      setImages(imageReponse);
  };

  return (
    <Image alt='logo' src={image} className='image-profile-team circle me-3' />
  );
};

const ImageList = (props: { image: string[] }) => {
  const [imageList, setImagesList] = useState<string[]>([]);

  useEffect(() => {
    if (props.image.length > 0) {
      getImageUrlAsync(props.image);
    }
  }, [props.image]);

  const getImageUrlAsync = async (imagePath: string[]) => {
    const images: string[] = [];

    for (const i of imagePath) {
      if (!i) {
        images.push(tesfIcon);
        continue;
      }

      const imageResponse = await firebase.getImageAsync(i);
      images.push(imageResponse);
    }

    setImagesList(images);
  };

  return (
    <ImgLists img={imageList} width="100" />
  );
};
