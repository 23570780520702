import http from '../hooks/useHttp';
import { SearchPlayerModel } from 'models'

const getPlayerAsync = async (searchModel: SearchPlayerModel) => {
  console.log('searchModel => ', searchModel.shortNames)

  const params = new URLSearchParams();

  params.append('keyword', searchModel.keyword!);
  params.append('memberType', searchModel.memberType!);
  params.append('page', searchModel.page.toString());
  params.append('size', searchModel.size.toString());

  searchModel.shortNames.forEach((item) => {
    params.append('shortNames', item);
  });

  return await http.get('api/player/all', { params });
};

export default {
  getPlayerAsync,
};