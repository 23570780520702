import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalSize, ModalType } from 'utils/constants';

interface State {
  isOpened: boolean;
  modalType: ModalType | undefined;
  size: ModalSize;
}

const initialState: State = {
  isOpened: false,
  modalType: undefined,
  size: ModalSize.MD,
};

export const modalStore = createSlice({
  name: 'modal',
  initialState: initialState,
  reducers: {
    openModal: (state: State, action: PayloadAction<{ type: ModalType, size: ModalSize }>) => {
      state.isOpened = true;
      state.modalType = action.payload.type;
      state.size = action.payload.size;
    },
    closeModal: (state: State) => {
      state.isOpened = false;
    },
    setModalType: (state: State, action: PayloadAction<ModalType>) => {
      state.modalType = action.payload;
    },
  },
});

export const { openModal, closeModal, setModalType } = modalStore.actions;

export default modalStore.reducer;