import { ShareValueGroup } from 'utils/constants/shareValue';
import http from '../hooks/useHttp';

const getShareValueListAsync = async (shareValueGroup: ShareValueGroup) => {
  const params = {
    shareValueGroup,
  };

  return await http.get('api/shareValue', { params });
};

const shareValueService = {
  getShareValueListAsync,
};

export default shareValueService;