import { Button, Table, TextHeader, Pagination as PaginationComponent, } from "components";
import { TrainerUserHistoryData, User } from "models";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";
import { account } from "services";
import { HttpStatusCode, convertDateToFullDate } from "utils";
import { AddTrainerUserHistoryModal } from "./modals/AddTrainerUserHistoryModal";
import { useLoaderData } from "react-router-dom";
import { useUser } from "hooks";

export default function Trainer() {
  const { t } = useTranslation('modal');
  const [trainerHistoryList, setTrainerHistoryList] = useState<TrainerUserHistoryData[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [totalRow, setTotalRow] = useState(0);
  const [page, setPage] = useState(1);
  const loader = useLoaderData() as Loader;
  const user = useUser();

  type Loader = {
    user: User;
  };

  useEffect(() => {
    getTrainerUserHistoryListAsync();
  }, [page]);

  const getTrainerUserHistoryListAsync = async () => {
    const { data, status } = await account.getTrainerUserHistoryListAsync(loader.user.id, page, 10);

    if (status === HttpStatusCode.OK) {
      setTotalRow(data.totalRows);
      setTrainerHistoryList(data.rows);
    }
  };

  const onSaveAddTrainerUserHistory = () => {
    getTrainerUserHistoryListAsync();

    setShowModal(false);
  };

  return (
    <>
      <TextHeader
        text={t('competition.trainerUserHistory')}
        className='mt-5 mb-5'>
        <>{loader.user.id === user.data.id ?
          <Button
            variant='outline-light'
            onClick={() => setShowModal(true)}>
            <>
              <FaPlus className='me-2' />
              เพิ่มประวัติการฝึกสอน
            </>
          </Button> : <></>}
        </>
      </TextHeader>
      <Table>
        <thead>
          <tr className='text-center'>
            <th>ชื่อทีมที่ฝึกสอน</th>
            <th>เกมส์ที่ฝึกสอน</th>
            <th>วันที่แข่งขัน</th>
            <th>สถานที่จัดการแข่งขัน</th>
            <th>ประเภทการแข่งขัน</th>
            <th>จำนวนทีม</th>
            <th>อันดับที่ได้รับ</th>
          </tr>
        </thead>
        <tbody>
          {trainerHistoryList?.map(data => (
            <tr key={data.id}>
              <td className='text-center'>{data.TeamTournamentName ?? data.teamName}</td>
              <td className='text-center'>{data.gameName}</td>
              <td className='text-center'>{`${convertDateToFullDate(data.tournamentStartDate)} - ${convertDateToFullDate(data.tournamentEndDate)}`}</td>
              <td className='text-center'>{data.tournamentLocation}</td>
              <td className='text-center'>{data.tournamentType}</td>
              <td className='text-center'>{data.noOfCompetitors}</td>
              <td className='text-center'>{data.ranking}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className='d-flex justify-content-center mt-3'>
        <PaginationComponent
          totalItems={totalRow}
          onChange={(value) => setPage(value)} />
      </div>
      <AddTrainerUserHistoryModal show={showModal} onClose={onSaveAddTrainerUserHistory} />
    </>
  );
}