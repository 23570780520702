import { CompetitionHistory, GamePlayed } from 'models';
import http from 'hooks/useHttp';

const getAllGamesAsync = async () => {
  return await http.get('api/game/all-games');
};

const getGamesPlayedWithPaginationAsync = async (userId: string, page: number) => {
  return await http.get('api/game/games-played-with-pagination', { params: { userId, page, size: 6 } });
};

const getGamesPlayedAsync = async (userId: string) => {
  return await http.get('api/game/games-played', { params: { userId } });
};

const getCompetitionHistoriesWithPaginationAsync = async (userId: string, gamePlayedId: string, page: number) => {
  return await http.get('api/game/competition-histories-with-pagination', { params: { userId, gamePlayedId, page, size: 10 } });
};

const addGamePlayedAsync = async (form: GamePlayed) => {
  return await http.post('api/game/add-game-played', form);
};

const addCompetitionHistoryAsync = async (form: CompetitionHistory) => {
  return await http.post('api/game/add-competition-history', form);
};

const getGameByIdAsync = async (id: string) => {
  return await http.get(`api/game/game/${id}`);
};

export default {
  getAllGamesAsync,
  getCompetitionHistoriesWithPaginationAsync,
  getGamesPlayedWithPaginationAsync,
  getGamesPlayedAsync,
  addGamePlayedAsync,
  addCompetitionHistoryAsync,
  getGameByIdAsync,
};