import { useCallback, useEffect, useMemo, useState } from 'react';
import { Navbar as NavbarBT, Container, Button, Dropdown, Image } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FaBars, FaUser, FaAngleDown } from 'react-icons/fa';
import { HttpStatusCode, Language, ResponsiveSize, showClubDetailModal } from 'utils';
import { useUser } from 'hooks';
import { useTranslation } from 'react-i18next';
import firebase from 'services/firebase';
import LoginModal from './modals/LoginModal';
import RegisterForm from './modals/RegisterFormModal';
import CheckExistEmailModal from './modals/CheckExistEmailModal';
import VerifyOtpModal from './modals/VerifyOtpModal';
import { account, club as clubService } from 'services';
import { User, Club as ClubModel, shareValue } from 'models';
import ModalForgotPassword from './modals/ForgotPasswordModal';
import shareValueService from 'services/shareValue';
import { ShareValueGroup } from 'utils/constants/shareValue';

export enum ModalType {
  LOGIN = 'login',
  CHECK_EXIST_EMAIL = 'checkExistEmail',
  VERIFY_OTP = 'verifyOtp',
  REGISTER_FORM = 'registerForm',
}

export function Navbar() {
  const [modalIsOpened, setModelIsOpened] = useState(false);
  const [modalType, setModalType] = useState<ModalType>();
  const [forgotPassword, setForgotPassword] = useState(false);

  const showModal = useCallback((modal: ModalType) => {
    return modalIsOpened && modalType === modal;
  }, [modalIsOpened, modalType]);

  const showModalModalForgotPassword = () => {
    setModelIsOpened(false);
    setForgotPassword(true);
  };

  return (
    <>
      <NavbarBT className='custom-navbar' expand={ResponsiveSize.SM}>
        <Container fluid={ResponsiveSize.XL}>
          <NavbarBT.Brand>
            <Link className='text-light text-decoration-none' to='/home'>
              <span className='text-primary'>E</span>sports
            </Link>
          </NavbarBT.Brand>
          <NavbarBT.Toggle>
            <FaBars />
          </NavbarBT.Toggle>
          <NavbarBT.Collapse className='justify-content-end'>
            <Buttons
              onOpenModal={value => {
                setModalType(value);
                setModelIsOpened(true);
              }} />
            <ChangeLanguage />
          </NavbarBT.Collapse>
        </Container>
      </NavbarBT>
      <LoginModal
        show={showModal(ModalType.LOGIN)}
        onClose={() => setModelIsOpened(false)}
        next={value => {
          setModalType(value);
          setModelIsOpened(true);
        }}
        onForgotPassword={showModalModalForgotPassword} />
      <ModalForgotPassword
        show={forgotPassword}
        onHide={() => setForgotPassword(false)} />
      <RegisterForm
        show={showModal(ModalType.REGISTER_FORM)}
        onClose={() => setModelIsOpened(false)} />
      <CheckExistEmailModal
        show={showModal(ModalType.CHECK_EXIST_EMAIL)}
        onClose={() => setModelIsOpened(false)}
        next={value => {
          setModalType(value);
          setModelIsOpened(true);
        }} />
      <VerifyOtpModal
        show={showModal(ModalType.VERIFY_OTP)}
        onClose={() => setModelIsOpened(false)}
        next={() => {
          setModalType(ModalType.REGISTER_FORM);
          setModelIsOpened(true);
        }} />
    </>
  );
}

function Buttons({ onOpenModal }: {
  onOpenModal: (value: ModalType) => void,
}) {
  const { t } = useTranslation('navbar');
  const user = useUser();
  const navigate = useNavigate();
  const [img, setImg] = useState<string>();
  const [alt, setAlt] = useState<string>();
  const [userData, setUserData] = useState<User>();
  const [clubData, setClubData] = useState<ClubModel>();
  const [memberTypeItems, setMemberTypeItems] = useState<shareValue[]>([]);

  useEffect(() => {
    if (user.data.signedIn && user.data.id) {
      if (!userData) {
        getUserAsync(user.data.id);
      }
    }
  }, [user, userData]);

  useEffect(() => {
    getMemberTypeAsync();
  }, []);

  const addEventListenerClubCreated = () => document.addEventListener('onClubCreated', async (data) => {
    const event = data as CustomEvent;
    const id = event.detail.id;

    await getClubAsync(id)
  });

  const addEventListenerLeavedClub = () => document.addEventListener('onLeavedClub', async () => {
    setClubData(undefined);
  });

  const getUserAsync = useCallback(async (id: string) => {
    const response = await account.getUserAsync(id);

    if (response.status === HttpStatusCode.OK) {
      setUserData(response.data);
      await getImageAsync(response.data.img);


      if (response.data.clubId) {
        await getClubAsync(response.data.clubId);
        addEventListenerLeavedClub();
      } else {
        addEventListenerClubCreated();
      }
    }
  }, []);

  const getClubAsync = useCallback(async (id: string) => {
    const club = await clubService.getClubAsync(id);

    if (club.status === HttpStatusCode.OK) {
      setClubData(club.data);
    }
  }, []);

  const getImageAsync = useCallback(async (img: string) => {
    if (img) {
      const imgUrl = await firebase.getImageAsync(img);

      setImg(imgUrl);
      setAlt(img);
    }
  }, []);

  const getName = useMemo(() => {
    const firstName = userData?.firstName;
    const middleName = userData?.middleName ? ` ${userData?.middleName}` : '';
    const lastName = userData?.lastName ? ` ${userData?.lastName}` : '';

    return `${firstName}${middleName}${lastName}`;
  }, [userData]);

  const getMemberType = (() => {
    if (userData?.memberType) {
      const memberTypeName = memberTypeItems?.find(m => m.value === userData?.memberType);

      return memberTypeName?.label;
    }

    return "";
  });

  const goToProfile = useCallback(() => {
    navigate(`/profile/${user.data.id}`);
  }, [user]);

  const handlerClubOnClick = useCallback(() => {
    if (clubData) {
      navigate(`/club/detail/${clubData.id}`);
    } else {
      showClubDetailModal();
    }
  }, [clubData]);

  const getMemberTypeAsync = async () => {
    const { data, status } = await shareValueService.getShareValueListAsync(ShareValueGroup.MemberType);

    if (status === HttpStatusCode.OK) {
      setMemberTypeItems(data);
    }
  };

  const template = useMemo(() => {
    if (user.data.signedIn && userData) {
      return (
        <>
          <div className='button profile me-2'>
            <Dropdown>
              <Dropdown.Toggle as={Button} className='w-100 d-flex flex-row' variant='custom'>
                {img ?
                  <div className='image'>
                    <Image src={img} alt={alt} />
                  </div> :
                  <div className='no-image'>
                    <FaUser />
                  </div>}
                <div className='info text-start'>
                  <div className='name'>{getName}</div>
                  <div className='role'>{getMemberType()}</div>
                </div>
                <div className='dropdown-icon'>
                  <FaAngleDown />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>
                  <Button
                    className='w-100'
                    variant='link'
                    onClick={() => goToProfile()}>
                    โปรไฟล์
                  </Button>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Button
                    className='w-100'
                    variant='link'
                    onClick={() => firebase.signOutAsync()}>
                    ออกจากระบบ
                  </Button>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className='button me-1'>
            <Button
              className='w-100'
              variant='primary'
              onClick={handlerClubOnClick}>
              {clubData ? t('club') : t('createClub')}
            </Button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className='button me-1'>
            <Button
              className='w-100'
              variant='primary'
              onClick={() => onOpenModal(ModalType.LOGIN)}>
              {t('login')}
            </Button>
          </div>
          <div className='button ms-1'>
            <Button
              className='w-100'
              variant='outline-light'
              onClick={() => onOpenModal(ModalType.CHECK_EXIST_EMAIL)}>
              {t('register')}
            </Button>
          </div>
        </>
      );
    }
  }, [user, img, alt, userData]);

  return (
    <div className='action d-flex flex-row'>
      {template}
    </div>
  );
}

function ChangeLanguage() {
  const { i18n, t } = useTranslation('navbar');

  const change = useCallback((value: Language) => i18n.changeLanguage(value), []);

  return (
    <div className='d-flex text-center'>
      <div className='switch-language mt-2 mt-sm-0 border-end'>
        <Button
          className={`${i18n.language === Language.TH ? 'current' : ''}`}
          variant='link'
          onClick={() => change(Language.TH)}>
          {t('th')}
        </Button>
      </div>
      <div className='switch-language mt-2 mt-sm-0'>
        <Button
          className={`${i18n.language === Language.EN ? 'current' : ''}`}
          variant='link'
          onClick={() => change(Language.EN)}>
          {t('en')}
        </Button>
      </div>
    </div>
  );
}