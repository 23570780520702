import { FormState, Event, EventType } from 'utils';
import { CustomValidate, Rules, useValidate, ValidateResult, ValidateType } from 'hooks';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { connect } from 'react-redux';
import { InputGroup } from 'react-bootstrap';

interface Props {
  label?: string;
  placeholder?: string;
  name: string;
  value?: string | number;
  rows?: number;

  // props from store
  rules: Rules;
  event: Event | undefined;
}

function Control(props: Props) {
  const [value, setValue] = useState<string | number>('');
  const validate = useValidate();
  const ref = useRef({} as HTMLInputElement);
  const [rule, setRule] = useState<ValidateType | null>();
  const [validateResult, setValidateResult] = useState<ValidateResult>();

  useEffect(() => {
    if (props.rules) {
      setRule(props.rules[props.name]);
    }
  }, [props.rules]);

  useEffect(() => {
    if (props.event?.type === EventType.ON_SUBMIT) {
      handlerOnSubmit();
    }
  }, [props.event]);

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);

  const isRequired = useMemo(() => {
    if (props.rules && props.name) {
      const rule = props.rules[props.name];

      if (rule) {
        if (typeof rule.required === typeof {}) {
          return (rule.required as CustomValidate).value;
        }

        return rule.required ?? false;
      }
    }

    return false;
  }, [props.rules, props.name]);

  const handlerOnChange = (event: HTMLInputElement) => {
    const value = event.value;

    setValue(value);

    onValidate(value);
  };

  const onValidate = useCallback((value: string | number) => {
    const result = validate.process(value, rule);

    setValidateResult(result);
  }, [rule, props.name]);

  const handlerOnSubmit = useCallback(() => {
    onValidate(value);
  }, [value, rule]);


  return (
    <div className='textarea mb-3'>
      <Form.Label>
        {props.label}
        {isRequired ? <span className='text-danger'> *</span> : null}
      </Form.Label>
      <InputGroup
        ref={ref}
        hasValidation>
        <Form.Control
          className={`control ${validateResult?.hasError ? 'is-invalid' : ''}`}
          as='textarea'
          value={value}
          name={props.name}
          rows={props.rows ? props.rows : 5}
          placeholder={props.placeholder}
          onChange={event => handlerOnChange(event.target as HTMLInputElement)} />
        <Form.Control.Feedback type='invalid'>
          {validateResult?.message}
        </Form.Control.Feedback>
      </InputGroup>
    </div>
  );
}

const mapStateToProps = (state: FormState) => state.formStore;

const Input = connect(mapStateToProps)(Control);

export default Input;