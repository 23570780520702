import { convertToFullDate, converFullDate } from 'utils/converters/dateConverter';
import { Badge, Col, Row, Image } from 'react-bootstrap';
import { FaCalendarAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { set } from '../../utils/stores/commonStore';
import { IAssociationTournamentPaginationResponse } from 'models';
import { EventName } from 'utils/constants/enum';
import { useCallback, useState, useEffect } from 'react';
import { firebase } from 'services';
import { EventStatus } from 'components';
import { TournamentStatusType } from 'utils';

interface Props {
  eventName: string;
  data: IAssociationTournamentPaginationResponse;
  textBagde: string;
}

export function ContentCard(props: Props) {
  const { t } = useTranslation('contentCard');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [tournamentData, setTournamentData] = useState<IAssociationTournamentPaginationResponse>({} as IAssociationTournamentPaginationResponse);

  useEffect(() => {
    setTournamentData(props.data);
  }, [props]);

  const onClickHandler = () => {
    setData(tournamentData);
    if (props.eventName === EventName.TOURNAMENT) {
      navigate(`/event/${tournamentData.id}`);
    }
    else {
      navigate('/content/streaming');
    }
  };

  const setData = (data: IAssociationTournamentPaginationResponse) =>
    dispatch(set({
      key: 'tournamentDetail',
      data: data,
    }));

  const handleCheckDate = () => {
    if (props.eventName === EventName.TOURNAMENT) {
      return (
        <>
          <p className='mb-0 text-date'>{converFullDate(tournamentData.startRegistrationDate)} - {converFullDate(tournamentData.competitionEndDate)}</p>
        </>
      );
    } else {
      return <p className='mb-0 text-date'>{convertToFullDate(tournamentData.startRegistrationDate)}</p>
    }
  };

  const activeDateStart = useCallback((dateNow: Date) => {
    if (dateNow < new Date(tournamentData.endRegistrationDate)) {
      return TournamentStatusType.OPENINGS;
    }

    if (dateNow >= new Date(tournamentData.endRegistrationDate) && dateNow < new Date(tournamentData.competitionStartDate)) {
      return TournamentStatusType.CLOSE;
    }

    if (dateNow >= new Date(tournamentData.competitionStartDate) && dateNow <= new Date(tournamentData.competitionEndDate)) {
      return TournamentStatusType.CURRENTLY;
    }

    if (dateNow >= new Date(tournamentData.competitionEndDate)) {
      return TournamentStatusType.SUCCESS;
    }
    return '';
  }, [tournamentData]);

  return (
    <div
      className='content-card h-50'
      onClick={onClickHandler}>
      <Row className='mb-2'>
        <Col>
          <div className='image border border-primary rounded'>
            <ImageFirebase image={tournamentData.imagePath} title={tournamentData.gameName} />
            <span className='status-fix rounded-pill'>
              <EventStatus status={activeDateStart(new Date())} />
            </span>
          </div>
        </Col>
      </Row>

      <Row className='mb-2'>
        <Col>
          <Badge bg='primary'>
            <h6 className='m-0'>{props.textBagde}</h6>
          </Badge>
        </Col>
      </Row>

      <Row className='mb-2'>
        <Col>
          <h5 className='m-0 topic'>{tournamentData.name}</h5>
        </Col>
      </Row>

      <Row>
        <Col className='d-flex align-items-center text-muted'>
          <FaCalendarAlt className='me-1 text-date' /> {handleCheckDate()}
        </Col>
      </Row>
    </div>
  );
}

function ImageFirebase({ image, title }: {
  image?: string,
  title: string,
}) {
  const [img, setImg] = useState<string>();

  useEffect(() => {
    getImageAsync();
  }, [image]);

  const getImageAsync = useCallback(async () => {
    if (image) {
      const imgUrl = await firebase.getImageAsync(image);

      setImg(imgUrl);
    }
  }, [image]);

  return (
    <>
      {img ? <Image alt={title} className='img-fluid d-flex mx-auto' src={img} /> : null}
    </>
  );
}