
import { Col, Row, Image } from 'react-bootstrap';
import { Breadcrumb } from 'components/Breadcrumb';
import { Content } from 'models/content';
import { ContentCard } from 'components/ContentCard';
import NewsCard from 'components/NewsCard/NewsCard';
import { Card } from 'components/Card';
import { HeaderCard } from 'components/HeaderCard';
import { Form } from 'components/Form';
import Input from 'components/Controls/Input';
import { Pagination } from 'components/Pagination';
import { InfoCard } from 'components/InfoCard';
import { Nationality } from 'utils/constants';
import { Button } from 'components/Button';
import { Checkbox, DatePicker, Select, Textarea } from 'components/Controls';
import MultiSelect from 'components/Controls/MultiSelect';

const select = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
];

const breadcrumbMenus = [
  { label: 'หน้าแรก', path: '/home' },
  { label: 'ตัวอย่าง', path: '/demo' },
];

const streaming: Content[] = [
  { id: '1', topic: 'SAZKA eLEAGUE - Season 1 - Winter', imagePath: '/images/demo/Asia_Pacific_Predator_League_2022_allmode.png', desc: '', date: new Date(), tag: 'การแข่งขัน' },
  { id: '2', topic: 'Asia Pacific Predator League 2022 Grand Finals', imagePath: '/images/demo/Asia_Pacific_Predator_League_2022_allmode.png', desc: '', date: new Date(), tag: 'การแข่งขัน' },
  { id: '3', topic: 'PUBG Global Championship 2022', imagePath: '/images/demo/Asia_Pacific_Predator_League_2022_allmode.png', desc: 'The PUBG Global Championship is the final event and the World Championship of the 2022 Competitive Season organized by PUBG Corporation. Invited are the best teams throughout the year', date: new Date(), tag: 'การแข่งขัน' },
  { id: '4', topic: 'Dota 2 Champions League Season 16', imagePath: '/images/demo/Asia_Pacific_Predator_League_2022_allmode.png', desc: 'Participants Twelve teams Ten invited teams Two teams from a closed qualifier Ten teams are seeded into the group stage Two teams are seeded into the playoffs stage Group Stage – November 7th - November 11th, 2022 Two groups of five teams each Single round-robin All matches are Bo3 Top two teams of each group advance to the upper bracket of the playoffs 3rd and 4th place teams of each group advance to the lower bracket of the playoffs Remaining teams of each group are eliminated Click here for tiebreaker rules Playoffs – November 13th - November 17th, 2022 Double-elimination bracket All matches except Grand Final are Bo3 Grand Final is Bo5', date: new Date(), tag: 'การแข่งขัน' },
];

const newses: Content[] = [
  { id: '1', topic: 'SAZKA eLEAGUE - Season 1 - Winter', imagePath: '/images/demo/Asia_Pacific_Predator_League_2022_allmode.png', desc: '', date: new Date(), tag: 'การแข่งขัน' },
  { id: '2', topic: 'Asia Pacific Predator League 2022 Grand Finals', imagePath: '/images/demo/Asia_Pacific_Predator_League_2022_allmode.png', desc: '', date: new Date(), tag: 'การแข่งขัน' },
  { id: '3', topic: 'PUBG Global Championship 2022', imagePath: '/images/demo/Asia_Pacific_Predator_League_2022_allmode.png', desc: 'The PUBG Global Championship is the final event and the World Championship of the 2022 Competitive Season organized by PUBG Corporation. Invited are the best teams throughout the year', date: new Date(), tag: 'การแข่งขัน' },
  { id: '4', topic: 'Dota 2 Champions League Season 16', imagePath: '/images/demo/Asia_Pacific_Predator_League_2022_allmode.png', desc: 'Participants Twelve teams Ten invited teams Two teams from a closed qualifier Ten teams are seeded into the group stage Two teams are seeded into the playoffs stage Group Stage – November 7th - November 11th, 2022 Two groups of five teams each Single round-robin All matches are Bo3 Top two teams of each group advance to the upper bracket of the playoffs 3rd and 4th place teams of each group advance to the lower bracket of the playoffs Remaining teams of each group are eliminated Click here for tiebreaker rules Playoffs – November 13th - November 17th, 2022 Double-elimination bracket All matches except Grand Final are Bo3 Grand Final is Bo5', date: new Date(), tag: 'การแข่งขัน' },
];

const multiSelect = [
  { label: 'one', value: 'one' },
  { label: 'two', value: 'two' },
  { label: 'three', value: 'three' },
  { label: 'four', value: 'four' },
  { label: 'five', value: 'five' },
];

export function Demo() {
  const formRules = {
    input1: { required: true },
    input2: {
      required: {
        value: true,
        message: '????????????????????',
      }
    },
    select: { required: true },
    multiSelect: { required: true },
    datePicker: { required: true },
    checkbox: { required: true },
    textarea: { required: true },
  };

  const paginationOnChange = (value: number) => {
    value;
  };

  const handlerOnSubmit = (data: any) => {
    console.log(data)
  };

  return (
    <>
      <Row className='mb-5'>
        <Col>
          <Breadcrumb menus={breadcrumbMenus} />
        </Col>
      </Row>
      <Row className='mb-5'>
        {Array.from(Array(4)).map((_, i) => (
          <Col key={i} lg='3' md='4' xs='6'>
            {/* <ContentCard data={streaming[i]} /> */}
          </Col>
        ))}
      </Row>
      <Row className='mb-5'>
        {Array.from(Array(4)).map((_, i) => (
          <Col key={i} className='mb-4' md='6' xs='12'>
            <NewsCard data={newses[i]} />
          </Col>
        ))}
      </Row>
      <Row className='mb-5'>
        <Col>
          <Button className='me-2' variant='primary'>Button</Button>
          <Button className='me-2' variant='outline-primary'>Button</Button>
          <Button className='me-2' variant='light'>Button</Button>
          <Button className='me-2' variant='outline-light'>Button</Button>
          <Button className='me-2' size='sm' variant='primary'>Button</Button>
          <Button className='me-2' size='sm' variant='outline-primary'>Button</Button>
          <Button className='me-2' size='sm' variant='light'>Button</Button>
          <Button className='me-2' size='sm' variant='outline-light'>Button</Button>
        </Col>
      </Row>
      <Row className='mb-5'>
        <Col>
          {Array.from(Array(4)).map((_, i) =>
            <Button className='me-2' variant='link' key={i}>Button</Button>)}
          {Array.from(Array(4)).map((_, i) =>
            <Button className='me-2' size='sm' variant='link' key={i}>Button</Button>)}
        </Col>
      </Row>
      <Row className='mb-5'>
        <Col>
          <HeaderCard
            date={new Date()}
            image='./images/rov-1440x439.png'
            tag='ข่าวสาร'
            topic='ทีมชาติไทยผลงานเข้ม!! ผ่านเข้าสู่รอบ Playoff ในการแข่งขัน Dota2 ประเภทหญิง รายการ GEG 2022 Dota2 Regional Qualifier : Asia Pacific Women ' />
        </Col>
      </Row>
      <Row className='mb-5'>
        <Col>
          <Card>
            {Array.from(Array(10)).map((_, i) =>
              <p key={i}>This is some text within a card body.</p>)}
          </Card>
        </Col>
      </Row>
      <Row className='mb-5'>
        <Col>
          <Form rules={formRules} onSubmit={handlerOnSubmit}>
            <Row>
              <Col>
                <Input label='Input 1' name='input1' />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input label='Input 2' name='input2' />
              </Col>
            </Row>
            <Row>
              <Col>
                <Select label='Select' name='select' items={select} />
              </Col>
            </Row>
            <Row>
              <Col>
                <MultiSelect label='Multi Select' name='multiSelect' items={multiSelect} />
              </Col>
            </Row>
            <Row>
              <Col>
                <DatePicker label='Date Picker' name='datePicker' />
              </Col>
            </Row>
            <Row>
              <Col>
                <Checkbox label='Checkbox' name='checkbox' />
              </Col>
            </Row>
            <Row>
              <Col>
                <Textarea label='Textarea' name='textarea' />
              </Col>
            </Row>
            <Row>
              <Col className='d-grid'>
                <Button
                  type='submit'
                  variant='primary'>
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row >
      <Row className='mb-5'>
        <Col>
          <Pagination totalItems={100} onChange={paginationOnChange} />
        </Col>
      </Row>
      <Row className='mb-5'>
        {Array.from(Array(8)).map((_, i) =>
          <Col key={i} sm='6' md='4' lg='3' className='mb-3'>
            <InfoCard
              img='./images/profile-496x512.png'
              fullName='Jackson A Wing'
              nickname='Wing'
              nationality='TH'
              club={{ name: 'club', img: './images/club-496x512.png' }}
              footer={
                <>
                  <Row>
                    <Col className='text-center'>
                      การแข่งขัน
                    </Col>
                  </Row>
                  <Row>
                    <Col className='text-center'>
                      MCT Premiership S3
                    </Col>
                  </Row>
                </>
              } />
          </Col>)}
      </Row>
    </>
  );
}