import toast from 'utils/toast';
import { Form, Image } from 'react-bootstrap';
import { MdAdd } from 'react-icons/md';
import firebase from 'services/firebase';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Rules, useValidate, ValidateResult, ValidateType } from 'hooks';
import { FormState } from 'utils';
import { connect } from 'react-redux';
import { Event, EventType } from 'utils/stores/formStore';
import { UploadImageType } from 'utils/constants/uploadImageType';
import uuid from 'react-uuid';

interface Props {
  label?: string;
  name: string;
  value?: string;
  type: UploadImageType;
  getImage?: (data: string) => void;

  // props from store
  rules: Rules;
  event: Event | undefined;
}

function Control(props: Props) {
  const [value, setValue] = useState<string>('');
  const [imgUrl, setImgUrl] = useState<string>();
  const ref = useRef({} as HTMLInputElement);
  const [rule, setRule] = useState<ValidateType | null>();
  const [validateResult, setValidateResult] = useState<ValidateResult>();
  const validate = useValidate();

  useEffect(() => {
    if (props.value) {
      setValue(props.value);

      getImageAsync(props.value);
    }
  }, [props.value]);

  useEffect(() => {
    if (props.rules) {
      setRule(props.rules[props.name]);
    }
  }, [props.rules]);

  useEffect(() => {
    if (props.event?.type === EventType.ON_SUBMIT) {
      handlerOnSubmit();
    }
  }, [props.event]);

  const onValidate = useCallback((value: string | number) => {
    const result = validate.process(value, rule);

    setValidateResult(result);
  }, [rule, props.name]);

  const handlerFileChange = useCallback(async (event: HTMLInputElement) => {
    if (event.files?.length) {
      const file = event.files[0];

      await upload(file);
    }
  }, []);

  const upload = useCallback(async (file: File) => {
    const imgName = uuid();
    const path = `${props.type}/${imgName}`;

    await firebase.uploadBytesAsync(file, imgName, props.type)
      .then(async () => {
        await getImageAsync(path);

        setValue(path);

        onValidate(path);

        toast.success('อัพโหลดรูปสำเร็จ');
      })
      .catch(() => toast.error('อัพโหลดรูปไม่สำเร็จ'));
  }, []);

  const getImageAsync = async (path: string) => {
    const imgUrl = await firebase.getImageAsync(path);

    setImgUrl(imgUrl);

    if (props.getImage) {
      props.getImage(imgUrl);
    }
  };

  const handlerOnClick = () => {
    ref.current.click();
  };

  const handlerOnChange = (event: HTMLInputElement) => {
    const value = event.value;

    setValue(value);

    onValidate(value);

  };

  const handlerOnSubmit = useCallback(() => {
    onValidate(value);
  }, [value, rule]);

  return (
    <div className='custom-file-input text-center mb-3'>
      <Form.Label>{props.label}</Form.Label>
      <div className='image'>
        {imgUrl ?
          <div className='profile cursor-pointer' onClick={() => handlerOnClick()}>
            <Image alt='profile' src={imgUrl} />
            <span>แก้ไขรูปภาพ</span>
          </div> :
          <div
            onClick={() => handlerOnClick()}
            className='no-image cursor-pointer'>
            <MdAdd className='text-danger fs-3' />
          </div>}
      </div>
      {validateResult?.hasError ?
        <div className='invalid-feedback'>
          กรุณาเพิ่มรูปโปรไฟล์
        </div> : null}
      <Form.Control
        ref={ref}
        className='d-none'
        type='file'
        onChange={event => handlerFileChange(event.target as HTMLInputElement)} />
      <Form.Control
        className='control d-none'
        name={props.name}
        value={value}
        onChange={event => handlerOnChange(event.target as HTMLInputElement)} />
    </div>
  );
}

const mapStateToProps = (state: FormState) => state.formStore;

const Upload = connect(mapStateToProps)(Control);

export default Upload;