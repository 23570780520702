import { Button, Row, Col, Image, Spinner } from 'react-bootstrap';
import { ModalType, HttpStatusCode, ValidatePattern } from 'utils';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks';
import { useCallback, useState } from 'react';
import { Form, Modal, Otp } from 'components';
import otp from 'services/otp';
import toast from 'utils/toast';

interface Props {
  show: boolean;
  onClose: () => void;
  next: (value: ModalType) => void;
}

export default function VerifyOtpModal(props: Props) {
  const { t } = useTranslation('modal', { keyPrefix: 'register.verifyOtp' });
  const store = useAppSelector((state) => state.commonStore);
  const [isDisabled, setIsDisabled] = useState(false);
  const formRule = {
    otp: { required: true, pattern: ValidatePattern.NUMBER, },
  };

  const submitHandler = useCallback(async (data: { otp: string }) => {
    setIsDisabled(true);

    const response = await otp.validateAsync(store.email, data.otp);

    if (response.status === HttpStatusCode.OK && response.data) {
      return props.next(ModalType.REGISTER_FORM);
    }

    toast.error('รหัส OTP ผิด');

    setIsDisabled(false);
  }, [store]);

  return (
    <Modal show={props.show} closeButton onClose={() => props.onClose()}>
      <Modal.Body>
        <div className='title'>
          <h4>
            {t('confirmEmail')}
          </h4>
        </div>
        <div className='content'>
          <Form onSubmit={submitHandler} rules={formRule}>
            <Row className='mb-4'>
              <Col className='d-flex justify-content-center'>
                <Image src='icons/email.svg' />
              </Col>
            </Row>
            <Row className='mb-3 text-center'>
              <Col>
                <p className='mb-1'>{t('pleaseInputOtpCodeFrom')}</p>
                <p className='mb-1'>{store.email}</p>
                <p className='mb-1'>{t('ref')}: {store.otpRef}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Otp name='otp' />
              </Col>
            </Row>
            <Row>
              <Col className='d-grid'>
                <Button
                  className='btn-login'
                  variant='primary'
                  type='submit'
                  disabled={isDisabled}>
                  {t('confirm')}
                  {isDisabled ?
                    <Spinner
                      className='ms-1'
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true' /> : null}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}