import { Button, Pagination as PaginationComponent, TextHeader, Table } from 'components';
import { Nav, Image, Tab, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useUser } from 'hooks';
import { GamePlayed, Game, CompetitionHistory, Pagination, User } from 'models';
import { useCallback, useEffect, useState } from 'react';
import { firebase } from 'services';
import { FaPlus } from 'react-icons/fa';
import { HttpStatusCode, ORDINAL_NUMBER } from 'utils';
import AddCompetitionModal from './modals/AddCompetitionModal';
import { game } from 'services';
import { useLoaderData } from 'react-router-dom';
import { convertToDateOnly } from 'utils';

export default function History() {
  const loader = useLoaderData() as { user: User };
  const user = useUser();
  const { t } = useTranslation('modal');
  const { userStore, gamesPlayed, games, competitionHistoriesWithPagination } = useAppSelector((state) => ({
    userStore: state.commonStore.user as User,
    gamesPlayed: state.commonStore.gamesPlayed as GamePlayed[],
    games: state.commonStore.games as Game[],
    competitionHistoriesWithPagination: state.commonStore.competitionHistoriesWithPagination as Pagination<CompetitionHistory>,
  }));
  const [addCompetitionModal, setAddCompetitionModal] = useState(false);
  const [dataTable, setDataTable] = useState<CompetitionHistory[]>(competitionHistoriesWithPagination?.rows);
  const [totalRows, setTotalRows] = useState(competitionHistoriesWithPagination?.totalRows);
  const [tapGameActive, setTabGameActive] = useState<string>('');

  useEffect(() => {
    if (gamesPlayed?.length > 0) {
      setTabGameActive(gamesPlayed[0].id);
    }
  }, [gamesPlayed]);

  useEffect(() => {
    if (tapGameActive) {
      getCompetitionHistoriesWithPaginationAsync(tapGameActive, 1);
    }
  }, [tapGameActive]);

  const paginationOnChange = useCallback(async (page: number) => {
    await getCompetitionHistoriesWithPaginationAsync(tapGameActive, page);
  }, [tapGameActive]);

  const getOrdinalNumber = (value: string) => {
    if (Number(value) >= 4) {
      return ORDINAL_NUMBER[4];
    }

    return ORDINAL_NUMBER[value as keyof typeof ORDINAL_NUMBER];
  };

  const getCompetitionHistoriesWithPaginationAsync = useCallback(async (id: string, page?: number) => {
    const response = await game.getCompetitionHistoriesWithPaginationAsync(loader.user.id, id, page ?? 1);

    if (response.status === HttpStatusCode.OK) {
      setDataTable(response.data.rows);
      setTotalRows(response.data.totalRows);
    }
  }, [loader, tapGameActive]);

  const handlerTabGameOnClick = useCallback(async (id: string) => {
    if (tapGameActive !== id) {
      setTabGameActive(id);

      await getCompetitionHistoriesWithPaginationAsync(id);
    }
  }, [tapGameActive]);

  const handlerModalOnClose = useCallback(async (gamePlayedId: string | undefined) => {
    if (gamePlayedId) {
      const id = gamesPlayed.find(f => f.id === gamePlayedId);

      if (id) {
        await getCompetitionHistoriesWithPaginationAsync(id.id);
      }
    }

    setAddCompetitionModal(false);
  }, []);

  return (
    <>
      <div className='mb-5'>
        <Tab.Container defaultActiveKey={0}>
          <Nav variant='pills' className='custom-nav'>
            {gamesPlayed?.map((gamePlayed, i) =>
              <Nav.Item key={i}>
                <Nav.Link eventKey={i} onClick={() => handlerTabGameOnClick(gamePlayed.id)}>
                  <Item
                    gamePlayed={gamePlayed}
                    games={games} />
                </Nav.Link>
              </Nav.Item>
            )}
          </Nav>
          <Tab.Content>
            <TextHeader
              text={t('competition.competition')}
              className='mt-5 mb-5'>
              <>
                {userStore.id === user.data.id ?
                  <Button
                    variant='outline-light'
                    onClick={() => setAddCompetitionModal(true)}>
                    <>
                      <FaPlus className='me-2' />
                      เพิ่มการแข่งขัน
                    </>
                  </Button> : null}
              </>
            </TextHeader>
            <Table>
              <thead>
                <tr className='text-center'>
                  <th>วันที่แข่ง</th>
                  <th>อันดับ</th>
                  <th>ระดับชั้น</th>
                  <th>ประเภท</th>
                  <th>ชื่อการแข่ง</th>
                  <th>ทีม</th>
                  <th>ผลการแข่งขัน</th>
                  <th>เงินรางวัล</th>
                </tr>
              </thead>
              <tbody>
                {dataTable?.map(item =>
                  <tr key={item.id}>
                    <td className='text-center'>{convertToDateOnly(item.date)}</td>
                    <td className={`text-center ranking ranking-${item.ranking}`}>
                      {item.ranking}
                      <span className='ordinal-number'>{getOrdinalNumber(item.ranking)}</span>
                    </td>
                    <td className='text-center'>{item.class}</td>
                    <td className='text-center'>{item.type}</td>
                    <td>{item.name}</td>
                    <td className='text-center'>{item.team}</td>
                    <td className='text-center'>{item.result}</td>
                    <td className='text-right'>{item.reward}</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {totalRows > 0 ?
              <div className='d-flex justify-content-center mt-3'>
                <PaginationComponent
                  totalItems={totalRows}
                  onChange={paginationOnChange} />
              </div> : null}
          </Tab.Content>
        </Tab.Container>
      </div>
      <AddCompetitionModal
        show={addCompetitionModal}
        onClose={gamePlayedId => handlerModalOnClose(gamePlayedId)} />
    </>
  );
}

function Item({ gamePlayed, games }: {
  gamePlayed: GamePlayed,
  games: Game[],
}) {
  const [img, setImg] = useState<string>();
  const [game, setGame] = useState<Game>();

  useEffect(() => {
    getImageAsync();
  }, []);

  const getImageAsync = useCallback(async () => {
    const game = games.find(f => f.id === gamePlayed.gameId);

    setGame(game);

    if (game !== undefined && game.img) {
      const imgUrl = await firebase.getImageAsync(game.img);

      setImg(imgUrl);
    }
  }, []);

  return (
    <div className='d-flex flex-column align-items-center'>
      {img ?
        <Image alt={game?.shortName} height={40} src={img} width={40} className='mb-2' /> :
        <Spinner animation='border' variant='primary' className='mt-2' />}
      {game?.shortName}
    </div>
  );
}