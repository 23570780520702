import { Navbar, FooterEventLayout, CookieConsent, TitleEvent } from 'components';
import { Container } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import './i18n';
import { ClubDetailModal } from 'pages';
import { useNavigate, Outlet, useLoaderData } from "react-router-dom";
import TextLogo from 'components/Background/TextLogo';
import { AssociationMatchType, IAssociationTournamentResponse, User } from 'models';
import { HttpStatusCode, ModalType } from 'utils';
import { useState, useCallback } from 'react';
import RegisterForm from 'components/Navbar/modals/RegisterFormModal';
import CheckExistEmailModal from 'components/Navbar/modals/CheckExistEmailModal';
import VerifyOtpModal from 'components/Navbar/modals/VerifyOtpModal';
import { useEffect } from 'react';
import { Game } from 'models';
import { associationTournament, associationTournamentMatch, account, firebase } from 'services';
import game from 'services/game';
import ModalEventsDetail from 'pages/Events/Detail';
import LoginModal from 'components/Navbar/modals/LoginModal';
import toast from 'utils/toast';
import { useTranslation } from 'react-i18next';

export type TournamentLoader = {
  tournament: IAssociationTournamentResponse
}

export default function EventLayout() {
  const [tournamentData, setTournamentData] = useState<IAssociationTournamentResponse>({} as IAssociationTournamentResponse);
  const loader = useLoaderData() as TournamentLoader;
  const [modalIsOpened, setModelIsOpened] = useState(false);
  const [modalType, setModalType] = useState<ModalType>();
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [gameData, setGameData] = useState<Game>();
  const [user, setUser] = useState<User>({} as User);
  const userId = firebase.cookies.get('userId');
  const { t } = useTranslation('[tournament, home]');
  const [hasRegisteredTournament, setHasRegisteredTournament] = useState(false);
  const [registerCount, setRegisterCount] = useState(0);
  const [hideButtonRegister, setHideButtonRegister] = useState(false);
  const [cadidateCount, setCadidateCount] = useState<{ teamCount: number, playerCount: number }>({ teamCount: 0, playerCount: 0 });
  const navigate = useNavigate();

  const checkParams = () => {
    if (loader.tournament === undefined || loader.tournament === null) {
      return navigate('/');
    }
  };

  const showModal = useCallback((modal: ModalType) => {
    return modalIsOpened && modalType === modal;
  }, [modalIsOpened, modalType]);

  useEffect(() => {
    getTournamentByIdAsync(loader.tournament.id);
    getGameByIdAsync(loader.tournament.game.id);
    getTournamentRegister(loader.tournament.id);
    getTournamentCadidateCount(loader.tournament.id);
    checkParams

    if (userId) {
      getUserAsync(userId);
      checkTournamentRegister(userId, loader.tournament.id);
    }

    onHideAfterSave();
  }, [loader.tournament, userId, loader]);

  const getTournamentByIdAsync = useCallback(async (id: string) => {
    const response = await associationTournament.getAssociationTournamentByIdAsync(id);
    if (response.status === HttpStatusCode.OK) {
      setTournamentData(response.data);
      await getGameByIdAsync(response.data.game.id);
    } else {
      navigate('/home');
    }
  }, []);

  const getGameByIdAsync = useCallback(async (gameId: string) => {
    const response = await game.getGameByIdAsync(gameId);
    if (response.status === HttpStatusCode.OK) {
      setGameData(response.data);
    }
  }, []);

  const getUserAsync = useCallback(async (userId: string) => {
    const response = await account.getUserAsync(userId);

    if (response.status === HttpStatusCode.OK) {
      setUser(response.data);
    }
  }, [userId]);

  const onHideAfterSave = () => {
    const endRegistrationDate = new Date(tournamentData.endRegistrationDate);
    const dateNow = new Date();
    if (dateNow > endRegistrationDate) {
      setHideButtonRegister(true);
      return;
    }

    if (hasRegisteredTournament) {
      setHideButtonRegister(true);
      return;
    }

    if (registerCount === tournamentData?.teamAmount) {
      setHideButtonRegister(true);
      return;
    }

    setShowModalDetail(false);
    getTournamentByIdAsync(loader.tournament.id);
  };

  const checkTournamentRegister = useCallback(async (id: string, tournamentId: string) => {
    const { status } = await associationTournament.checkRegisterTournamentAsync(tournamentId, id);

    if (status === HttpStatusCode.CONFLICT) {
      setHasRegisteredTournament(true);
    }
  }, []);

  const getTournamentRegister = useCallback(async (tournamentId: string) => {
    const { data } = await associationTournament.getRegisterTournamentAsync(tournamentId);

    setRegisterCount(data.length);
  }, []);

  const handleRegisterTournament = () => {
    const isLogin = firebase.cookies.get('token');

    if (isLogin === undefined || isLogin === '' || isLogin === 'undefined') {
      setModalType(ModalType.LOGIN);
      setModelIsOpened(true);

      return;
    }

    const stringValidation = (value: string) => value === undefined || value === null || value === ''; 

    if (
      stringValidation(user.firstName) ||
      stringValidation(user.lastName) ||
      stringValidation(user.tel) ||
      stringValidation(user.email) ||
      stringValidation(user.gender) ||
      stringValidation(user.nationality) ||
      stringValidation(user.birthday) ||
      stringValidation(user.img)
    ) {
      return toast.info(t('tournament:alert:fullfillProfile'));
    }

    if (user !== undefined
      && (user.clubId === undefined || user.clubId === null || user.clubId === '')
      && (loader.tournament.associationMatchType === AssociationMatchType.Team || loader.tournament.associationMatchType === AssociationMatchType.Duo)) {
      return toast.info(t('tournament:alert:userHasNoClub'));
    }

    const endRegistrationDate = new Date(tournamentData.endRegistrationDate);
    const dateNow = new Date();
    if (dateNow > endRegistrationDate) {
      return toast.info(t('tournament:alert:expiredRegister'));
    }

    if (hasRegisteredTournament && loader.tournament.associationMatchType === AssociationMatchType.Single) {
      return toast.info(t('tournament:alert:applyRegister'));
    }

    if (registerCount === tournamentData?.teamAmount) {
      return toast.info(t('tournament:alert:fullRegister'));
    }

    setShowModalDetail(true);
  };

  const getTournamentCadidateCount = useCallback(async (tournamentId: string) => {
    const { data, status } = await associationTournamentMatch.getAssociationTournamentTeamsCandidateCountAsync(tournamentId);

    if (status !== HttpStatusCode.OK) {
      toast.error(t('home:alert:serverError'));

      return
    }

    setCadidateCount(data);
  }, []);

  return (
    <>
      <Navbar />
      <div className='box-logo-text'>
        <TextLogo text={loader.tournament.game.shortName} />
      </div>
      <Container fluid='xl'>
        <TitleEvent
          nameEvent={loader.tournament.name}
          subTitle={loader.tournament.game.fullName}
          registrationStartDate={loader.tournament.startRegistrationDate}
          registrationEndDate={loader.tournament.endRegistrationDate}
          competitionStartDate={loader.tournament.competitionStartDate}
          competitionEndDate={loader.tournament.competitionEndDate}
          imgProfile={loader.tournament.imagePath}
          countType={loader.tournament.associationMatchType}
          teamCount={cadidateCount.teamCount}
          playerCount={cadidateCount.playerCount} />
        <Outlet />
      </Container>
      <CookieConsent />
      <FooterEventLayout
        hideButtonRegister={hideButtonRegister}
        ICon={loader.tournament.game.img}
        IOnClick={handleRegisterTournament}
        IEndDateRegis={loader.tournament.endRegistrationDate} />
      <ToastContainer />
      <ClubDetailModal />

      <LoginModal
        show={showModal(ModalType.LOGIN)}
        onClose={() => { setModelIsOpened(false) }}
        next={value => {
          setModalType(value);
          setModelIsOpened(true);
        }} />
      <RegisterForm
        show={showModal(ModalType.REGISTER_FORM)}
        onClose={() => setModelIsOpened(false)} />
      <CheckExistEmailModal
        show={showModal(ModalType.CHECK_EXIST_EMAIL)}
        onClose={() => setModelIsOpened(false)}
        next={value => {
          setModalType(value);
          setModelIsOpened(true);
        }} />
      <VerifyOtpModal
        show={showModal(ModalType.VERIFY_OTP)}
        onClose={() => setModelIsOpened(false)}
        next={() => {
          setModalType(ModalType.REGISTER_FORM);
          setModelIsOpened(true);
        }} />
      <ModalEventsDetail
        gameName={gameData?.fullName ?? gameData?.shortName ?? ''}
        tournamentData={tournamentData}
        show={showModalDetail}
        onHide={() => { setShowModalDetail(false) }}
        onHideAfterSave={() => onHideAfterSave()}
        userData={user} />
    </>
  );
}