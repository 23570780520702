import { CustomValidate, Rules, ValidateType } from 'hooks/useForm';
import { FormState } from 'utils/stores/configure/formConfigure';
import { useMemo, useState, useCallback } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Event, EventType, setData } from 'utils/stores/formStore';
import { useValidate, ValidateResult } from 'hooks/useValidate';
import { FaRegEye, FaRegEyeSlash, FaSearch } from 'react-icons/fa';
import { Button } from 'components/Button';
import { useEffect } from 'react';
import { useAppDispatch, useFormSelector } from 'hooks';

enum Icon {
  SEARCH = 'search',
}

interface Props {
  label?: string;
  type?: string;
  name: string;
  id?: string;
  icon?: string;
  placeholder?: string;
  value?: string | number;
  onChange?: (value: string) => void;
  disabled?: boolean;
  className?: string;
  onSearch?: () => void;

  // props from store
  rules: Rules;
  event: Event | undefined;
}

function Control(props: Props) {
  const [value, setValue] = useState<string | number>('');
  const validate = useValidate();
  const [eyeIsOpened, setEyeIsOpened] = useState(false);
  const [rule, setRule] = useState<ValidateType | null>();
  const [validateResult, setValidateResult] = useState<ValidateResult>();
  const dispatch = useAppDispatch();
  const formStore = useFormSelector((state) => state.formStore);

  useEffect(() => {
    if (props.rules) {
      setRule(props.rules[props.name]);
    }
  }, [props.rules]);

  useEffect(() => {
    if (props.event?.type === EventType.ON_SUBMIT) {
      handlerOnSubmit();
    }
  }, [props.event]);

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);

  const isRequired = useMemo(() => {
    if (rule && rule.required) {
      if (typeof rule.required === typeof {}) {
        return (rule.required as CustomValidate).value;
      }

      return rule.required ?? false;
    }

    return false;
  }, [rule]);

  const onValidate = useCallback((value: string | number) => {
    const result = validate.process(value, rule, formStore.data);

    setValidateResult(result);

    if (result) {
      dispatch(setData({ key: props.name, value: value }));
    }
  }, [rule, formStore]);

  const handlerOnChange = useCallback((event: HTMLInputElement) => {
    const value = event.value;

    setValue(value);

    onValidate(value);

    if (props.onChange) {
      props.onChange(value);
    }
  }, [onValidate, formStore]);

  const icon = useMemo(() => {
    if (props.icon) {
      if (props.icon === Icon.SEARCH) {
        return (
          <InputGroup.Text>
            <FaSearch className='cursor-pointer' onClick={props.onSearch} />
          </InputGroup.Text>
        );
      }
    }

    return null;
  }, [props.type, props.icon, eyeIsOpened, value]);

  const type = useMemo(() => {
    if (props.type === 'password') {
      if (eyeIsOpened) {
        return 'text';
      }

      return 'password';
    }

    return props.type;
  }, [props.type, eyeIsOpened]);

  const handlerOnSubmit = useCallback(() => {
    onValidate(value);
  }, [value, rule, formStore]);

  return (
    <div className={`${props.className ?? ''} input mb-3`}>
      <Form.Label>
        {props.label}
        {isRequired ? <span className='text-danger'> *</span> : null}
      </Form.Label>
      <InputGroup
        hasValidation>
        <Form.Control
          disabled={props.disabled}
          name={props.name}
          type={type}
          value={value}
          placeholder={props.placeholder}
          onChange={event => handlerOnChange(event.target as HTMLInputElement)}
          className={`control ${validateResult?.hasError ? 'is-invalid' : ''}`} />
        {props.type === 'password' ?
          <EyeButton onChange={value => setEyeIsOpened(value)} /> :
          icon}
        <Form.Control.Feedback type='invalid'>
          {validateResult?.message}
        </Form.Control.Feedback>
      </InputGroup>
    </div>
  );
}

function EyeButton(props: { onChange: (value: boolean) => void }) {
  const [eyeIsOpened, setEyeIsOpened] = useState(false);

  const icon = useMemo(() => {
    return eyeIsOpened ? <FaRegEye /> : <FaRegEyeSlash />;
  }, [eyeIsOpened]);

  const handlerOnClick = useCallback(() => {
    setEyeIsOpened(!eyeIsOpened);

    props.onChange(!eyeIsOpened);
  }, [eyeIsOpened]);

  return (
    <Button
      type='button'
      variant='input'
      onClick={handlerOnClick}
      className='d-flex align-items-center'>
      {icon}
    </Button>
  );
}

const mapStateToProps = (state: FormState) => state.formStore;

const Input = connect(mapStateToProps)(Control);

export default Input;