import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface State {
  image?: string | undefined;
  text?: string | undefined;
}

const initialState: State = {};

export const backgroundStore = createSlice({
  name: 'background',
  initialState: initialState,
  reducers: {
    setImage: (state: State, action: PayloadAction<string>) => {
      state.image = action.payload;
    },
    setText: (state: State, action: PayloadAction<string>) => {
      state.text = action.payload;
    },
  },
});

export const { setImage, setText } = backgroundStore.actions;

export default backgroundStore.reducer;