export const PROFILE =
{
  name: 'Jackson A Wing',
  img: '/images/profile-496x512.png',
  nickname: 'Jack',
  nationality: 'ไทย',
  nameclub: 'Club Name',
  imgclub: '/images/club-496x512.png',
  gender: 'ชาย',
  birthday: '12 ต.ค. 2542',
  age: '(18 ปี)',
};

export const CONTENT =
{
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  email: 'JacksonWigng1212@gmail.com',
  discord: 'J.Wing1234',
  game: [
    {
      id: 1,
      name: 'ROV',
      yearRage: '2012 - 2022',
      logo: '/images/rov-logo-512x512.png',
      position: 'ชื่อตำแหน่ง',
      hero: 'อันดับ',
      rating: 'อันดับ',
    },
    {
      id: 2,
      name: 'DOTA',
      yearRage: '2012 - 2022',
      logo: '/images/dota.png',
      position: 'ชื่อตำแหน่ง',
      hero: 'อันดับ',
      rating: 'อันดับ',
    },
    {
      id: 3,
      name: 'PUB G',
      yearRage: '2012 - 2022',
      logo: '/images/pubg.png',
      position: 'ชื่อตำแหน่ง',
      hero: 'อันดับ',
      rating: 'อันดับ',
    },
    {
      id: 4,
      name: 'VALORANT',
      yearRage: '2012 - 2022',
      logo: '/images/vrl.png',
      position: 'ชื่อตำแหน่ง',
      hero: 'อันดับ',
      rating: 'อันดับ',
    },
    {
      id: 5,
      name: 'Lol',
      yearRage: '2012 - 2022',
      logo: '/images/lol.png',
      position: 'ชื่อตำแหน่ง',
      hero: 'อันดับ',
      rating: 'อันดับ',
    },
    {
      id: 6,
      name: 'Lol',
      yearRage: '2012 - 2022',
      logo: '/images/lol.png',
      position: 'ชื่อตำแหน่ง',
      hero: 'อันดับ',
      rating: 'อันดับ',
    },
  ]
};

export const TABLE_DOTA_COLUMNS = [
  {
    header: 'วันที่แข่ง',
    sort: true,
    className: 'text-start text-nowrap',
  },
  {
    header: 'อันดับ',
    sort: true,
    className: 'text-center text-nowrap',
  },
  {
    header: 'ระดับชั้น',
    sort: true,
    className: 'text-center text-nowrap',
  },
  {
    header: 'ประเภท',
    sort: true,
    className: 'text-center text-nowrap',
  },
  {
    header: 'ชื่อการแข่ง',
    sort: true,
    className: 'text-start text-nowrap',
  },
  {
    header: 'ทีม',
    sort: true,
    className: 'text-center text-nowrap',
  },
  {
    header: 'ผลการแข่งขัน',
    sort: true,
    className: 'text-center text-nowrap',
  },
  {
    header: 'เงินรางวัล',
    sort: true,
    className: 'text-center text-nowrap',
  },
];

export const TABLE_DOTA_ROWS = [
  {
    dayRace: '10-10-2022',
    rating: 1,
    class: 'C-Tier',
    type: 'ออนไลน์',
    raceName: '99Liga S11 Div. 1 - League',
    imgclub: '/images/club-496x512.png',
    team:'Team A',
    results: '6/8',
    prizeMoney:'฿20,000',
  },
  {
    dayRace: '10-10-2022',
    rating: 2,
    class: 'C-Tier',
    type: 'ออนไลน์',
    raceName: '99Liga S11 Div. 1 - League',
    imgclub: '/images/club-496x512.png',
    team:'Team A',
    results: '6/8',
    prizeMoney:'฿20,000',
  },
  {
    dayRace: '10-10-2022',
    rating: 3,
    class: 'C-Tier',
    type: 'ออนไลน์',
    raceName: '99Liga S11 Div. 1 - League',
    imgclub: '/images/club-496x512.png',
    team:'Team A',
    results: '6/8',
    prizeMoney:'฿20,000',
  },
  {
    dayRace: '10-10-2022',
    rating: 4,
    class: 'C-Tier',
    type: 'ออนไลน์',
    raceName: '99Liga S11 Div. 1 - League',
    imgclub: '/images/club-496x512.png',
    team:'Team A',
    results: '6/8',
    prizeMoney:'฿20,000',
  },
  {
    dayRace: '10-10-2022',
    rating: 5,
    class: 'C-Tier',
    type: 'ออนไลน์',
    raceName: '99Liga S11 Div. 1 - League',
    imgclub: '/images/club-496x512.png',
    team:'Team A',
    results: '6/8',
    prizeMoney:'฿20,000',
  },
  {
    dayRace: '10-10-2022',
    rating: 6,
    class: 'C-Tier',
    type: 'ออนไลน์',
    raceName: '99Liga S11 Div. 1 - League',
    imgclub: '/images/club-496x512.png',
    team:'Team A',
    results: '6/8',
    prizeMoney:'฿20,000',
  },
  {
    dayRace: '10-10-2022',
    rating: 6,
    class: 'C-Tier',
    type: 'ออนไลน์',
    raceName: '99Liga S11 Div. 1 - League',
    imgclub: '/images/club-496x512.png',
    team:'Team A',
    results: '6/8',
    prizeMoney:'฿20,000',
  },
];

export const TABLE_ROV_COLUMNS = [
  {
    header: 'วันที่แข่ง',
    sort: true,
    className: 'text-start text-nowrap',
  },
  {
    header: 'อันดับ',
    sort: true,
    className: 'text-center text-nowrap',
  },
  {
    header: 'ระดับชั้น',
    sort: true,
    className: 'text-center text-nowrap',
  },
  {
    header: 'ชื่อการแข่ง',
    sort: true,
    className: 'text-start text-nowrap',
  },
  {
    header: 'ทีม',
    sort: true,
    className: 'text-center text-nowrap',
  },
  {
    header: 'ผลการแข่งขัน',
    sort: true,
    className: 'text-center text-nowrap',
  },
  {
    header: 'เงินรางวัล',
    sort: true,
    className: 'text-center text-nowrap',
  },
];

export const TABLE_ROV_ROWS = [
  {
    dayRace: '10-10-2022',
    rating: 1,
    class: 'C-Tier',
    raceName: '99Liga S11 Div. 1 - League',
    imgclub: '/images/club-496x512.png',
    team:'Team A',
    results: '6/8',
    prizeMoney:'฿20,000',
  },
  {
    dayRace: '10-10-2022',
    rating: 3,
    class: 'C-Tier',
    raceName: '99Liga S11 Div. 1 - League',
    imgclub: '/images/club-496x512.png',
    team:'Team A',
    results: '6/8',
    prizeMoney:'฿20,000',
  },
  {
    dayRace: '10-10-2022',
    rating: 6,
    class: 'C-Tier',
    raceName: '99Liga S11 Div. 1 - League',
    imgclub: '/images/club-496x512.png',
    team:'Team A',
    results: '6/8',
    prizeMoney:'฿20,000',
  },
];

export const HISTORY_GAME = [
  {
    id: 1,
    name: 'ผลงานและรางวัล',
  },
  {
    id: 2,
    name: 'การแข่งขัน',
  },
  {
    id: 3,
    name: 'การเล่นเกม',
  },
  {
    id: 4,
    name: 'สังกัดสโมสร',
  },
];

export const TABLE_HISTORY_COLUMNS = [
  {
    header: 'ปี',
    sort: true,
    className: 'text-start text-nowrap',
  },
  {
    header: 'ชื่อการแข่ง',
    sort: true,
    className: 'text-start text-nowrap',
  },
  {
    header: 'เกม',
    sort: true,
    className: 'text-start text-nowrap',
  },
  {
    header: 'ประเภทการแข่ง',
    sort: true,
    className: 'text-center text-nowrap',
  },
  {
    header: 'ผลการแข่งขัน',
    sort: true,
    className: 'text-center text-nowrap',
  },
  {
    header: 'คะแนน',
    sort: true,
    className: 'text-center text-nowrap',
  },
  {
    header: 'ตำแหน่งที่เล่น',
    sort: true,
    className: 'text-center text-nowrap',
  },
  {
    header: 'ชื่อฮีไร่',
    sort: true,
    className: 'text-center text-nowrap',
  },
];

export const TABLE_HISTORY_ROW = [
  {
    year: '2011-2022',
    name: 'MCT Premiership S3',
    game: 'ROV',
    logoGame: '/images/rov-logo-512x512.png',
    type: 'เดี่ยว',
    results: '123',
    score: '2000',
    playingPosition:'XXXX',
    heroName:'XX',
  },
  {
    year: '2011-2022',
    name: 'MCT Premiership S3',
    game: 'ROV',
    logoGame: '/images/rov-logo-512x512.png',
    type: 'เดี่ยว',
    results: '123',
    score: '2000',
    playingPosition:'XXXX',
    heroName:'XX',
  },
  {
    year: '2011-2022',
    name: 'MCT Premiership S3',
    game: 'ROV',
    logoGame: '/images/rov-logo-512x512.png',
    type: 'เดี่ยว',
    results: '123',
    score: '2000',
    playingPosition:'XXXX',
    heroName:'XX',
  },
];