import { Children } from "react";

interface Props {
  children: JSX.Element | JSX.Element[];
}

export function Row(props: Props) {
  return (
    <tr>
      {props.children}
    </tr>
  );
}