import { Table, Button } from 'react-bootstrap';
import IconSort from 'assets/icons/IconSort';

interface Columns {
  header?: string;
  sort?: boolean;
  className?: string;
}

interface Props {
  columns: Columns[];
  rows: any;
}

export default function TablePlayer({ columns, rows }: Props) {
  return (
    <Table hover responsive striped className='my-2' variant='tertiary'>
      <thead>
        <tr>
          {columns.map((data, index) => (
            <th key={index} className={data.className}>
              {data.header} {data.sort ? <Button className='ms-1' variant='link'></Button> : null}
            </th>
          ))}

          <th className='w-5' />
        </tr>
      </thead>

      <tbody>
        {rows}
      </tbody>
    </Table>
  );
}