import { Content } from 'models/content';
import { convertToFullDate } from 'utils/converters/dateConverter';
import { Col, Row, Image } from 'react-bootstrap';
import { FaCalendarAlt } from 'react-icons/fa';

interface Props {
  data: Content;
}

export default function NewsCard({ data }: Props) {
  return (
    <div className='content-card h-50'>
      <Row>
        <Col>
          <div className='image'>
            <Image alt={data.topic} className='img-fluid d-flex mx-auto' src={`${data.imagePath}`} />
          </div>
        </Col>
        <Col className='d-flex align-items-start flex-column'>
          <Row className='mb-auto'>
            <Col>
              <h5 className='topic m-0'>{data.topic}</h5>
            </Col>
          </Row>
          <Row>
            <Col className='d-flex align-items-center text-muted'>
              <FaCalendarAlt className='me-1' /> {convertToFullDate(data.date)}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}