import { convertToFullDate } from '../../utils/converters/dateConverter';
import { Badge, Card, Col, Row } from 'react-bootstrap';
import { FaCalendarAlt } from 'react-icons/fa';

interface Props {
  image: string;
  topic: string;
  date: string | Date;
  tag: string;
}

export function HeaderCard({ image, topic, date, tag }: Props) {
  return (
    <Card className='custom-header-card'>
      <Card.Img alt={topic} src={image} variant='top' />

      <Card.Body>
        <Row className='mb-3'>
          <Col>
            <Badge bg='primary'>
              <h6 className='m-0'>{tag}</h6>
            </Badge>
          </Col>
        </Row>

        <Row>
          <Col>
            <h2 className='m-0'>{topic}</h2>
          </Col>
        </Row>
      </Card.Body>

      <Card.Footer>
        <Row>
          <Col className='d-flex align-items-center text-muted'>
            <FaCalendarAlt className='me-1' /> {convertToFullDate(date)}
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
}