import { useEffect, useMemo, useState } from 'react';
import { Modal as ModalBT } from 'react-bootstrap';
import { ModalSize } from 'utils';

interface Props {
  children: JSX.Element | JSX.Element[];
  show?: boolean;
  size?: ModalSize;
  closeButton?: boolean;
  onClose?: () => void;
  className?: string;
  classNameHeader?: string;
}

export function ModalDetailGame(props: Props) {
  const [show, setShow] = useState(props.show);

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const size = useMemo(() => {
    return props.size === ModalSize.MD ? undefined : props.size;
  }, [props.size]);

  const handlerOnHide = () => {
    setShow(false);

    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <ModalBT
      centered
      backdrop='static'
      backdropClassName='custom-modal-backdrop'
      className={`${props.className ?? ''} custom-modal`}
      show={show}
      onHide={handlerOnHide}
      size={size}>
      {props.children}
    </ModalBT>
  );
}

function Header(props: {
  children: JSX.Element | JSX.Element[],
  classNameHeader?: string;
}) {
  return (
    <ModalBT.Header className={`${props.classNameHeader ?? ''}`}>
      {props.children}
    </ModalBT.Header>
  );
}

function Body(props: {
  children: JSX.Element | JSX.Element[],
  className?: string;
}) {
  return (
    <ModalBT.Body className={`${props.className ?? ''}`}>
      {props.children}
    </ModalBT.Body>
  );
}

function Footer(props: {
  children: JSX.Element | JSX.Element[],
  className?: string;
}) {
  return (
    <ModalBT.Footer className={`${props.className ?? ''}`}>
      {props.children}
    </ModalBT.Footer>
  );
}

ModalDetailGame.Header = Header;
ModalDetailGame.Body = Body;
ModalDetailGame.Footer = Footer;