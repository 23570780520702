import { EventType, FormState, Event } from 'utils';
import { CustomValidate, Rules, useValidate, ValidateResult, ValidateType } from 'hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { connect } from 'react-redux';

interface Props {
  label?: string;
  name: string;
  id?: string;
  placeholder?: string;
  value?: string | number;
  items: { label: string, value: string }[] | any[];
  selectKey?: { label: string; value: string; };
  onChange?: (value: string) => void;
  disabled?: boolean;

  // props from store
  rules: Rules;
  event: Event | undefined;
}

function Control(props: Props) {
  const [value, setValue] = useState<string | number>('');
  const validate = useValidate();
  const [rule, setRule] = useState<ValidateType | null>();
  const [validateResult, setValidateResult] = useState<ValidateResult>();

  useEffect(() => {
    if (props.rules) {
      setRule(props.rules[props.name]);
    }
  }, [props.rules]);

  useEffect(() => {
    if (props.event?.type === EventType.ON_SUBMIT) {
      handlerOnSubmit();
    }
  }, [props.event]);

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);

  const isRequired = useMemo(() => {
    if (props.rules && props.name) {
      const rule = props.rules[props.name];

      if (rule) {
        if (typeof rule.required === typeof {}) {
          return (rule.required as CustomValidate).value;
        }

        return rule.required ?? false;
      }
    }

    return false;
  }, [props.rules, props.name]);

  const handlerOnChange = (event: HTMLSelectElement) => {
    const value = event.value;

    if (props.onChange) {
      if (value) {
        props.onChange(value);
      } else {
        props.onChange('');
      }
    }

    setValue(value);

    onValidate(value);
  };

  const onValidate = useCallback((value: string | number) => {
    const result = validate.process(value, rule);

    setValidateResult(result);
  }, [rule, props.name]);

  const handlerOnSubmit = useCallback(() => {
    onValidate(value);
  }, [value, rule]);

  const getItems = useMemo(() => {
    if (props.selectKey) {
      return props.items.map(item => {
        return {
          label: item[props.selectKey?.label as keyof typeof item],
          value: item[props.selectKey?.value as keyof typeof item],
        };
      });
    }

    return props.items;
  }, [props.items, props.selectKey]);

  return (
    <div className='select mb-3'>
      <Form.Label>
        {props.label}
        {isRequired ? <span className='text-danger'> *</span> : null}
      </Form.Label>
      <InputGroup
        hasValidation>
        <Form.Select
          disabled={props.disabled}
          style={{ color: `${props.disabled ? '#000000' : ''}` }}
          className={`control ${validateResult?.hasError ? 'is-invalid' : ''}`}
          name={props.name}
          value={value ?? ''}
          onChange={event => handlerOnChange(event.target as HTMLSelectElement)}>
          <option value='' disabled hidden />
          {getItems.map((item, i) => <option key={i} value={item.value}>{item.label}</option>)}
        </Form.Select>
        <Form.Control.Feedback type='invalid'>
          {validateResult?.message}
        </Form.Control.Feedback>
      </InputGroup>
    </div>
  );
}

const mapStateToProps = (state: FormState) => state.formStore;

const Select = connect(mapStateToProps)(Control);

export default Select;