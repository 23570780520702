import { Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Input from 'components/Controls/Input';
import Textarea from 'components/Controls/Textarea';
import Upload from 'components/Controls/Upload';
import { Form } from 'components/Form';
import { useCallback, useState, useEffect } from 'react';
import { HttpStatusCode, ModalSize, ValidatePattern } from 'utils';
import toast from 'utils/toast';
import { club as clubService } from 'services';
import { Club, SocialMediaClubs } from 'models';
import { useParams, useNavigate } from 'react-router-dom';
import { Modal, Select } from 'components';
import countries from 'data/json/countries.json';
import uuid from 'react-uuid';
import { SOCIAL_MEDIA } from 'utils/constants/socialMedia';
import { FaTrashAlt } from 'react-icons/fa';
import { HeadLine } from 'components/TextHeader/HeadLine';
import { UploadImageType } from 'utils/constants/uploadImageType';
import { AxiosResponse } from 'axios';
import { useLocation } from 'react-router-dom'

export function ClubDetailModal() {
  const navigate = useNavigate();
  const location = useLocation();
  const param = useParams();
  const [club, setClub] = useState<Club>({} as Club);
  const { t } = useTranslation(['modal', 'common']);
  const [show, setShow] = useState(false);
  const formRules = {
    clubName: { required: true },
    nationality: { required: true },
    email: { required: true, pattern: ValidatePattern.EMAIL },
    channel: { required: true },
    channelName: { required: true },
    followers: { required: true },
  };

  useEffect(() => {
    addEventListener();
  }, []);

  const addEventListener = () => document.addEventListener('onShowClubDetailModal', async (data) => {
    const event = data as CustomEvent;
    const id = event.detail.data?.id;

    if (id) {
      await getClubDataAsync(id);
    }

    setShow(true);
  });

  const getClubDataAsync = useCallback(async (id: string) => {
    const response = await clubService.getClubAsync(id);

    if (response.status === HttpStatusCode.OK) {
      setClub(response.data);
    }
  }, [club]);

  const addSocialMediaClub = useCallback(() => {
    const items = club.socialMediaClubs;

    items.push({ id: uuid() } as SocialMediaClubs);

    setClub({ ...club, socialMediaClubs: items });
  }, [club]);

  const deleteSocialClubMedia = useCallback((id: string) => {
    const items = club.socialMediaClubs.filter(f => f.id !== id);

    setClub({ ...club, socialMediaClubs: items });
  }, [club]);

  const submitHandler = useCallback(async (data: Club) => {
    let response: AxiosResponse = {} as AxiosResponse;

    if (club.id) {
      data.id = club.id;
      data.userId = club.userId;

      response = await clubService.updateAsync(data);
    } else {
      response = await clubService.createAsync(data);
    }

    if (response.status === HttpStatusCode.OK) {
      setShow(false);
      toast.success('บันทึกข้อมูลสำเร็จ');
      document.dispatchEvent(new CustomEvent('onClubCreated', { detail: { id: response.data.id } }));

      if (location.pathname.includes('/club/detail')) {
        document.dispatchEvent(new CustomEvent('onClubUpdated'));
      } else {
        navigate(`/club/detail/${response.data.id}`);
      }
    }
  }, [club]);

  return (
    <Modal show={show} onClose={() => setShow(false)} size={ModalSize.LG} className='club-detail-modal'>
      <Modal.Body>
        <Form onSubmit={submitHandler} rules={formRules}>
          <HeadLine title={t('club.clubInformation')} size='lg' />
          <Upload value={club?.image} label={t('club.image')} name='image' type={UploadImageType.IMAGE_CLUB} />
          <Row>
            <Col lg={6}>
              <Input value={club?.clubName} label={t('club.clubName')} name='clubName' />
            </Col>
            <Col lg={6}>
              <Select
                value={club?.nationality}
                label={t('club.nationality')}
                name='nationality'
                items={countries}
                selectKey={{ label: 'name', value: 'code' }} />
            </Col>
            <Col lg={6}>
              <Input value={club?.email} label={t('club.email')} name='email' />
            </Col>
            <Col lg={6}>
              <Input value={club?.discordId} label={t('club.discordId')} name='discordId' />
            </Col>
            <Col lg={12}>
              <Textarea value={club?.description} label={t('club.description')} name='description' />
            </Col>
          </Row>
          <HeadLine title={t('club.socialMedia')} size='md' className='mt-5' />
          <>
            {club.socialMediaClubs?.map((elem, i) =>
              <Form.List key={i} name='socialMediaClubs'>
                <div>
                  <Row>
                    <Col xs={2} lg={1} className='text-center social-media-no'>
                      {i + 1}
                    </Col>
                    <Col>
                      <Select
                        value={elem.channel}
                        label='ช่องทาง'
                        name='channel'
                        items={SOCIAL_MEDIA} />
                    </Col>
                    <Col>
                      <Input
                        label='ชื่อช่อง'
                        name='channelName'
                        value={elem.channelName}
                      />
                    </Col>
                    <Col xs={2} lg={1} className='d-flex justify-content-center'>
                      <Button
                        onClick={() => deleteSocialClubMedia(elem.id)}
                        type='button'
                        className='delete-button d-flex align-items-center'
                        variant='outline-primary'>
                        <FaTrashAlt />
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={2} lg={1} />
                    <Col>
                      <Input
                        value={elem.followers}
                        label='จำนวนผู้ติดตาม'
                        name='followers'
                        type='number' />
                    </Col>
                    <Col>
                      <Input
                        value={elem.link}
                        label='Link'
                        name='link' />
                    </Col>
                    <Col xs={2} lg={1} />
                  </Row>
                </div>
              </Form.List>
            )}
          </>
          <div className='d-flex justify-content-center'>
            <Button onClick={() => addSocialMediaClub()} type='button'>เพิ่มโซเชียลมีเดีย</Button>
          </div>
          <div className='d-flex justify-content-end'>
            <Button type='submit'>{t('club.save')}</Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal >
  );
}