import { ValidatePattern } from 'utils';
import regex from 'utils/regex';
import { ValidateType } from './useForm';

export type ValidateResult = { hasError: boolean, message?: string };

export function useValidate() {
  const process = (
    value: string | number | boolean | Date,
    rule: ValidateType | null | undefined,
    dataStore?: any,
  ) => {
    if (rule) {
      let result: ValidateResult = { hasError: false };

      result = validateRequired(value, rule);

      if (result.hasError) {
        return result;
      }

      result = validateMaxLength(value.toString(), rule);

      if (result.hasError) {
        return result;
      }

      result = validateMinLength(value.toString(), rule);

      if (result.hasError) {
        return result;
      }

      result = validateIdCardCodeAndPassportNo(value.toString(), rule);

      if (result.hasError) {
        return result;
      }

      result = validatePattern(value, rule);

      if (result.hasError) {
        return result;
      }

      result = validateMatching(value, rule, dataStore);

      if (result.hasError) {
        return result;
      }
    }

    return { hasError: false };
  };

  const validateRequired = (
    value: string | number | boolean | Date,
    rule: ValidateType,
  ) => {
    let required = false;
    let message = 'กรุณาระบุข้อมูล';

    if (typeof rule.required === 'boolean') {
      required = rule.required;
    } else {
      required = Boolean(rule.required?.value) ?? false;
      message = rule.required?.message ?? message;
    }

    if (required && isNotvalidInput(value)) {
      return {
        hasError: true,
        message: message,
      };
    }

    return { hasError: false };
  };

  const validateMaxLength = (
    value: string,
    rule: ValidateType,
  ) => {
    let maxLength = 0;
    let message = '';

    if (typeof rule.maxLength === 'number') {
      maxLength = rule.maxLength;
    } else {
      maxLength = Number(rule.maxLength?.value);
      message = rule.maxLength?.message ?? '';
    }

    if (maxLength > 0 && value.length > maxLength) {
      return {
        hasError: true,
        message: message ? message : `กรุณาระบุไม่เกิน ${maxLength} ตัวอักษร`,
      };
    }

    return { hasError: false };
  };

  const validateMinLength = (
    value: string,
    rule: ValidateType,
  ) => {
    let minLength = 0;
    let message = '';

    if (typeof rule.minLength === 'number') {
      minLength = rule.minLength;
    } else {
      minLength = Number(rule.minLength?.value);
      message = rule.minLength?.message ?? '';
    }

    if (minLength > 0 && value.length < minLength) {
      return {
        hasError: true,
        message: message ? message : `กรุณาระบุอย่างน้อย ${minLength} ตัวอักษร`,
      };
    }

    return { hasError: false };
  };

  const validateIdCardCodeAndPassportNo = (
    value: string,
    rule: ValidateType,
  ) => {
    if (!rule.idCardNoAndPassportNo) {
      return { hasError: false };
    }

    if (value.length !== 13) {
      return { hasError: false };
    }

    let sum = 0;

    for (let i = 0; i < 12; i++) {
      sum += parseFloat(value.charAt(i)) * (13 - i);
    }

    if ((11 - sum % 11) % 10 != parseFloat(value.charAt(12))) {
      return {
        hasError: true,
        message: 'รูปรหัสบัตรประชาชนแบบ',
      };
    }

    return { hasError: false };
  };

  const validatePattern = (
    value: string | number | boolean | Date,
    rule: ValidateType,
  ) => {
    let pattern = null;
    let message = '';

    if (typeof rule.pattern === 'number') {
      pattern = rule.pattern;
    } else {
      pattern = Number(rule.pattern?.value);
      message = rule.pattern?.message ?? '';
    }

    if (pattern >= 0) {
      switch (pattern) {
        case ValidatePattern.NUMBER:
          return {
            hasError: !regex.validateNumber(value.toString()),
            message: message ? message : 'กรุณาระบุ 0-9 เท่านั้น',
          };
        case ValidatePattern.EMAIL:
          return {
            hasError: value ? !regex.validateEmail(value.toString()) : false,
            message: message ? message : 'ผิดรูปแบบอีเมล',
          };
        case ValidatePattern.ENGLISH:
          return {
            hasError: regex.validateEnglish(value.toString()),
            message: message ? message : 'กรุณาระบุ a-z, A-Z, 0-9 เท่านั้น',
          };
      }
    }

    return { hasError: false };
  };

  const validateMatching = (
    value: string | number | boolean | Date,
    rule: ValidateType,
    dataStore: any,
  ) => {
    let matching = '';
    let message = 'ข้อมูลไม่ตรงกัน';

    if (typeof rule.matching === 'string') {
      matching = rule.matching;
    } else {
      matching = rule.matching?.value ? rule.matching?.value.toString() : '';
      message = rule.matching?.message ?? message;
    }

    if (matching && dataStore && dataStore[matching] !== value) {
      return {
        hasError: true,
        message: message,
      };
    }

    return { hasError: false };
  };

  return {
    process,
  };
}

function isNotvalidInput(value: string | number | boolean | Date | null | undefined): boolean {
  // Check if value is null or undefined
  if (value === null || value === undefined) {
      return true;
  }

  // For string type, trim whitespace and check if empty
  if (typeof value === 'string' && value.trim() === '') {
      return true;
  }

  return false;
}