import { Table as TableBT } from 'react-bootstrap';

interface Props {
  children: JSX.Element | JSX.Element[];
}

export default function Table(props: Props) {
  return (
    <TableBT
      className='custom-table'
      striped
      hover
      responsive
      variant='tertiary'>
      {props.children}
    </TableBT>
  );
}