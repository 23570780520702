import { Image, Row, Col } from "react-bootstrap";
import { IAssociationTournamentResponse, IRegisterAssociationTournamentRequest, RegisterTournament, User } from 'models';
import { Input, Form, DatePicker, Button } from "components";
import { useTranslation } from "react-i18next";
import { firebase, tournament } from "services";
import { HttpStatusCode, TournamentType } from "utils";
import toast from "utils/toast";
import { Rules } from "hooks";
import { useCallback, useEffect, useState } from "react";
import tournamentService from "services/associationTournament";

interface Props {
  tournamentData: IAssociationTournamentResponse;
  userData: User;
  onHide: () => void;
  onHideAfterSave: () => void;
}

export default function Single(props: Props) {
  const [user, setUser] = useState<User>({} as User);
  const [img, setImg] = useState("");
  const [userGameName, setUserGameName] = useState('');
  const { t } = useTranslation(['modal']);
  const [formRules] = useState<Rules>({
    gameName: { required: true },
  });

  useEffect(() => {
    if (props.userData) {
      setUser(props.userData);
    }
  }, [props.userData]);

  const handleOnSubmit = async () => {
    if (!user.idCardNo) {
      toast.error(t('modal:competition:alert:checkIdPassport'));

      return;
    }

    await registerTournamentAsync();
  };

  const registerTournamentAsync = async () => {
    const data: IRegisterAssociationTournamentRequest = {
      userId: user.id,
      gameName: userGameName,
      createdBy: user.firstName,
    };

    const { status } = await tournamentService.registerTournamentAsync(props.tournamentData.id, data);

    if (status === HttpStatusCode.CONFLICT) {
      return toast.error('ผู้เล่นลงสมัครแข่งขันแล้ว');
    }

    if (status === HttpStatusCode.CREATED || status === HttpStatusCode.OK) {
      props.onHideAfterSave();

      return toast.success(t('modal:competition:alert:successRegister'));
    }

    return toast.error('เกิดข้อผิดพลาด ขณะสมัคร');
  };

  const getImageAsync = useCallback(async (img: string) => {
    if (img) {
      const imgUrl = await firebase.getImageAsync(img);

      setImg(imgUrl);
    }
  }, []);

  useEffect(() => {
    if (props.userData.img) {
      getImageAsync(props.userData.img);
    }
  }, [props.userData.img]);

  return (
    <>
      <div>
        <Image src="/images/single.png" className="me-2" /> <span>{t('modal:competition:singleType')}</span>
      </div>
      <div>
        <div className="text-center">
          <h4 className="fw-bold">{t('modal:competition:playerProfile')}</h4>
          <h5>{t('modal:club:subDetail')}</h5>
          <p className="text-primary mt-5">{t('modal:competition:condition')}</p>
          <p className="text-primary">{t('modal:competition:alertCondition')}</p>
        </div>
        <Form onSubmit={handleOnSubmit} rules={formRules}>
          <div className="custom-file-input mb-3">
            <div className="image">
              {user?.img ?
                <div className='profile cursor-pointer'>
                  <Image alt='profile' src={img} />
                </div>
                :
                <div className='no-image cursor-pointer'>
                </div>
              }
            </div>
          </div>
          <Row>
            <Col md={6} lg={6}>
              <Input disabled={true} value={user?.email} label={t('modal:profile:email')} name='email-single' placeholder={t('email')} />
            </Col>
            <Col md={6} lg={6}>
              <Input disabled={true} value={user?.firstName} label={t('modal:profile:firstName')} name='firstName-single' placeholder={t('firstName')} />
            </Col>
            <Col md={6} lg={6}>
              <DatePicker disabled={true} label={t('modal:profile:birthday')} name='birthday-single' value={user?.birthday} />
            </Col>
            <Col md={6} lg={6}>
              <Input
                value={userGameName}
                label={t('modal:competition:competitionName')}
                name='gameName'
                placeholder={t('modal:competition:competitionName')}
                onChange={(value) => setUserGameName(value)} />
            </Col>
            <Col md={6} lg={6}>
              <Input disabled={true} label={t('modal:competition:tel')} name='tel-single' value={user?.tel} />
            </Col>
            <Col>
              <Input disabled={true} label={t('modal:competition:idCardPassport')} name="idCardNo-single" value={user?.idCardNo} />
            </Col>
          </Row>
          <div className="d-flex justify-content-end mt-5">
            <Button type="submit">
              {t('modal:register:verifyOtp:confirm')}
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
}