import { Column } from "./Column";

interface Props {
  children: JSX.Element | JSX.Element[];
}

function Header(props: Props) {
  return (
    <thead>
      {props.children}
    </thead>
  );
}

export { Header };