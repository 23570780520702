import { BannerSwiper, Button, Card, ContentCard } from 'components';
import { useTranslation } from 'react-i18next';
import { Row, Col, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Banner } from 'models/banner';
import { useCallback, useEffect, useState } from 'react';
import banner from 'services/banner';
import { EventName } from 'utils/constants/enum';
import { IAssociationTournamentPaginationResponse } from 'models';
import { associationTournament } from 'services';

export function Home() {
  const [tournamentData, setTournamentData] = useState<IAssociationTournamentPaginationResponse[]>([]);
  const [bannerdata, setBannerData] = useState<Banner[]>([]);
  const { t } = useTranslation('home');
  const navigate = useNavigate();

  const getTournamentAsync = useCallback(async () => {
    const { data, status } = await associationTournament.getAssociationTournamentAsync(1, 12);
    
    setTournamentData(data.data);
  }, []);

  const getBannerAsync = useCallback(async () => {
    const { data, status } = await banner.getBannerAsync();
    
    setBannerData(data);
  }, []);

  useEffect(() => {
    getTournamentAsync();
    getBannerAsync();
  }, [getTournamentAsync, getBannerAsync]);

  return (
    <>
      <div className='mb-5'>
        <BannerSwiper bannerData={bannerdata} />
      </div>
      <div className='mb-5'>
        <Row>
          <Col>
            <CardMenu
              onClick={() => navigate('/player')}
              firstText='สมาชิกอีสปอร์ต'
              secondText='ESports Player'
              img='/images/player.png' />
          </Col>
          <Col>
            <CardMenu
              onClick={() => navigate('/club')}
              firstText='สโมสร'
              secondText='Club'
              img='/images/club.png' />
          </Col>
        </Row>
      </div>
      <div className='mb-5'>
        <Row>
          <Col className='text-start'>
            <h3>{t('competition')}</h3>
          </Col>
          <Col className='text-end'>
            <Button
              className='view-all'
              variant='link'
              onClick={() => navigate('/event-all', { state: { eventName: EventName.TOURNAMENT } })}>
              <h5>
                {t('seeMore')}
              </h5>
            </Button>
          </Col>
        </Row>
        <Row>
          {tournamentData.map(data =>
            <Col
              sm={6}
              md={4}
              lg={3}
              key={data.id}
              className='mb-5'>
              <ContentCard
                eventName={EventName.TOURNAMENT}
                data={data}
                textBagde={t('competition')} />
            </Col>)}
        </Row>
      </div>
    </>
  );
}

function CardMenu({
  onClick,
  firstText,
  secondText,
  img
}: {
  onClick: () => void,
  firstText: string,
  secondText: string,
  img: string,
}) {
  const hostUrl = `${window.location.protocol}//${window.location.host}`;

  return (
    <Card className='card-menu py-3' onClick={() => onClick()}>
      <Row className='mb-3'>
        <Col className='d-flex justify-content-center'>
          <Image src={`${hostUrl}${img}`} />
        </Col>
      </Row>
      <Row className='mb-2'>
        <Col>
          <h4 className='text-center'>{firstText}</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5 className='text-center'>{secondText}</h5>
        </Col>
      </Row>
    </Card>
  );
}