interface Props {
  position?: 'left' | 'center' | 'right';
  width?: 'fit' | 'large';
}

export function Line({ position = 'center', width = 'fit' }: Props) {
  const getPosition = () => {
    if (position === 'left') {
      return 'left';
    } else if (position === 'right') {
      return 'right';
    } else {
      return '';
    }
  };

  const getSize = () => {
    if (width === 'large') {
      return 'width-large';
    }

    return 'width-fit';
  };

  return (<div className={`line my-2 ${getPosition()} ${getSize()}`} />);
}