import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useBackground } from '../../../hooks/useBackground';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { Card } from '../../../components/Card';
import { BackgroundImage } from '../../../components/BackgroundImage';
import { useAppSelector } from '../../../hooks/redux';
import { ContentType } from 'models/contentType';
import ReactPlayer from 'react-player'
import TextLogo from '../../../components/Background/TextLogo';

export function ContentStreaming() {
  const hostUrl = `${window.location.protocol}//${window.location.host}`;
  const background = useBackground();

  const data: ContentType = useAppSelector((state) =>
    state.commonStore.tournamentDetail);

  const BREADCRUMB = [
    { label: 'หน้าแรก', path: '/home' },
    { label: `${data.title}`, path: '/demo' },
  ];

  useEffect(() => {
    background.setImage(`${hostUrl}/images/rov-1440x439.png`);
  }, []);

  return (
    <>
      <BackgroundImage src={`${hostUrl}/images/rov-1440x439.png`} />
      <Breadcrumb className='my-5' menus={BREADCRUMB} />
      <Row>
        <TextLogo text='PUBG' />
        <Col>
          <Card className='mb-5'>
            <ReactPlayer className='w-100 content-streaming' url={data.videoURL} />
          </Card>
        </Col>
      </Row>
    </>
  );
}