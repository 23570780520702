import { Table as TableBT } from "react-bootstrap";
import { Pagination } from "components";
import { Header } from "./Header";
import { Body } from "./Body";
import { Children } from "react";
import { Row } from "./Row";
import { Column } from "./Column";
import { Cell } from "./Cell";

interface Props {
  children: JSX.Element | JSX.Element[];
  total?: number;
  onChange?: (page: number) => void;
  className?: string;
}

function NewTable(props: Props) {
  const handlerPaginationOnChange = (page: number) => {
    if (props.onChange) {
      props.onChange(page);
    }
  };

  return (
    <>
      <div className={`w-100 overflow-auto ${props.className}`}>
        <TableBT striped borderless className="mb-0">
          {Children.map(props.children, (child) => child.type.name === 'Header' || child.type.name === 'Body' ? child : null)}
        </TableBT>
      </div>
      {props.total ? <Pagination totalItems={props.total} onChange={handlerPaginationOnChange} /> : null}
    </>
  );
}

NewTable.Header = Header;
NewTable.Body = Body;
NewTable.Row = Row;
NewTable.Column = Column;
NewTable.Cell = Cell;

export { NewTable };