const validateNumber = (value: string) => {
  return /^[0-9]+$/.test(value);
}

const validateEmail = (value: string) => {
  return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(value);
}

const validateEnglish = (value: string) => {
  return /[^a-zA-Z0-9]/g.test(value);
}

export default {
  validateNumber,
  validateEmail,
  validateEnglish,
}