export const enum EventName {
  TOURNAMENT = 'TOURNAMENTS',
  STREAMING = 'STREAMING',
  NEWS = 'NEWS',
}

export const enum Session {
  ACCEPT_COOKIES = 'accept_cookies',
}

export const enum SessionTimeOut {
  TWENTY_MINUTE = 1200000,
  ONE_YEAR = 31556952000,
}