import { Image, Row, Col } from 'react-bootstrap';
import { Input, Upload, Button, Form, Modal, Table } from 'components';
import { UploadImageType } from 'utils/constants/uploadImageType';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { club } from 'services';
import { HttpStatusCode, ModalSize, ValidatePattern, set } from 'utils';
import { Rules, useAppDispatch } from 'hooks';
import toast from 'utils/toast';
import { ClubMember, TeamManagement, TeamPlayer } from '../../../models';


interface Props {
  onHide: () => void;
  clubId: string;
  clubMember: ClubMember[];
}

interface PlayerTeamManageProps {
  backStep: () => void;
  onHide: () => void;
  clubId: string;
  teamData: TeamManagement;
  clubMember: ClubMember[];
}

interface TeamManageProps {
  nextStep: () => void;
  sendTeamData: (teamData: TeamManagement) => void;
}

export default function Team(props: Props) {
  const [ step, setStep ] = useState(1);
  const [ teamData, setTeamData ] = useState<TeamManagement>({} as TeamManagement);
  const {t} = useTranslation([ 'modal' ]);
  return (
    <>
      <div>
        <Image src='/images/team.png'
          className='me-2' />
        <span>{t('modal:competition:teamType')}</span>
      </div>
      {step === 1
        ? <TeamManage nextStep={() => setStep(2)}
            sendTeamData={(data) => setTeamData(data)} />
        : <PlayerTeamManage
            clubId={props.clubId}
            backStep={() => setStep(1)}
            clubMember={props.clubMember}
            teamData={teamData}
            onHide={() => props.onHide()} />
      }
    </>
  );
}

const TeamManage = (props: TeamManageProps) => {
  const [ logoImage, setLogoImage ] = useState('');
  const [ teamName, setTeamName ] = useState('');
  const [ teamTag, setTeamTag ] = useState('');
  const [ Phone, setPhone ] = useState('');
  const [ lineId, setLineId ] = useState('');

  const dispatch = useAppDispatch();

  const [ formRules ] = useState<Rules>({
    teamName: {required: true},
    Phone: {required: true, pattern: ValidatePattern.NUMBER},
    ownerLine: {required: true},
    img: {required: true},
  });
  const {t} = useTranslation([ 'modal' ]);

  const handleOnSubmit = useCallback(() => {
    dispatch(set({key: 'teamName', data: teamName}));
    dispatch(set({key: 'teamTag', data: teamTag}));
    dispatch(set({key: 'Phone', data: Phone}));
    dispatch(set({key: 'lineId', data: lineId}));
    dispatch(set({key: 'logoImage', data: logoImage}));

    const teamData: TeamManagement = {
      teamName: teamName,
      teamTag: teamTag,
      phone: Phone,
      lineId: lineId,
      logoImage: logoImage,
    };
    props.sendTeamData(teamData);
    props.nextStep();
  }, [ teamName, teamTag, Phone, lineId, logoImage ]);

  return (
    <div>
      <div className='text-center'>
        <h4 className='fw-bold'>{t('modal:competition:playerProfile')}</h4>
        <h5>{t('modal:club:subDetail')}</h5>
        <p className='text-primary mt-5'>{t('modal:competition:condition')}</p>
        <p className='text-primary'>{t('modal:competition:alertCondition')}</p>
      </div>
      <Form onSubmit={handleOnSubmit}
        rules={formRules}>
        <Upload name='img'
          label={t('modal:competition:teamLogo')}
          type={UploadImageType.IMAGE_TOURNAMENT}
          value={logoImage}
          getImage={(data) => setLogoImage(data)} />
        <Row>
          <Col md={6}
            lg={6}>
            <Input value={teamName}
              label={t('modal:competition:teamName')}
              name='teamName'
              placeholder={t('modal:competition:teamName')}
              onChange={(value) => setTeamName(value)} />
          </Col>
          <Col md={6}
            lg={6}>
            <Input value={teamTag}
              label={t('modal:competition:teamTag')}
              name='signName'
              placeholder={t('modal:competition:teamTag')}
              onChange={(value) => setTeamTag(value)} />
          </Col>
          <Col md={6}
            lg={6}>
            <Input value={Phone}
              label={t('modal:competition:telOwnerTeam')}
              name='Phone'
              placeholder='xxx-xxxxxxx'
              onChange={(value) => setPhone(value)} />
          </Col>
          <Col md={6}
            lg={6}>
            <Input value={lineId}
              label={t('modal:competition:lineOwnerTeam')}
              name='ownerLine'
              placeholder='Line ID'
              onChange={(value) => setLineId(value)} />
          </Col>
        </Row>
        <div className='d-flex justify-content-end mt-5'>
          <Button type='submit'>
            {t('modal:register:verifyOtp:next')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

const PlayerTeamManage = (props: PlayerTeamManageProps) => {
  const [ selectedPlayerList, setSelectedPlayerList ] = useState<TeamPlayer[]>([]);
  const [ show, setShow ] = useState(false);

  const handleOnSubmit = useCallback(async () => {
    // await registerTournament();
    const { status } = await club.crateTeamClubMemberAsync(props.clubId, selectedPlayerList, props.teamData);

    if (status == HttpStatusCode.CREATED) {
      toast.success('เพิ่มทีมสำเร็จ');
      props.onHide();

      return;
    }
  }, [props, selectedPlayerList]);

  const onClose = () => {
    setShow(false);
  };

  const onOpen = () => {
    setShow(true);
  };

  const onSelectPlayer = useCallback((data: ClubMember) => {

    const isExist = selectedPlayerList.some(f => f.email === data.user.email);

    if (isExist) {
      toast.error("มีข้อมูลผู้เล่นคนนี้แล้ว กรุณาเลือกคนใหม่");

      return;
    }

    const newPlayer: TeamPlayer = {
      gameName: data.user.firstName,
      email: data.user.email,
    };

    setSelectedPlayerList(oldArray => [ ...oldArray, newPlayer ]);
    onClose();
  }, [selectedPlayerList]);

  return (
    <>
      <div>
        <div className='d-flex justify-content-between mb-3'>
          <h4 className='m-0 d-flex'>ทีมในสโมสร</h4>
          <Button className='me-2'
            variant='primary'
            onClick={() => onOpen()}>
            เพิ่มผู้เล่นทีม
          </Button>
        </div>
        <Table>
          <thead>
          <tr className='text-center'>
            <th>ชื่อในเกมส์</th>
            <th>Email</th>
          </tr>
          </thead>
          <tbody>
          {
            selectedPlayerList.map((data, index) =>
            <tr key={index}>
              <td width={200} className='ms-3'>{data.gameName}</td>
              <td width={300}>{data.email}</td>
            </tr>)
          }
          </tbody>
        </Table>
        <Button variant='primary'
          onClick={() => handleOnSubmit()}>
          บันทึกข้อมูล
        </Button>
      </div>

      <Modal show={show}
        size={ModalSize.LG}
        closeButton
        onClose={() => onClose()}>
        <Modal.Body>
          <Table>
            <thead>
              <tr className='text-center'>
                <th>ชื่อในเกมส์</th>
                <th>Email</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                props.clubMember.map((player, index) =>
                <tr key={index}>
                  <td width={200} className='ms-3'>{`${player.user.firstName} ${player.user.middleName ?? ''} ${player.user.lastName ?? ''}`}</td>
                  <td width={300}>{player.user.email}</td>
                  <td width={200} className='text-center'>
                    <Button variant='primary'
                      onClick={() => onSelectPlayer(player)}>
                      เพิ่มข้อมูล
                    </Button>
                  </td>
                </tr>)
              }
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
};