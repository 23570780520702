import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import { PiListBullets, PiGameControllerDuotone, PiUsersDuotone } from "react-icons/pi";
import { useMemo } from 'react';

interface Props {
  ICon: string;
  IOnClick: () => void;
  IEndDateRegis: string;
  hideButtonRegister: boolean;
}

export function FooterEventLayout({ ICon, IOnClick, IEndDateRegis }: Props) {
  const visibleRegister = useMemo(() => {
    const registerDate = new Date(IEndDateRegis);
    const dateNow = new Date();
    return dateNow > registerDate;
  }, [IEndDateRegis]);

  const pathname = useLocation().pathname;

  const IEventId = pathname.split('/')[2];
  return (
    <div className='footer'>
      <Container className='py-2 menu'>
        {
          ICon ? <div className='logo'><img src={ICon} alt={ICon} className='icon' /></div> : <h2><span className='text-primary'>E</span>sports</h2>
        }
        <Link
          className={`${pathname === `/event/${IEventId}` ? 'isActive' : ''} text-light text-decoration-none d-flex flex-column align-items-center`}
          to={`/event/${IEventId}`}>
          <PiListBullets className='fs-1 icon d-block d-md-none' />
          <p className='mb-0 d-none d-md-block'>รายละเอียด</p>
        </Link>
        <Link
          className={`${pathname === `/event/${IEventId}/candidates` ? 'isActive' : ''} text-light text-decoration-none d-flex flex-column align-items-center`}
          to={`/event/${IEventId}/candidates`}>
          <PiUsersDuotone className='fs-1 icon d-block d-md-none' />
          <p className='mb-0 d-none d-md-block'>ผู้เข้าแข่งขัน</p>
        </Link>
        <Link
          className='text-light text-decoration-none d-none'
          to={`#`}>
          <PiUsersDuotone className='fs-1 icon d-block d-md-none' />
          <p className='mb-0 d-none d-md-block'>สายการแข่งขัน</p>
        </Link>
        <Link
          className={`${pathname === `/event/${IEventId}/Tournament` ? 'isActive' : ''} text-light text-decoration-none d-flex flex-column align-items-center`}
          to={`/event/${IEventId}/Tournament`}>
          <PiGameControllerDuotone className='fs-1 icon d-block d-md-none' />
          <p className='mb-0 d-none d-md-block'>แมตช์การแข่งขัน</p>
        </Link>
        {
          !visibleRegister ?
          <Button
            variant='primary'
            onClick={() => IOnClick()}>
            สมัครเข้าแข่งขัน
          </Button> : null
        }
      </Container>
    </div>
  );
}