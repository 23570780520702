import { Outlet } from 'react-router-dom';
import { Navbar, Footer, CookieConsent } from 'components';
import { Container } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import './i18n';
import { ClubDetailModal } from 'pages';

export default function App() {
  return (
    <>
      <Navbar />
      <Container fluid='xl'>
        <Outlet />
      </Container>
      <CookieConsent />
      <Footer />
      <ToastContainer />
      <ClubDetailModal />
    </>
  );
}