import { DatePicker, Form, Input, Modal, Select, TextHeader } from "components";
import { useAppSelector } from "hooks";
import { Game, RefereeUserHistory } from "models";
import { useCallback } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { account } from "services";
import { HttpStatusCode, ModalSize, convertDateToDateTimeOffset } from "utils";
import toast from "utils/toast";

export function AddRefereeUserHistoryModal(props: { show: boolean, onClose: () => void }) {
  const formRule = {
    tournamentStartDate: { required: true },
    tournamentEndDate: { required: true },
    tournamentTitle: { required: true },
    tournamentLocation: { required: true },
    gameId: { required: true },
  };

  const { games } = useAppSelector((state) => ({
    games: state.commonStore.games as Game[],
  }));

  const getGamesPlayedMaster = useCallback(() => {
    const gameList = games?.map(d => ({ label: d.fullName, value: d.id }));

    return gameList;
  }, [games]);

  const submitHandler = async (data: RefereeUserHistory) => {
    const convertStartDate = convertDateToDateTimeOffset(data.tournamentStartDate.toString());
    const convertEndDate = convertDateToDateTimeOffset(data.tournamentEndDate.toString());

    if (convertStartDate > convertEndDate) {
      return toast.warn("วันที่การแข่งขันเริ่มต้นมากกว่าวันที่การแข่งขันสิ้นสุด");
    }

    const noOfCompetitors = data.noOfCompetitors?.toString();
    const body = data;
    body.tournamentStartDate = convertStartDate;
    body.tournamentEndDate = convertEndDate;
    body.noOfCompetitors = noOfCompetitors ? parseInt(noOfCompetitors) : undefined;

    const { status } = await account.createRefereeUserHistoryAsync(body);

    if (status === HttpStatusCode.CREATED) {
      toast.success('บันทึกข้อมูลสำเร็จ');

      props.onClose();
    }
  };

  return (
    <Modal show={props.show} size={ModalSize.LG} closeButton onClose={() => props.onClose()}>
      <Form onSubmit={submitHandler} rules={formRule}>
        <Modal.Body>
          <div className='my-4'>
            <TextHeader
              text='เพิ่มประวัติการตัดสิน'
              position='center'
              size='sm'
              underline='center'
              width='large' />
          </div>
          <Row>
            <Col xs={12}>
              <Select label='เกมที่ตัดสิน' name='gameId' items={getGamesPlayedMaster()} />
            </Col>
            <Col lg={6}>
              <DatePicker label='วันที่เรื่มการแข่งขัน' name='tournamentStartDate' />
            </Col>
            <Col lg={6}>
              <DatePicker label='วันที่สิ้นสุดการแข่งขัน' name='tournamentEndDate' />
            </Col>
            <Col xs={12}>
              <Input label='ชื่อการแข่งขัน' name='tournamentTitle' />
            </Col>
            <Col xs={12}>
              <Input label='สถานที่การแข่งขัน' name='tournamentLocation' />
            </Col>
            <Col xs={12}>
              <Input label='ประเภทการแข่งขัน' name='tournamentType' />
            </Col>
            <Col xs={12}>
              <Input label='จำนวนทีม' name='noOfCompetitors' type="number" />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className='justify-content-between'>
          <Button variant='outline-light' onClick={() => props.onClose()}>ยกเลิก</Button>
          <Button type='submit'>บันทึก</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}