import { useAppSelector } from 'hooks';
import { Button, Row, Col } from 'react-bootstrap';
import { Input, Form, TextHeader, Select, Modal } from 'components';
import { useCallback } from 'react';
import { Game, GamePlayed } from 'models';
import { game } from 'services';
import { HttpStatusCode, ModalSize } from 'utils';
import toast from 'utils/toast';

interface Props {
  show: boolean;
  onClose: () => void;
}

export default function AddGameModal(props: Props) {
  const games = useAppSelector((state) => state.commonStore.games as Game[]);
  const formRule = {
    gameId: { required: true },
    mainPosition: { required: true },
    mainHero: { required: true },
    latestRanking: { required: true },
    startingYear: { required: true, maxLength: 4 },
    endingYear: { required: true, maxLength: 4 },
  };

  const submitHandler = useCallback(async (data: GamePlayed) => {
    const response = await game.addGamePlayedAsync(data);

    if (response.status === HttpStatusCode.CREATED) {
      toast.success('บันทึกข้อมูลสำเร็จ');

      props.onClose();
    }
  }, []);

  const getGamesMaster = useCallback(() =>
    games?.map(game => ({ label: game.shortName, value: game.id })), [games]);

  return (
    <Modal show={props.show} size={ModalSize.LG} closeButton onClose={() => props.onClose()}>
      <Form onSubmit={submitHandler} rules={formRule}>
        <Modal.Body>
          <div className='my-4'>
            <TextHeader
              text='เพิ่มเกมที่เล่น'
              position='center'
              size='sm'
              underline='center'
              width='large' />
          </div>
          <Row>
            <Col lg={6}>
              <Select label='เกมที่เล่น' name='gameId' items={getGamesMaster()} />
            </Col>
            <Col lg={6}>
              <Input label='ตำแหน่งหลัก' name='mainPosition' />
            </Col>
            <Col lg={6}>
              <Input label='ฮีโร่หลัก' name='mainHero' />
            </Col>
            <Col lg={6}>
              <Input label='อันดับล่าสุด/คะแนนล่าสุด' name='latestRanking' />
            </Col>
            <Col lg={6}>
              <Input label='ปีที่เริ่ม' name='startingYear' type='number' />
            </Col>
            <Col lg={6}>
              <Input label='ปีที่สิ้นสุด' name='endingYear' type='number' />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className='justify-content-between'>
          <Button variant='outline-light' onClick={() => props.onClose()}>ยกเลิก</Button>
          <Button type='submit'>บันทึก</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}