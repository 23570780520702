interface Props {
  children?: string;
  minWidth?: number;
}

export function Column(props: Props) {
  return (
    <th className="text-center" style={{ minWidth: props.minWidth }}>
        {props.children}
    </th>
  );
}