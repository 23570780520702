import { ContentCard, Pagination } from 'components';
import { ContentType } from 'models/contentType';
import { useCallback, useEffect, useState } from 'react';
import tournament from 'services/tournament';
import streaming from 'services/streaming';
import { useLocation } from "react-router-dom";
import { EventName } from 'utils/constants/enum';
import { Row, Col, Button } from 'react-bootstrap';
import { Breadcrumb } from 'components/Breadcrumb';
import IconSort from 'assets/icons/IconSort';
import { useTranslation } from 'react-i18next';
import { IAssociationTournamentPaginationResponse } from 'models';
import { associationTournament } from 'services';

export function EventAll() {
  const { t } = useTranslation(['swiper', 'home']);
  const [sortName, setSortName] = useState<string>('ASC');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalRows, setTotalRows] = useState(1);
  const [size, setSize] = useState<number>(12);
  const [tournamentData, setTournamentData] = useState<IAssociationTournamentPaginationResponse[]>([]);

  const BREADCRUMB = [
    { label: 'หน้าแรก', path: '/home' },
    { label: t('contentSwiper.tournamentsAllTitle') as string, path: '' }
  ];

  const handleChangeSort = () => {
    const sort = sortName === 'ASC'
      ? 'DESC'
      : 'ASC';
  };

  const paginationOnChange = (value: number) => {
    value;
  };

  const getTournamentAsync = useCallback(async () => {
    const { data, status } = await associationTournament.getAssociationTournamentAsync(1, 12);
    setTournamentData(data.data);
  }, []);

  useEffect(() => {
    getTournamentAsync();
  }, [getTournamentAsync]);

  return (
    <div className='mb-5'>
      <Breadcrumb className='my-5' menus={BREADCRUMB} />
      <Row className='align-items-center head-table py-3'>
        <Col cols={6}>
          <p className='fs-3 mb-0 text-start'>
            {t('contentSwiper.tournamentsAllTitle')}
          </p>
        </Col>
        <Col className='d-flex align-items-center justify-content-end'>
          <Button variant='outline-light' onClick={handleChangeSort}>
            <h5>
              <IconSort />
              <span className='ms-2'>{t('contentSwiper.latest')}</span>
            </h5>
          </Button>
        </Col>
      </Row>
      <Row className='my-5'>
        {tournamentData.map(data =>
          <Col
            sm={6}
            md={4}
            lg={3}
            key={data.id}
            className='mb-5'>
            <ContentCard
              eventName={EventName.TOURNAMENT}
              data={data}
              textBagde={t('competition')} />
          </Col>)}
        <Col xs={12}>
          <div className='d-flex justify-content-center'>
            <Pagination totalItems={totalRows} totalItemsPerPage={size} onChange={paginationOnChange} />
          </div>
        </Col>
      </Row>
    </div>
  )
}