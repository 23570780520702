import { Image, Row, Col, Form as FormBT, Tabs, Tab } from 'react-bootstrap';
import { Input, Upload, Form, Button, Table, TableGame } from 'components';
import { UploadImageType } from 'utils/constants/uploadImageType';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import {
  AssociationMatchType,
  Club,
  ClubMember,
  IAssociationTournamentResponse,
  IRegisterAssociationTournamentRequest,
  IRegisterAssociationTournamentTeamRequest,
  TeamManagement,
  TeamManagementResponse,
  TeamTournamentPlayer,
  User
} from 'models';
import { club } from 'services';
import {
  HttpStatusCode,
  TournamentType,
  ValidatePattern,
  set,
  TournamentRegisterType
} from 'utils';
import { Rules, useAppDispatch, useAppSelector } from 'hooks';
import toast from 'utils/toast';
import { BsPlusLg } from 'react-icons/bs';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import tournamentService from 'services/associationTournament';

interface Props {
  onHide: () => void;
  onHideAfterSave: () => void;
  tournamentData: IAssociationTournamentResponse;
  userData: User;
  club: Club;
  teams: TeamManagementResponse[];
  gameName: string;
}

export default function Team(props: Props) {
  const [step, setStep] = useState(1);
  const [teamData, setTeamData] = useState<TeamManagement>({} as TeamManagement);
  const [teamSelect, setTeamSelect] = useState<TeamManagementResponse>();
  const [createTeamClub, setCreateTeamClub] = useState(false);
  const [haseClub, setHaseClub] = useState(false);
  const { t } = useTranslation(['modal']);

  const [defaultRender, setDefaultRender] = useState(TournamentRegisterType.PERSONAL_REGISTER);

  useEffect(() => {
    if (Object.keys(props.club).length !== 0) {
      setDefaultRender(TournamentRegisterType.CLUB_REGISTER);
      setHaseClub(true);
    }
  }, [props.club]);

  const renderByClub = () => {
    switch (step) {
      case 1:
        return <ListTeamManage
          onSubmit={setTeamSelect}
          nextStep={() => setStep(2)}
          createTeamClub={(value) => setCreateTeamClub(value)}
          tournamentData={props.tournamentData}
          teamData={props.teams}
          onHide={() => props.onHide()}
          userData={props.userData} />;
      case 2:
        return <TeamManage
          nextStep={() => setStep(3)}
          backStep={() => setStep(1)}
          hasBackStep={true}
          teamData={teamData}
          teamSelect={teamSelect}
          sendTeamData={(data) => setTeamData(data)} />;
      case 3:
        return <PlayerTeamManage
          backStep={() => setStep(2)}
          onHide={() => props.onHide()}
          onHideAfterSave={() => props.onHideAfterSave()}
          gameName={props.gameName}
          tournamentData={props.tournamentData}
          clubData={props.club}
          clubMembers={teamSelect?.clubMembers ? teamSelect?.clubMembers : props.club.clubMembers}
          createTeamClub={createTeamClub}
          teamData={teamData}
          userData={props.userData} />;
    }
  };

  const renderByPersonal = () => {
    switch (step) {
      case 1:
        return <TeamManage
          nextStep={() => setStep(3)}
          backStep={() => setStep(1)}
          hasBackStep={false}
          teamData={teamData}
          sendTeamData={(data) => setTeamData(data)} />;
      case 3:
        return <ManualPlayerTeamManage
          backStep={() => setStep(2)}
          onHide={() => props.onHide()}
          onHideAfterSave={() => props.onHideAfterSave()}
          gameName={props.gameName}
          tournamentData={props.tournamentData}
          clubData={props.club}
          clubMembers={teamSelect?.clubMembers ? teamSelect?.clubMembers : props.club.clubMembers}
          createTeamClub={createTeamClub}
          teamData={teamData}
          userData={props.userData} />;
    }
  };

  const onSelectTab = (key: string | null) => {
    setDefaultRender(key ?? TournamentRegisterType.PERSONAL_REGISTER);
    setStep(1);
  };

  return (
    <>
      <div>
        {
          props.tournamentData.associationMatchType === TournamentType.SINGLE ?
            <>
              <Image src="/images/single.png" className="me-2" />
              <span>{t('modal:competition:singleType')}</span>
            </>
            :
            <>
              <Image src='/images/team.png' className='me-2' />
              <span>{t('modal:competition:teamType')}</span>
            </>
        }
      </div>

      <Tabs
        activeKey={defaultRender}
        id='tourment-register-tab'
        className='mb-3 mt-3 custom-tab'
        onSelect={onSelectTab}
        justify>
        {/* <Tab eventKey={TournamentRegisterType.PERSONAL_REGISTER} title={<p>สมัครในนามตัวเอง</p>} className='current'>
          {renderByPersonal()}
        </Tab> */}
        {
          haseClub ?
            <Tab eventKey={TournamentRegisterType.CLUB_REGISTER} title={<p>สมัครในนามสโมสร</p>} className='current'>
              {renderByClub()}
            </Tab> :
            null
        }
      </Tabs>
    </>
  );
}

interface ListTeamManageProps {
  nextStep: () => void;
  onSubmit: (value?: TeamManagementResponse) => void;
  onHide: () => void;
  teamData: TeamManagementResponse[];
  userData: User;
  createTeamClub: (value: boolean) => void;
  tournamentData: IAssociationTournamentResponse;
}

const ListTeamManage = (props: ListTeamManageProps) => {
  const [onSelectTeam, setOnSelectTeam] = useState<TeamManagementResponse>();
  const { t } = useTranslation(['modal']);

  const handleOnSubmit = () => {
    if (!onSelectTeam) {
      toast.warn('กรุณาเลือกทีมที่ต้องการ')
      return;
    }

    props.nextStep();
    props.onSubmit(onSelectTeam);
  }

  const conditionPassed = (member: number) => {
    const tournament = props.tournamentData;
    const isSingle = tournament.associationMatchType === AssociationMatchType.Single;
    const summaryPlayer = tournament.players;

    if (isSingle) {
      return member >= 1;
    }

    return member >= summaryPlayer;
  }

  const renderByCondition = useCallback((data: TeamManagementResponse, index: number) => {
    if (!conditionPassed(data.clubMembers.length)) {
      return (
        <tr key={index}>
          <td>
            <FormBT.Check disabled
              type='radio'
              name='team'
              checked={onSelectTeam?.id === data.id}
              readOnly />
          </td>
          <td width={200} className='text-center'>
            <Image className='img-register-tournament'
              src={data.logoImage}
              alt='logo'
              rounded />
          </td>
          <td width={300}>
            {data.name}
          </td>
          <td width={300}
            className='text-center'>{data.clubMembers.length}
          </td>
          <td width={300}>{data.tag}</td>
          <td width={300}
            className='text-center'>
            <AiOutlineClose className='text-danger' />
          </td>
        </tr>
      );
    }

    return (
      <tr key={index} onClick={() => setOnSelectTeam(data)}>
        <td>
          <FormBT.Check
            type='radio'
            name='team'
            checked={onSelectTeam?.id === data.id}
            readOnly />
        </td>
        <td width={200} className='text-center'>
          <Image className='img-register-tournament'
            src={data.logoImage}
            alt='logo'
            rounded />
        </td>
        <td width={300}>
          {data.name}
        </td>
        <td width={300}
          className='text-center'>{data.clubMembers.length}
        </td>
        <td width={300}>{data.tag}</td>
        <td width={300}
          className='text-center'>
          <AiOutlineCheck className='text-success' />
        </td>
      </tr>
    );
  }, [onSelectTeam]);

  const nextStep = () => {
    props.nextStep();
    props.createTeamClub(true);
  }

  return (
    <div>
      <div className='text-center'>
        <h4 className='fw-bold'>{t('modal:competition:playerProfile')}</h4>
        <h5>{t('modal:club:subDetail')}</h5>
        <p className='text-primary mt-5'>{t('modal:competition:condition')}</p>
        <p className='text-primary'>{t('modal:competition:alertCondition')}</p>
      </div>
      <TableGame>
        <thead >
          <tr className='text-center'>
            <th className='bg-transparent'></th>
            <th className='bg-transparent'>LOGO</th>
            <th className='bg-transparent'>TEAM NAME</th>
            <th className='bg-transparent'>MEMBER</th>
            <th className='bg-transparent'>NAME TAG</th>
            <th className='bg-transparent'>STATUS</th>
          </tr>
        </thead>
        <tbody>
          {
            props.teamData.map((data, index) => renderByCondition(data, index))
          }
        </tbody>
      </TableGame>
      <div className='d-flex justify-content-center mt-5'>
        <Button type='button'
          onClick={() => nextStep()}>
          <>
            <BsPlusLg className='me-2' />เพิ่มทีม
          </>
        </Button>
      </div>
      <div className='d-flex justify-content-end mt-5'>
        <Button type='button'
          onClick={() => handleOnSubmit()}>
          ต่อไป
        </Button>
      </div>
    </div>
  );
};

interface TeamManageProps {
  nextStep: () => void;
  backStep: () => void;
  hasBackStep: boolean;
  teamData?: TeamManagement;
  teamSelect?: TeamManagementResponse;
  sendTeamData: (teamData: TeamManagement) => void;
}

const TeamManage = (props: TeamManageProps) => {
  const [logoImage, setLogoImage] = useState('');
  const [teamName, setTeamName] = useState('');
  const [teamTag, setTeamTag] = useState('');
  const [phone, setPhone] = useState('');
  const [lineId, setLineId] = useState('');

  const [formRules] = useState<Rules>({
    teamName: { required: true },
    phone: { required: true, pattern: ValidatePattern.NUMBER },
    ownerLine: { required: true },
    img: { required: true },
  });

  const { t } = useTranslation(['modal']);
  const dispatch = useAppDispatch();
  const store = useAppSelector((state) => state.commonStore);
  const teamNameStore = store.teamName;
  const teamTagStore = store.teamTag;
  const telStore = store.tel;
  const lineIdStore = store.lineId;
  const logoImageStore = store.logoImage;

  const setDefaultValue = (teamSelect?: TeamManagementResponse, teamData?: TeamManagement) => {
    if (teamSelect) {
      setLogoImage(teamSelect.logoImage);
      setTeamName(teamSelect.name);
      setTeamTag(teamSelect.tag ?? '');
      setPhone(teamSelect.phone);
      setLineId(teamSelect.lineId);

      return;
    }

    if (teamData) {
      setLogoImage(teamData.logoImage);
      setTeamName(teamData.teamName);
      setTeamTag(teamData.teamTag ?? '');
      setPhone(teamData.phone);
      setLineId(teamData.lineId);

      return;
    }

    setLogoImage(logoImageStore);
    setTeamName(teamNameStore);
    setTeamTag(teamTagStore);
    setPhone(telStore);
    setLineId(lineIdStore);
  };

  useEffect(() => {
    setDefaultValue(props.teamSelect, props.teamData);
  }, [props.teamSelect, props.teamData]);

  const handleOnSubmit = (data: TeamManagement) => {
    dispatch(set({ key: 'teamName', data: teamName ? teamName : teamNameStore }));
    dispatch(set({ key: 'teamTag', data: teamTag ? teamTag : teamTagStore }));
    dispatch(set({ key: 'tel', data: phone ? phone : telStore }));
    dispatch(set({ key: 'lineId', data: lineId ? lineId : lineIdStore }));
    dispatch(set({ key: 'logoImage', data: logoImage ? logoImage : logoImageStore }));

    const newData: TeamManagement = {
      teamName: data.teamName,
      teamTag: data.teamTag,
      phone: data.phone,
      lineId: data.lineId,
      logoImage: data.logoImage,
    };

    props.sendTeamData(newData);

    props.nextStep();
  };

  return (
    <div>
      <div className='text-center'>
        <h4 className='fw-bold'>{t('modal:competition:playerProfile')}</h4>
        <h5>{t('modal:club:subDetail')}</h5>
        <p className='text-primary mt-5'>{t('modal:competition:condition')}</p>
        <p className='text-primary'>{t('modal:competition:alertCondition')}</p>
      </div>
      <Form onSubmit={handleOnSubmit}
        rules={formRules}>
        <Upload name='logoImage'
          label={t('modal:competition:teamLogo')}
          type={UploadImageType.IMAGE_TOURNAMENT}
          value={logoImage}
          getImage={(data) => setLogoImage(data)} />
        <Row>
          <Col md={6}
            lg={6}>
            <Input value={teamName}
              label={t('modal:competition:teamName')}
              name='teamName'
              placeholder={t('modal:competition:teamName')}
              onChange={(value) => setTeamName(value)} />
          </Col>
          <Col md={6}
            lg={6}>
            <Input value={teamTag}
              label={t('modal:competition:teamTag')}
              name='teamTag'
              placeholder={t('modal:competition:teamTag')}
              onChange={(value) => setTeamTag(value)} />
          </Col>
          <Col md={6}
            lg={6}>
            <Input value={phone}
              label={t('modal:competition:telOwnerTeam')}
              name='phone'
              placeholder='xxx-xxxxxxx'
              onChange={(value) => setPhone(value)} />
          </Col>
          <Col md={6}
            lg={6}>
            <Input value={lineId}
              label={t('modal:competition:lineOwnerTeam')}
              name='lineId'
              placeholder='Line ID'
              onChange={(value) => setLineId(value)} />
          </Col>
        </Row>
        <div className={`d-flex ${props.hasBackStep ? 'justify-content-between' : 'justify-content-end'} mt-5`}>
          {
            props.hasBackStep
              ?
              <Button type='button'
                variant='outline-primary'
                onClick={() => props.backStep()}>
                {t('modal:register:verifyOtp:back')}
              </Button>
              : null
          }
          <Button type='submit'>
            {t('modal:register:verifyOtp:next')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

interface PlayerTeamManageProps {
  backStep: () => void;
  onHide: () => void;
  onHideAfterSave: () => void;
  tournamentData: IAssociationTournamentResponse;
  teamData: TeamManagement;
  userData: User;
  clubData: Club;
  clubMembers: ClubMember[];
  createTeamClub: boolean;
  gameName: string;
}

const PlayerTeamManage = (props: PlayerTeamManageProps) => {
  const [playerList, setPlayerList] = useState<TeamTournamentPlayer[]>([]);
  const { t } = useTranslation(['modal']);

  const handleOnSubmit = async () => {
    if (playerList.length === 0) {
      toast.warn('กรุณาเลือกผู้เล่น');

      return;
    }

    await registerTournament();
  };

  const registerTournament = async () => {
    const newTeamRegister: IRegisterAssociationTournamentTeamRequest = {
      teamName: props.teamData.teamName,
      teamTag: props.teamData.teamTag,
      phone: props.teamData.phone,
      lineId: props.teamData.lineId,
      logoImage: props.teamData.logoImage,
      teamType: props.tournamentData.associationMatchType,
      teamTournamentPlayer: playerList.map((item) => {
        return {
          email: item.email,
          playerName: item.gameName,
        };
      }),
    };

    const newRegister: IRegisterAssociationTournamentRequest = {
      clubId: props.clubData.id,
      userId: props.userData.id,
      gameName: props.teamData.teamName,
      teamTournament: newTeamRegister,
      createdBy: props.userData.firstName,
    };

    const tournamentPlayerRequired = props.tournamentData.players;
    const tournamentPlayerReserve = props.tournamentData.reservePlayers;

    const userLessThenTournamentRequired = newTeamRegister.teamTournamentPlayer.length < tournamentPlayerRequired;
    const userMoreThenTournamentReserve = newTeamRegister.teamTournamentPlayer.length > (tournamentPlayerRequired + tournamentPlayerReserve);

    if (userLessThenTournamentRequired) {
      return toast.error('จำนวนผู้เล่นไม่ตรงตามเงื่อนไขที่กำหนด');
    }

    if (userMoreThenTournamentReserve) {
      return toast.error('จำนวนผู้เล่นเกินที่กำหนด');
    }

    const { data: userNoIdCard, status: checkIdCardStatus } = await tournamentService.checkPlayerRegisterIdCardAsync(newTeamRegister.teamTournamentPlayer);

    if (checkIdCardStatus !== HttpStatusCode.OK) {
      return toast.error('เกิดข้อผิดพลาด ขณะตรวจสอบข้อมูล');
    }

    if (userNoIdCard.length > 0) {
      return toast.error(`${userNoIdCard.join(', ')} ไม่สามารถลงทะเบียนได้ เนื่องจากไม่มีข้อมูลบัตรประชาชน`);
    }

    const { data: vaerrifydata, status: verifyStatus } = await tournamentService.verifyPlayerRegisterAsync(newTeamRegister.teamTournamentPlayer);

    if (verifyStatus !== HttpStatusCode.OK) {
      return toast.error('เกิดข้อผิดพลาด ขณะตรวจสอบข้อมูล');
    }

    if (vaerrifydata.length > 0) {
      return toast.error(`${vaerrifydata.join(', ')} ไม่พบ email ตามที่ระบุในระบบ`);
    }

    const { data: vaerrifyRegister, status: verifyRegisterStatus } =
      await tournamentService.verifyPlayerHasRegisteredTournamentAsync(
        props.tournamentData.id,
        newTeamRegister.teamTournamentPlayer);

    if (verifyRegisterStatus !== HttpStatusCode.OK) {
      return toast.error('เกิดข้อผิดพลาด ขณะตรวจสอบข้อมูล');
    }

    if (vaerrifyRegister.length > 0) {
      return toast.error(`ผู้เล่น ${vaerrifyRegister.join(', ')} สมัครแข่งขันแล้ว `);
    }

    const { data, status } = await tournamentService.registerTournamentAsync(props.tournamentData.id, newRegister);

    switch (data) {
      case 'Not Found User':
        return toast.error('ไม่พบข้อมูลผู้เล่นในระบบกรุณาตรวจสอบอีเมลให้ถูกต้อง');
      case 'NoMoreThan':
        return toast.error('ไม่สามารถลงทะเบียนได้เนื่องจากมีผู้เล่นอายุเกินที่กำหนด');
      case 'MoreThan':
        return toast.error('ไม่สามารถลงทะเบียนได้เนื่องจากมีผู้เล่นอายุไม่ถึงตามที่กำหนด');
      case 'BirthDate Require':
        return toast.error('ไม่สามารถลงทะเบียนได้กรุณาตรวจสอบว่าผู้เล่นแต่ละคนกรอกข้อมูลผู้ใช้ครบถ้วน');
    }

    if (status !== HttpStatusCode.CREATED && status !== HttpStatusCode.OK) {
      return toast.error('เกิดข้อผิดพลาด ขณะสมัคร');
    }

    if (props.createTeamClub) {
      const { status } = await club.crateTeamClubMemberAsync(props.clubData.id ?? '', playerList, props.teamData);

      if (status !== HttpStatusCode.CREATED && status !== HttpStatusCode.OK) {
        return toast.error('เกิดข้อผิดพลาด ขณะสร้างทีม');
      }
    }

    if (status === HttpStatusCode.CREATED || status === HttpStatusCode.OK) {
      props.onHideAfterSave();

      return toast.success(t('modal:competition:alert:successRegister'));
    }
  };

  const onSelectMember = useCallback((data: ClubMember) => {
    const player: TeamTournamentPlayer = {
      email: data.user.email,
      gameName: data.user.firstName,
    };

    const index = playerList.findIndex((item) => item.email === data.user.email);

    if (index !== -1) {
      const list = playerList.filter((item) => item.email !== data.user.email);

      setPlayerList(list);

      return;
    }

    if (playerList.length >= (props.tournamentData.players + props.tournamentData.reservePlayers)) {
      return toast.warn('จำนวนผู้เล่นเต็มแล้ว');
    }

    setPlayerList([...playerList, player]);
  }, [playerList, props.tournamentData]);

  const isChecked = (email: string) => {
    return playerList.findIndex((item) => item.email === email) !== -1;
  };

  return (
    <div className='mt-5'>
      <div className='text-center'>
        <h4 className='fw-bold'>{t('modal:competition:playerProfile')} คน</h4>
        <h5>{t('modal:club:subDetail')}</h5>
        <p className='text-primary mt-5'>{t('modal:competition:condition')}</p>
        <p className='text-primary'>{t('modal:competition:alertCondition')}</p>
      </div>
      <Table>
        <thead>
          <tr className='text-center'>
            <th></th>
            <th>EMAIL</th>
            <th>NAME</th>
          </tr>
        </thead>
        <tbody>
          {
            props.clubMembers?.map((data, index) =>
              <tr key={index}
                onClick={() => onSelectMember(data)}>
                <td>
                  <FormBT.Check onClick={() => onSelectMember(data)}
                    type='checkbox'
                    name='team'
                    checked={isChecked(data.user.email)}
                    readOnly />
                </td>
                <td width={300}>{data.user.email}</td>
                <td width={200}>{data.user.firstName}</td>
              </tr>)
          }
        </tbody>
      </Table>
      <div className='d-flex justify-content-between mt-5'>
        <Button type='button'
          variant='outline-primary'
          onClick={() => props.backStep()}>
          {t('modal:register:verifyOtp:back')}
        </Button>
        <Button type='button' onClick={() => handleOnSubmit()}>
          {t('modal:register:verifyOtp:confirm')}
        </Button>
      </div>
    </div>
  );
};

const ManualPlayerTeamManage = (props: PlayerTeamManageProps) => {
  const [playerList, setPlayerList] = useState<TeamTournamentPlayer[]>([]);
  const [playerReserveList, setPlayerReserveList] = useState<TeamTournamentPlayer[]>([]);
  const { t } = useTranslation(['modal']);
  const [formRules] = useState<Rules>({
    name: { required: true },
    email: { required: true, pattern: ValidatePattern.EMAIL },
    birthDate: { required: true },
    gameName: { required: true },
  });

  const setPlayerMember = (count: number) => {
    const member: TeamTournamentPlayer[] = [];

    for (let i = 0; i < count; i++) {
      member.push({} as TeamTournamentPlayer);
    }

    return member;
  };

  useEffect(() => {
    if (props.tournamentData.associationMatchType === AssociationMatchType.Single) {
      const players = setPlayerMember(1);
      setPlayerList(players);
    } else {
      const summaryPlayer = props.tournamentData.players;
      const summaryReserve = props.tournamentData.reservePlayers;
      const players = setPlayerMember(summaryPlayer);
      const playerReserve = setPlayerMember(summaryReserve);

      setPlayerList(players);
      setPlayerReserveList(playerReserve);
    }
  }, [props.tournamentData]);

  const handleChangePlayerListValue = (name: string, index: number, data: string | number | boolean) => {
    setPlayerList((playerList) =>
      playerList.map((item, i) =>
        index === i ? { ...item, [name]: data } : item
      )
    );
  };

  const handleOnSubmit = async () => {
    await registerTournament();
  };

  const registerTournament = async () => {
    const playerRegisterList = [...playerList, ...playerReserveList];

    const newTeamRegister: IRegisterAssociationTournamentTeamRequest = {
      teamName: props.teamData.teamName,
      teamTag: props.teamData.teamTag,
      phone: props.teamData.phone,
      lineId: props.teamData.lineId,
      logoImage: props.teamData.logoImage,
      teamType: props.tournamentData.associationMatchType,
      teamTournamentPlayer: playerRegisterList.map((item) => {
        return {
          email: item.email,
          playerName: item.gameName,
        };
      }),
    };

    const newRegister: IRegisterAssociationTournamentRequest = {
      clubId: props.clubData.id,
      userId: props.userData.id,
      gameName: props.teamData.teamName,
      teamTournament: newTeamRegister,
      createdBy: props.userData.firstName,
    };

    const tournamentPlayerRequired = props.tournamentData.players;
    const tournamentPlayerReserve = props.tournamentData.reservePlayers;

    const userLessThenTournamentRequired = newTeamRegister.teamTournamentPlayer.length < tournamentPlayerRequired;
    const userMoreThenTournamentReserve = newTeamRegister.teamTournamentPlayer.length > (tournamentPlayerRequired + tournamentPlayerReserve);

    if (userLessThenTournamentRequired) {
      return toast.error('จำนวนผู้เล่นไม่ตรงตามเงื่อนไขที่กำหนด');
    }

    if (userMoreThenTournamentReserve) {
      return toast.error('จำนวนผู้เล่นเกินที่กำหนด');
    }

    const { data: userNoIdCard, status: checkIdCardStatus } = await tournamentService.checkPlayerRegisterIdCardAsync(newTeamRegister.teamTournamentPlayer);

    if (checkIdCardStatus !== HttpStatusCode.OK) {
      return toast.error('เกิดข้อผิดพลาด ขณะตรวจสอบข้อมูล');
    }

    if (userNoIdCard.length > 0) {
      return toast.error(`${userNoIdCard.join(', ')} ไม่สามารถลงทะเบียนได้ เนื่องจากไม่มีข้อมูลบัตรประชาชน`);
    }

    const { data: vaerrifydata, status: verifyStatus } = await tournamentService.verifyPlayerRegisterAsync(newTeamRegister.teamTournamentPlayer);

    if (verifyStatus !== HttpStatusCode.OK) {
      return toast.error('เกิดข้อผิดพลาด ขณะตรวจสอบข้อมูล');
    }

    if (vaerrifydata.length > 0) {
      return toast.error(`${vaerrifydata.join(', ')} ไม่พบ email ตามที่ระบุในระบบ`);
    }

    const { data: vaerrifyRegister, status: verifyRegisterStatus } =
      await tournamentService.verifyPlayerHasRegisteredTournamentAsync(
        props.tournamentData.id,
        newTeamRegister.teamTournamentPlayer);

    if (verifyRegisterStatus !== HttpStatusCode.OK) {
      return toast.error('เกิดข้อผิดพลาด ขณะตรวจสอบข้อมูล');
    }

    if (vaerrifyRegister.length > 0) {
      return toast.error(`ผู้เล่น ${vaerrifyRegister.join(', ')} สมัครแข่งขันแล้ว `);
    }

    const { data, status } = await tournamentService.registerTournamentAsync(props.tournamentData.id, newRegister);

    switch (data) {
      case 'Not Found User':
        return toast.error('ไม่พบข้อมูลผู้เล่นในระบบกรุณาตรวจสอบอีเมลให้ถูกต้อง');
      case 'NoMoreThan':
        return toast.error('ไม่สามารถลงทะเบียนได้เนื่องจากมีผู้เล่นอายุเกินที่กำหนด');
      case 'MoreThan':
        return toast.error('ไม่สามารถลงทะเบียนได้เนื่องจากมีผู้เล่นอายุไม่ถึงตามที่กำหนด');
      case 'BirthDate Require':
        return toast.error('ไม่สามารถลงทะเบียนได้กรุณาตรวจสอบว่าผู้เล่นแต่ละคนกรอกข้อมูลผู้ใช้ครบถ้วน');
    }

    if (status !== HttpStatusCode.CREATED && status !== HttpStatusCode.OK) {
      return toast.error('เกิดข้อผิดพลาด ขณะสมัคร');
    }

    if (status === HttpStatusCode.CREATED || status === HttpStatusCode.OK) {
      props.onHideAfterSave();

      return toast.success(t('modal:competition:alert:successRegister'));
    }
  };

  return (
    <Form onSubmit={handleOnSubmit}
      rules={formRules}>
      <div className='mt-5'>
        <div className='text-center'>
          {/* <h4 className='fw-bold'>{t('modal:competition:playerProfile')} {props.conditionData.noOfMembers} คน</h4> */}
          <h5>{t('modal:club:subDetail')}</h5>
          <p className='text-primary mt-5'>{t('modal:competition:condition')}</p>
          <p className='text-primary'>{t('modal:competition:alertCondition')}</p>
        </div>
        <label className='p-5'>{t('modal:competition:requiredPlayer')}</label>
        {playerList.map((data, index) => (
          <div key={index}>
            <Row>
              <Col md={2}
                lg={2}>
                <div className='d-flex justify-content-center text-center'>
                  <p className='rounded border border-primary player-list p-2 text-primary'>{index + 1}</p>
                </div>
              </Col>
              <Col md={5}
                lg={5}>
                <Input value={data.email}
                  name='email'
                  label={`${t('modal:profile:email')} (ใช้อีเมลที่มีในระบบเท่านั้น)`}
                  placeholder={t('modal:profile:email')}
                  onChange={(value) => handleChangePlayerListValue('email', index, value)} />
              </Col>
              <Col md={5}
                lg={5}>
                <Input value={data.gameName}
                  name='gameName'
                  label={t('modal:competition:competitionName')}
                  placeholder={t('modal:competition:competitionName')}
                  onChange={(value) => handleChangePlayerListValue('gameName', index, value)} />
              </Col>
            </Row>
          </div>
        ))}
        {
          playerReserveList.length > 0 ? <label className='p-5'>{t('modal:competition:reservePlayer')}</label> : null
        }
        {playerReserveList.map((data, index) => (
          <div key={index}>
            <Row>
              <Col md={2}
                lg={2}>
                <div className='d-flex justify-content-center text-center'>
                  <p className='rounded border border-primary player-list p-2 text-primary'>{index + 1}</p>
                </div>
              </Col>
              <Col md={5}
                lg={5}>
                <Input
                  value={data.email}
                  name='emailReserve'
                  label={`${t('modal:profile:email')} (ใช้อีเมลที่มีในระบบเท่านั้น)`}
                  placeholder={t('modal:profile:email')}
                  onChange={(value) => handleChangePlayerListValue('email', index, value)} />
              </Col>
              <Col md={5}
                lg={5}>
                <Input value={data.gameName}
                  name='gameNameReserve'
                  label={t('modal:competition:competitionName')}
                  placeholder={t('modal:competition:competitionName')}
                  onChange={(value) => handleChangePlayerListValue('gameName', index, value)} />
              </Col>
            </Row>
          </div>
        ))}
        <div className='d-flex justify-content-between mt-5'>
          <Button type='button'
            variant='outline-primary'
            onClick={() => props.backStep()}>
            {t('modal:register:verifyOtp:back')}
          </Button>
          <Button type='submit'>
            {t('modal:register:verifyOtp:confirm')}
          </Button>
        </div>
      </div>
    </Form>
  );
};