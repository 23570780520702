import { Register } from 'models/register';
import http from 'hooks/useHttp';
import { RefereeUserHistory, TrainerUserHistory, User } from 'models';

const checkExistEmailAsync = async (email: string, creationDate?: string) => {
  return await http.get('api/account/check-exist-email', { params: { email, creationDate } });
};

const registerAsync = async (form: Register) => {
  return await http.post('api/account/register', form);
};

const getUserAsync = async (userId: string) => {
  return await http.get('api/account/user', { params: { userId } });
};

const updateUserAsync = async (user: User) => {
  return await http.put('api/account/user', user);
};

const createRefereeUserHistoryAsync = async (body: RefereeUserHistory) => {
  return await http.post('api/refereeUserHistory', body);
};

const getRefereeUserHistoryListAsync = async (userId: string, page: number, size: number) => {
  const params = {
    page,
    size,
    userId,
  };

  return await http.get('api/refereeUserHistory', { params: params });
};

const createTrainerUserHistoryAsync = async (body: TrainerUserHistory) => {
  return await http.post('api/trainerUserHistory', body);
};

const getTrainerUserHistoryListAsync = async (userId: string, page: number, size: number) => {
  const params = {
    page,
    size,
    userId,
  };

  return await http.get('api/trainerUserHistory', { params });
};

export default {
  checkExistEmailAsync,
  registerAsync,
  getUserAsync,
  updateUserAsync,
  createRefereeUserHistoryAsync,
  getRefereeUserHistoryListAsync,
  createTrainerUserHistoryAsync,
  getTrainerUserHistoryListAsync,
};