// see more: https://react-select.com/home
import { CustomValidate, Rules, useValidate, ValidateResult, ValidateType } from 'hooks';
import { connect } from 'react-redux';
import { FormState } from 'utils';
import Select, { StylesConfig } from 'react-select'
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Event, EventType } from 'utils/stores/formStore';

interface Props {
  label?: string;
  name: string;
  id?: string;
  placeholder?: string;
  value?: string[];
  items: { label: string, value: string }[];

  // props from store
  rules: Rules;
  event: Event | undefined;
}

function Control(props: Props) {
  const [selected, setSelected] = useState<typeof props.items>([]);
  const [value, setValue] = useState<string>('');
  const [rule, setRule] = useState<ValidateType | null>();
  const [validateResult, setValidateResult] = useState<ValidateResult>();
  const validate = useValidate();

  const styles: StylesConfig = {
    control: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: '#15151A',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: isFocused ? '#f38f93' : '#343434',
      transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
      boxShadow: 'unset',
      ':hover': {
        ...styles[':hover'],
        borderColor: isFocused ? '#f38f93' : '#343434',
      },
    }),
    clearIndicator: (styles) => ({
      ...styles,
      ':hover': {
        ...styles[':hover'],
        color: '#e61f26',
      },
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      ':hover': {
        ...styles[':hover'],
        color: '#e61f26',
      },
    }),
    input: (styles) => ({
      ...styles,
      color: 'white',
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: '#e61f26',
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      ':hover': {
        backgroundColor: '#c41a20',
      },
    }),
    menuList: (styles) => ({
      ...styles,
      backgroundColor: '#15151A',
      border: '1px solid #343434',
      borderRadius: '0.375rem',
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      backgroundColor: isSelected ? '#e61f26' : undefined,
      ':hover': {
        ...styles[':hover'],
        backgroundColor: '#e61f26',
      },
    }),
  };

  useEffect(() => {
    if (props.rules) {
      setRule(props.rules[props.name]);
    }
  }, [props.rules]);

  useEffect(() => {
    if (props.event?.type === EventType.ON_SUBMIT) {
      handlerOnSubmit();
    }
  }, [props.event]);

  useEffect(() => {
    if (props.value) {
      setValue(props.value.join(','));

      const selected = [] as typeof props.items;

      props.value.forEach(value => {
        const item = props.items.find(f => f.value === value);

        if (item) {
          selected.push(item);
        }
      });

      setSelected(selected);
    }
  }, [props.value, props.items]);

  const isRequired = useMemo(() => {
    if (props.rules && props.name) {
      const rule = props.rules[props.name];

      if (rule) {
        if (typeof rule.required === typeof {}) {
          return (rule.required as CustomValidate).value;
        }

        return rule.required ?? false;
      }
    }

    return false;
  }, [props.rules, props.name]);

  const handlerOnChange = useCallback((multiValue: typeof props.items) => {
    const arrayValue = [] as string[];

    multiValue.forEach((value) => arrayValue.push(value.value));

    const value = arrayValue.join(',');

    setValue(value);

    setSelected(multiValue);

    onValidate(value);
  }, [rule]);

  const onValidate = useCallback((value: string | number) => {
    const result = validate.process(value, rule);
    setValidateResult(result);
  }, [rule, props.name]);

  const handlerOnSubmit = useCallback(() => {
    onValidate(value);
  }, [value, rule]);

  return (
    <div className='multi-select mb-3'>
      <Form.Label>
        {props.label}
        {isRequired ? <span className='text-danger'> *</span> : null}
      </Form.Label>
      <InputGroup
        hasValidation>
        <Select
          value={selected}
          className={`multi-select w-100 ${validateResult?.hasError ? 'is-invalid' : ''}`}
          isMulti
          closeMenuOnSelect={false}
          options={props.items}
          placeholder={props.placeholder ?? ''}
          styles={styles}
          onChange={multiValue => handlerOnChange(multiValue as typeof props.items)} />
        {validateResult?.hasError ?
          <div className='invalid-feedback'>
            {validateResult?.message}
          </div> : null}
        <input type='hidden' value={value} name={props.name} className='control' />
      </InputGroup>
    </div>
  );
}

const mapStateToProps = (state: FormState) => state.formStore;

const InputMultiSelect = connect(mapStateToProps)(Control);

export default InputMultiSelect;