import { BackgroundImage, Breadcrumb } from "components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { tournament } from "services";
import { HttpStatusCode } from "utils";

type LocationState = {
  image: string;
  title: string;
  pageBefore: string;
  id: string;
};



export function Applicant() {
  const hostUrl = `${window.location.protocol}//${window.location.host}`;
  const { image, title, pageBefore, id } = useLocation().state as LocationState;
  const { t } = useTranslation('[tournament, home]');
  const [applicants, setApplicants] = useState<string[]>([]);

  const BREADCRUMB = [
    { label: `${t('home:home')}`, path: '/home' },
    { label: `${title}`, path: pageBefore },
    { label: `${t('tournament:listOfApplicants')}`, path: '' },
  ];

  useEffect(() => {
    getApplicantAsync(id);
  }, [id]);

  const getApplicantAsync = async (id: string) => {
    const res = await tournament.getApplicantAsync(id);

    if (res.status === HttpStatusCode.OK) {
      setApplicants(res.data);
    }
  };

  return (
    <>
      <BackgroundImage src={image ? image : `${hostUrl}/images/rov-1440x439.png`} />
      <Breadcrumb className='my-5' menus={BREADCRUMB} />
      {applicants.map((a, i) =>
        <p key={i}>{i + 1}. {a}</p>)}
    </>
  );
}