interface Props {
  src: string;
}

export function BackgroundImage({ src }: Props) {
  return (
    <div
      style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, .2), #000), url(${src})` }}
      className='background' />
  );
}