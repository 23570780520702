import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Payload {
  key: string;
  data: any;
}

export const commonStore = createSlice({
  name: 'common',
  initialState: {} as any,
  reducers: {
    set: (state: any, action: PayloadAction<Payload | Payload[]>) => {
      if (Array.isArray(action.payload)) {
        action.payload.forEach(payload => state[payload.key] = payload.data);
      } else {
        state[action.payload.key] = action.payload.data;
      }
    },
  },
});

export const { set } = commonStore.actions;

export default commonStore.reducer;