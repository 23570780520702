import { Image, Row, Col } from "react-bootstrap";
import { Input, Upload, Button, Form } from "components";
import { UploadImageType } from "utils/constants/uploadImageType";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { RegisterTournament, TeamTournament, TournamentCondition, User } from "models";
import { tournament } from "services";
import { HttpStatusCode, TournamentType, ValidatePattern, set } from "utils";
import { Rules, useAppDispatch, useAppSelector } from "hooks";
import toast from "utils/toast";

interface PlayerDetail {
  name: string;
  email: string;
  birthDate: string;
  gameName: string;
  user: User;
}

interface Props {
  conditionId: string;
  tournamentId: string;
  registerTourId: string;
  playerList: PlayerDetail[];
  teamTourId: string;  
  onHide: () => void;
  userData: User;
  teamTournament: TeamTournament;
}

interface TeamManagement {
  teamName: string,
  teamTag?: string,
  tel: string,
  lineId: string,
  logoImage: string,
}

interface PlayerTeamManageProps {
  backStep: () => void;
  onHide: () => void;
  teamData: TeamManagement;
  userData: User;
  conditionId: string;
  tournamentId: string;
  teamTourId: string;
  registerTourId: string;
  playerList: PlayerDetail[];
}

interface TeamManageProps {
  nextStep: () => void;
  sendTeamData: (teamData: TeamManagement) => void;
  teamTournament: TeamTournament;
}

export default function Team(props: Props) {
  const [step, setStep] = useState(1);
  const [teamData, setTeamData] = useState<TeamManagement>({} as TeamManagement);
  const { t } = useTranslation(['modal']);
  return (
    <>
      <div>
        <Image src="/images/team.png" className="me-2" /> <span>{t('modal:competition:teamType')}</span>
      </div>
      {step === 1
        ? <TeamManage nextStep={() => setStep(2)} sendTeamData={(data) => setTeamData(data)} teamTournament={props.teamTournament} />
        : <PlayerTeamManage
            backStep={() => setStep(1)}
            teamData={teamData}
            onHide={() => props.onHide()}
            userData={props.userData}
            conditionId={props.conditionId}
            teamTourId={props.teamTourId}
            tournamentId={props.tournamentId}
            playerList={props.playerList}
            registerTourId={props.registerTourId}
            />
      }
    </>
  );
}

const TeamManage = (props: TeamManageProps) => {
  const [logoImage, setLogoImage] = useState('');
  const [teamName, setTeamName] = useState('');
  const [teamTag, setTeamTag] = useState('');
  const [tel, setTel] = useState('');
  const [lineId, setLineId] = useState('');

  const dispatch = useAppDispatch();

  const [formRules] = useState<Rules>({
    teamName: { required: true },
    tel: { required: true, pattern: ValidatePattern.NUMBER },
    ownerLine: { required: true },
    img: { required: true },
  });
  const { t } = useTranslation(['modal']);


  const setDefauletValue = (teamTournament: TeamTournament) => {
    setLogoImage(teamTournament.logoImage);
    setTeamName(teamTournament.teamName);
    setTeamTag(teamTournament.teamTag ?? '');
    setTel(teamTournament.phone);
    setLineId(teamTournament.lineId);
  };

  useEffect(() => {
    setDefauletValue(props.teamTournament);
  }, [props.teamTournament]);

  const handleOnSubmit = useCallback(() => {
    dispatch(set({ key: 'teamName', data: teamName }));
    dispatch(set({ key: 'teamTag', data: teamTag }));
    dispatch(set({ key: 'tel', data: tel }));
    dispatch(set({ key: 'lineId', data: lineId }));
    dispatch(set({ key: 'logoImage', data: logoImage }));

    const temaData: TeamManagement = {
      teamName: teamName,
      teamTag: teamTag,
      tel: tel,
      lineId: lineId,
      logoImage: logoImage,
    };
    props.sendTeamData(temaData);
    props.nextStep();
  }, [teamName, teamTag, tel, lineId, logoImage]);

  return (
    <div>
      <div className="text-center">
        <h4 className="fw-bold">{t('modal:competition:playerProfile')}</h4>
        <h5>{t('modal:club:subDetail')}</h5>
        <p className="text-primary mt-5">{t('modal:competition:condition')}</p>
        <p className="text-primary">{t('modal:competition:alertCondition')}</p>
      </div>
      <Form onSubmit={handleOnSubmit} rules={formRules}>
        <Upload name='img' label={t('modal:competition:teamLogo')} type={UploadImageType.IMAGE_TOURNAMENT} value={logoImage} getImage={(data) => setLogoImage(data)} />
        <Row>
          <Col md={6} lg={6}>
            <Input
              value={teamName}
              label={t('modal:competition:teamName')}
              name='teamName'
              placeholder={t('modal:competition:teamName')}
              onChange={(value) => setTeamName(value)} />
          </Col>
          <Col md={6} lg={6}>
            <Input
              value={teamTag}
              label={t('modal:competition:teamTag')}
              name='signName'
              placeholder={t('modal:competition:teamTag')}
              onChange={(value) => setTeamTag(value)} />
          </Col>
          <Col md={6} lg={6}>
            <Input
              value={tel}
              label={t('modal:competition:telOwnerTeam')}
              name='tel'
              placeholder="xxx-xxxxxxx"
              onChange={(value) => setTel(value)} />
          </Col>
          <Col md={6} lg={6}>
            <Input
              value={lineId}
              label={t('modal:competition:lineOwnerTeam')}
              name='ownerLine'
              placeholder="Line ID"
              onChange={(value) => setLineId(value)} />
          </Col>
        </Row>
        <div className="d-flex justify-content-end mt-5">
          <Button type="submit">
            {t('modal:register:verifyOtp:next')}
          </Button>
        </div>
      </Form>
    </div>
  );
}

const PlayerTeamManage = (props: PlayerTeamManageProps) => {
  const [playerList, setPlayerList] = useState<PlayerDetail[]>([]);
  const { t } = useTranslation(['modal']);
  const [formRules] = useState<Rules>({
    name: { required: true },
    email: { required: true, pattern: ValidatePattern.EMAIL },
    birthDate: { required: true },
    gameName: { required: true },
  });  

  useEffect(() => {
    if (props.playerList) {
      setPlayerList(props.playerList);
    }
  }, [props]);

  const handleChangePlayerListValue = (name: string, index: number, data: string | number | boolean) => {
    setPlayerList((playerList) =>
      playerList.map((item, i) =>
        index === i ? { ...item, [name]: data } : item
      )
    );
  };

  const handleOnSubmit = async () => {
    await registerTournament();
  };

  const registerTournament = async () => {
    const teamTournament: TeamTournament = {
      teamName: props.teamData.teamName,
      teamTag: props.teamData.teamTag,
      phone: props.teamData.tel,
      lineId: props.teamData.lineId,
      logoImage: props.teamData.logoImage,
      teamTournamentPlayer: playerList,
    };

    const data: RegisterTournament = {
      tournamentId: props.tournamentId,
      conditionId: props.conditionId,
      playerId: props.userData.id,
      teamTournament: teamTournament,
      createdBy: props.userData.firstName
    };
    const response = await tournament.updateRegisterTournamentAysnc(data, TournamentType.TEAM, props.registerTourId, props.teamTourId);

    switch (response.data) {
      case 'Not Found User':
        return toast.error('ไม่พบข้อมูลผู้เล่นในระบบกรุณาตรวจสอบอีเมลให้ถูกต้อง');
      case 'NoMoreThan':
        return toast.error('ไม่สามารถลงทะเบียนได้เนื่องจากมีผู้เล่นอายุเกินที่กำหนด');
      case 'MoreThan':
        return toast.error('ไม่สามารถลงทะเบียนได้เนื่องจากมีผู้เล่นอายุไม่ถึงตามที่กำหนด');
      case 'BirthDate Require':
        return toast.error('ไม่สามารถลงทะเบียนได้กรุณาตรวจสอบว่าผู้เล่นแต่ละคนกรอกข้อมูลผู้ใช้ครบถ้วน');
    }

    if (response.status === HttpStatusCode.OK) {
      props.onHide();

      return toast.success(t('modal:competition:alert:successRegister'));
    }
  };

  return (
    <Form onSubmit={handleOnSubmit} rules={formRules}>
      <div className="mt-5">
        <div className="text-center">
          <h4 className="fw-bold">{t('modal:competition:playerProfile')} {playerList.length} คน</h4>
          <h5>{t('modal:club:subDetail')}</h5>
          <p className="text-primary mt-5">{t('modal:competition:condition')}</p>
          <p className="text-primary">{t('modal:competition:alertCondition')}</p>
        </div>
        {playerList.map((data, index) => (
          <div key={index}>
            <Row>
              <Col md={2} lg={2}>
                <div className="d-flex justify-content-center text-center">
                  <p className="rounded border border-primary player-list p-2 text-primary">{index + 1}</p>
                </div>
              </Col>
              <Col md={5} lg={5}>
                <Input
                  value={data.user.email}
                  name="email"
                  label={`${t('modal:profile:email')} (ใช้อีเมลที่มีในระบบเท่านั้น)`}
                  placeholder={t('modal:profile:email')}
                  onChange={(value) => handleChangePlayerListValue('email', index, value)} />
              </Col>
              <Col md={5} lg={5}>
                <Input
                  value={data.gameName}
                  name="gameName"
                  label={t('modal:competition:competitionName')}
                  placeholder={t('modal:competition:competitionName')}
                  onChange={(value) => handleChangePlayerListValue('gameName', index, value)} />
              </Col>
            </Row>
          </div>
        ))}
        <div className="d-flex justify-content-between mt-5">
          <Button type="button" variant="outline-primary" onClick={() => props.backStep()}>
            {t('modal:register:verifyOtp:back')}
          </Button>
          <Button type="submit">
            {t('modal:register:verifyOtp:confirm')}
          </Button>
        </div>
      </div>
    </Form>
  );
}