import { InfoCard } from 'components';
import { PlayerModel } from 'models';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface Props {
  data: PlayerModel[];
}

export default function CardPlayer({ data }: Props) {
  const navigate = useNavigate();

  return (
    <Row>
      {data.map((data) =>
        <Col key={data.id} sm='6' md='4' lg='3' className='mb-3'>
          <InfoCard
            img={data.img}
            fullName={data.name}
            nickname={data.nickname}
            nationality={data.nationality}
            onClick={() => navigate(`/profile/${data.id}`)} />
        </Col>
      )}
    </Row>
  );
}