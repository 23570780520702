import Table from 'react-bootstrap/Table';

interface Props {
  children: JSX.Element | JSX.Element[];
  ITitle?: string;
  ISubTitle?: string;
  IHover?: boolean;
}

function TableGame({ children, ISubTitle, ITitle, IHover }: Props) {
  return (
    <>
      {
        ITitle !== '' && <div className='fs-5 text-center head-table'>{ITitle}</div>
      }
      {
        ISubTitle !== '' && <div className='fs-3 text-center my-3'>{ISubTitle}</div>
      }
      <Table responsive className={`my-2 text-white table-game ${IHover && 'hover'}`}>
        {children}
      </Table>
    </>
  );
}

export default TableGame;