import { Club } from "models"
import { useEffect, useState } from "react"
import { useParams } from "react-router";
import { club, firebase } from "services";
import { HttpStatusCode } from "utils";
import { Image } from "react-bootstrap";

export function AcceptMember() {
  const [clubName, setClubName] = useState("");
  const [clubImage, setClubImage] = useState("");
  const [onInit, setOnInit] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      updateClubMemberStatusAsync(id);
    }
  }, [id]);

  const updateClubMemberStatusAsync = async (id: string) => {
    const response = await club.updateClubMemberStatusAsync(id);

    if (response.status === HttpStatusCode.ACCEPTED) {
      const clubData: Club = response.data;

      getImageFireBase(clubData.image);
      setClubName(clubData.clubName);

      return;
    }
  };

  const getImageFireBase = async (image: string) => {
    if (image) {
      const response = await firebase.getImageAsync(image);

      setClubImage(response);
      setOnInit(false);
    }
  };

  return (
    !onInit ?
      <div style={{ height: '100vh' }} className="d-flex align-items-center justify-content-center flex-column">
        <div>
          <Image
            src={clubImage}
            width="250"
            height="250" />
        </div>
        <div className="text-center mt-5">
          <h5>
            คุณได้เข้าร่วมสโมสร {clubName} แล้ว
          </h5>
          <h5 className="mt-5">
            <a href="/">ไปยังหน้าแรก</a>
          </h5>
        </div>
      </div> : <></>
  )
}