import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { componentEn, componentTh, pagesEn, pagesTh } from './data/translations';
import { Language } from './utils/constants';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        ...componentEn,
        ...pagesEn,
      },
      th: {
        ...componentTh,
        ...pagesTh,
      },
    },
    lng: Language.TH,
    fallbackLng: Language.EN,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;