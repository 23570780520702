import { useState } from 'react';
import { ValidatePattern } from 'utils/constants/validatePattern';

export type FormHook = {
  rules: Rules | undefined;
  create: (rules: Rules) => void;
}

export type CustomValidate = { value: boolean | string | number, message: string };

export interface ValidateType {
  required?: boolean | CustomValidate;
  pattern?: ValidatePattern | { value: ValidatePattern, message?: string };
  minValue?: number | CustomValidate;
  maxValue?: number | CustomValidate;
  minLength?: number | CustomValidate;
  maxLength?: number | CustomValidate;
  matching?: string | CustomValidate;
  idCardNoAndPassportNo?: boolean;
}

export interface Rules {
  [key: string]: ValidateType | null;
}

export function useForm(): FormHook {
  const [rules, setRules] = useState<Rules>();

  const create = (rules: Rules) => setRules(rules);

  return { rules, create };
}