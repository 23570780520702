import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserState {
  signedIn?: boolean;
  id?: string;
  email?: string;
  accessToken?: string;
  refreshToken?: string;
  expirationTime?: number;
}

const initialState: UserState = {
  signedIn: undefined,
  id: undefined,
  email: undefined,
  accessToken: undefined,
  refreshToken: undefined,
  expirationTime: undefined,
};

export const userStore = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUser: (state: UserState, action: PayloadAction<UserState>) => {
      state.signedIn = true;
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.expirationTime = action.payload.expirationTime;
    },
    resetUser: (state: UserState) => {
      state.signedIn = false;
      state.id = undefined;
      state.email = undefined;
      state.accessToken = undefined;
      state.refreshToken = undefined;
      state.expirationTime = undefined;
    },
  },
});

export const { setUser, resetUser } = userStore.actions;

export default userStore.reducer;