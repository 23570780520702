import { YouTube, Facebook, Instagram, TikTok } from 'components';
import { SocialMedia } from 'models/socialMedia';
import { useCallback, useMemo } from 'react';
import { FiUser } from 'react-icons/fi';
import { SocialMedia as SocialMediaEnum } from 'utils/constants/socialMedia';

interface Props {
  data: SocialMedia[];
}

export function Social(props: Props) {
  const youtube = useMemo(() => {
    return props.data?.find(f => f.channel === SocialMediaEnum.YOUTUBE);
  }, [props.data]);

  const facebook = useMemo(() => {
    return props.data?.find(f => f.channel === SocialMediaEnum.FACEBOOK);
  }, [props.data]);

  const instagram = useMemo(() => {
    return props.data?.find(f => f.channel === SocialMediaEnum.INSTAGRAM);
  }, [props.data]);

  const tiktok = useMemo(() => {
    return props.data?.find(f => f.channel === SocialMediaEnum.TIKTOK);
  }, [props.data]);

  const getFollowers = useCallback((followers: number) => {
    if (followers > 1000) {
      const quotient = Math.floor(followers / 1000);
      const remainder = Math.floor((followers % 1000) / 100);

      return `${quotient}${remainder ? `.${remainder}` : ''} K`;
    }

    return followers;
  }, []);

  return (
    <div className='social my-3'>
      {youtube ?
        <div className='youtube'>
          <YouTube border canHover />
          <p className='follower mb-0 mt-2'>
            <FiUser className='me-1' />
            <span>{getFollowers(youtube.followers)}</span>
          </p>
        </div> : null}
      {facebook ?
        <div className='facebook'>
          <div className='icon'>
            <Facebook border canHover />
          </div>
          <p className='follower mb-0 mt-2'>
            <FiUser className='me-1' />
            <span>{getFollowers(facebook.followers)}</span>
          </p>
        </div> : null}
      {instagram ?
        <div className='instagram'>
          <div className='icon'>
            <Instagram border canHover />
          </div>
          <p className='follower mb-0 mt-2'>
            <FiUser className='me-1' />
            <span>{getFollowers(instagram.followers)}</span>
          </p>
        </div> : null}
      {tiktok ?
        <div className='tiktok'>
          <div className='icon'>
            <TikTok border canHover />
          </div>
          <p className='follower mb-0 mt-2'>
            <FiUser className='me-1' />
            <span>{getFollowers(tiktok.followers)}</span>
          </p>
        </div> : null}
    </div>
  );
}