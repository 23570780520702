import { Card } from 'react-bootstrap';
import { converFullDate } from 'utils/converters/dateConverter';
import { EventMatchStatus } from 'components/Status/Events';

interface Props {
  title: string;
  dateStart: string;
  dateEnd: string;
  matchStatus: string;
  location?: string;
  imgProfile?: string;
}

export function CardHeadContent({ title, dateStart, dateEnd, matchStatus, location, imgProfile }: Props) {
  return (
    <Card className={`position-relative card-detail w-100 ${dateStart ? 'fix-height' : 'non-height'} `}>
      <Card.Img variant="top" src={imgProfile ? imgProfile : '/images/rov-1440x439.png'} className='border-0' />
      <Card.Body className="position-absolute end-0 w-100 px-3 pb-0">
        <div className='d-flex justify-content-between align-items-start align-items-md-center flex-column flex-md-row'>
          <div className='mb-3'>
            <p className="mb-0 f-size-18">{title}</p>
            <p className="mb-0 f-size-14">วันที่แข่งขัน : {converFullDate(dateStart)} - {converFullDate(dateEnd)}</p>
            <p className="mb-0 f-size-14">{location ? 'สถานที่แข่งขัน :' + location : null}</p>
          </div>
          <EventMatchStatus status={matchStatus} />
        </div>
      </Card.Body>
    </Card>
  );
}