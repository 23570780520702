import { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { FaAngleRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

interface Menu {
  label: string;
  path: string;
}

interface Props {
  menus: Menu[];
  className?: string;
}

export function Breadcrumb({ menus, className }: Props) {
  const navigate = useNavigate();

  const items = useMemo(() => {
    const items: Menu[] = [];

    menus.forEach((menu: Menu, i: number) => {
      items.push(menu);

      if (menus.length !== (i + 1)) {
        items.push({} as Menu);
      }
    });

    return items.map((item: Menu, i: number) => {
      if ((items.length - 1) === i) {
        return <Button key={i} variant='link'>{item.label}</Button>;
      } else if (Object.keys(item).length === 0) {
        return <FaAngleRight key={i} />;
      } else {
        return <Button key={i} variant='link' onClick={() => navigate(item.path)}>{item.label}</Button>;
      }
    });
  }, [menus, navigate]);

  return (
    <div className={`custom-breadcrumb ${className}`}>
      {items}
    </div>
  );
}