import { useEffect, useState } from "react";
import tesfIcon from 'assets/icons/tesf-icon.jpeg';

interface Props {
  img: string[];
  width: string;
}

interface ListProps {
  img: string;
  width: string;
  index: number;
}

const ListImg = ({ img, width, index }: ListProps) => {
  if (index < 7) {
    return (
      <img className={`rounded-circle list-img`} width={width} height={width} src={img} alt="" />
    );
  } else if (index === 7) {
    return (
      <div className={`list-img number rounded-circle width-${width} height-${width}`}>+{img.length - 7}</div>
    );
  } else {
    return null;
  }
}

export function ImgLists({ img, width }: Props) {
  const [images, setImages] = useState<string[]>([]);

  useEffect(() => {
    if (img.length > 0) {
      setImages(img);
    }
  }, [img]);
  
  return (
    <div className="d-flex justify-content-start">
      {images.map((data, index) =>
        <ListImg img={data == null ? tesfIcon : data} width={width} index={index} key={index} />)}
    </div>
  );
}