import { configureStore } from '@reduxjs/toolkit';
import backgroundStore from '../backgroundStore';
import commonStore from '../commonStore';
import modalStore from '../modalStore';
import userStore from '../userStore';

const appStore = configureStore({
  reducer: {
    modalStore,
    backgroundStore,
    commonStore,
    userStore,
  },
});

export type AppState = ReturnType<typeof appStore.getState>;
export type AppDispatch = typeof appStore.dispatch;

export default appStore;