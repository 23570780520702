import http from '../hooks/useHttp';
import {
  Club,
  SearchClubModel,
  TeamManagement,
  TeamManagementResponse,
  TeamPlayer,
  TeamPlayerResponse
} from '../models';

const getClubAsync = async (id: string) => {
  return await http.get(`api/club/${id}`);
};

const getClubByUserIdAsync = async (id: string) => {
  return await http.get<Club>(`api/club/user/${id}`);
};

const createAsync = async (club: Club) => {
  return await http.post('api/club', club);
};

const updateAsync = async (club: Club) => {
  return await http.put('api/club', club);
}

const addMemberAsync = async (email: string, clubId: string) => {
  return await http.post('api/club/add-member', { email: email, clubId: clubId });
}

const removeMemberAsync = async (userId: string, clubId: string) => {
  return await http.delete(`api/club/remove-member?userId=${userId}&clubId=${clubId}`);
};

const getClubAllAsync = async (searchModel: SearchClubModel) => {
  return await http.get('api/club/all', { params: searchModel });
};

const updateClubMemberStatusAsync = async (clubMemberId: string) => {
  return await http.put(`api/club/update-club-member-status/${clubMemberId}`);
};

const crateTeamClubMemberAsync = async (clubId: string, playerList: TeamPlayer[], teamManagement: TeamManagement) => {
  const teamTournamentRequest = {
    teamName: teamManagement.teamName,
    teamTag: teamManagement.teamTag,
    phone: teamManagement.phone,
    lineId: teamManagement.lineId,
    logoImage: teamManagement.logoImage,
    teamTournamentPlayer: playerList,
  };

  return await http.post(`api/club/${clubId}/create-team`,
    { ...teamTournamentRequest }, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
};

const getListTeamAsync = async (clubId: string) => {
  return await http.get<TeamManagementResponse[]>(`api/club/${clubId}/teams`);
};

const getListTeamMemberByTeamIdAsync = async (id: string) => {
  return await http.get<TeamPlayerResponse[]>(`api/club/team/${id}/players`);
};

export default {
  getClubAsync,
  getClubByUserIdAsync,
  createAsync,
  updateAsync,
  addMemberAsync,
  removeMemberAsync,
  getClubAllAsync,
  updateClubMemberStatusAsync,
  crateTeamClubMemberAsync,
  getListTeamAsync,
  getListTeamMemberByTeamIdAsync
}