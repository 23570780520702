import { SHORT_MONTHS, LONG_MONTHS } from '../../data/date';

const convertToFullDate = (value: string | Date) => {
  if (value) {
    const dateObj = new Date(value);
    const date = dateObj.getDate();
    const month = SHORT_MONTHS[dateObj.getMonth()];
    const year = dateObj.getFullYear() + 543;
    const hours = dateObj.getHours();
    const minute = (dateObj.getMinutes() < 10 ? '0' : '') + dateObj.getMinutes();

    return `${date} ${month} ${year}, ${hours}:${minute} น.`;
  }

  return '';
};

const converFullDate = (valueStart: string | Date, valueEnd?: string | Date) => {
  if (valueStart && valueEnd) {
    const dateStart = new Date(valueStart);
    const dateEnd = new Date(valueEnd);

    const start = dateStart.getDate();
    const end = dateEnd.getDate();

    const monthStart = SHORT_MONTHS[dateStart.getMonth()];
    const monthEnd = SHORT_MONTHS[dateEnd.getMonth()];

    const yearStart = (dateStart.getFullYear() + 543).toString().slice(2);
    const yearEnd = (dateEnd.getFullYear() + 543).toString().slice(2);

    if (monthStart === monthEnd && yearStart === yearEnd) {
      return `${start}-${end} ${monthStart} ${yearStart}`;
    }
    if (monthStart === monthEnd && yearStart !== yearEnd) {
      return `${start} ${monthStart} - ${end} ${monthEnd} ${yearStart}`;
    }
    if (monthStart !== monthEnd && yearStart === yearEnd) {
      return `${start} ${monthStart} - ${end} ${monthEnd} ${yearStart}`;
    }
    if (monthStart !== monthEnd && yearStart !== yearEnd) {
      return `${start} ${monthStart} ${yearStart} - ${end} ${monthEnd} ${yearStart}`;
    }
  } else {
    const dateObj = new Date(valueStart);
    const data = dateObj.getDate();
    const month = LONG_MONTHS[dateObj.getMonth()];
    const year = (dateObj.getFullYear() + 543).toString().slice(2);
    return `${data} ${month} ${year}`;
  }
};

const convertToDateOnly = (value: string | Date) => {
  if (value) {
    const splits = value.toString().split('-');
    const dateObj = new Date(+splits[2], +splits[1] - 1, +splits[0]);
    const date = dateObj.getDate().toString().padStart(2, '0');
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObj.getFullYear();

    return `${date}/${month}/${year}`;
  }

  return '';
};

const convertDateToFullDate = (value: string | Date) => {
  const dateObj = new Date(value);
  const date = dateObj.getDate().toString().padStart(2, '0');
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
  const year = dateObj.getFullYear();

  return `${date}/${month}/${year}`;
};

const convertDateToDateTimeOffset = (value: string) => {
  const splits = value.toString().split('-');
  const dateObj = new Date(Date.UTC(+splits[2], +splits[1] - 1, +splits[0], new Date().getUTCHours(),
    new Date().getUTCMinutes(),
    new Date().getUTCSeconds(),
    new Date().getUTCMilliseconds()));

  return dateObj;
};

export {
  convertToFullDate,
  convertToDateOnly,
  convertDateToFullDate,
  convertDateToDateTimeOffset,
  converFullDate
};