// #region Request
export interface IAssociationTournamentCreateRequest {
  id: string;
  gameName: string;
  gameId: string;
  name: string;
  imagePath: string;
  isOnlineMatch: boolean;
  location: string;
  competitionType: CompetitionType;
  associationMatchType: string;
  teamAmount: number;
  players: number;
  reservePlayers: number;
  startRegistrationDate: string;
  endRegistrationDate: string;
  competitionStartDate: string;
  competitionEndDate: string;
  isPublished: boolean;
  status: AssociationTournamentStatus;
  rule: string;
  description: string;
  associationTournamentRewards?: IAssociationTournamentReward[];
  modifyBy: string;
  game: IAssociationTournamentGame;
}

export interface IAssociationTournamentGame {
  createdBy: null | string;
  createdDate: string;
  fullName: string;
  id: string;
  img: string;
  shortName: string;
  updatedBy: null | string;
  updatedDate: null | string;
}

export interface IRegisterAssociationTournamentRequest {
  clubId?: string;
  userId: string;
  gameName: string;
  teamTournament?: IRegisterAssociationTournamentTeamRequest;
  createdBy: string;
}

export interface IRegisterAssociationTournamentTeamRequest {
  teamName: string;
  teamTag?: string;
  phone: string;
  lineId: string;
  logoImage: string;
  teamType: string;
  teamTournamentPlayer: IRegisterAssociationTournamentPlayerRequest[];
}

export interface IRegisterAssociationTournamentPlayerRequest {
  email: string;
  playerName: string;
}
// #endregion

// #region Response
export interface IAssociationTournamentResponse extends IAssociationTournamentCreateRequest {
  id: string;
}

export interface IAssociationTournamentPaginationResponse {
  id: string;
  gameName: string;
  associationMatchType: AssociationMatchType;
  name: string;
  players: number;
  reservePlayers: number;
  startRegistrationDate: Date | string;
  endRegistrationDate: Date | string;
  competitionStartDate: Date | string;
  competitionEndDate: Date | string;
  isPublished: boolean;
  createdDate: Date | string | null;
  updatedDate: Date | string | null;
  imagePath?: string;
}

export interface IAssociationTournamentReward {
  rewardIdentifier?: string;
  rating: number;
  prize: number;
}

export interface IRegisterTournamentResponse {
  id: string;
  tournamentId: string;
  conditionId: string;
  teamTournamentId?: string; //TODO: Remove this when migration is done
  associationTournamentTeamId?: string;
  clubId?: string;
  userId?: string;
  gameName?: string;
  createdBy?: string;
  createdDate: Date;
  updatedBy?: string;
  updatedDate?: Date;
  isBaned: boolean;
}
// #endregion

// #region Enum
export const enum AssociationMatchType {
  Single = "Single",
  Duo = "Duo",
  Team = "Team",
}

export const enum TournamentTeamStatusType {
  Approved = "Approved",
  Pending = "Pending",
  Rejected = "Rejected",
}

export const enum AssociationTournamentStatus {
  Draft = "Draft",
  Completed = "Completed",
}

export const enum CompetitionType {
  SingleElimination = "SingleElimination",
  SingleEliminationFindThirdPlace = "SingleEliminationFindThirdPlace",
  DoubleElimination = "DoubleElimination",
  DoubleEliminationFindChampionship = "DoubleEliminationFindChampionship",
  FreeForAll = "FreeForAll",
}
// #endregion