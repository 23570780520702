import { IoGridOutline } from 'react-icons/io5';
import { Col, Row, Button, Image } from 'react-bootstrap';
import Table from '../../components/Table/TablePlayer';
import { Pagination } from '../../components/Pagination';
import { useCallback, useState, useEffect } from 'react';
import Cards from './Cards';
import { IoIosList } from 'react-icons/io';
import Input from '../../components/Controls/Input';
import { COLUMNS } from '../../data/makeDataClub';
import { MdArrowForwardIos } from 'react-icons/md';
import { HeadLine } from '../../components/TextHeader/HeadLine';
import { useTranslation } from 'react-i18next';
import { SearchClubModel, Club as ClubModel } from 'models';
import { club as clubService } from 'services';
import { HttpStatusCode } from 'utils';
import { firebase } from 'services';
import { Form } from 'components'
import countries from 'data/json/countries.json';
import { useNavigate } from 'react-router-dom';

export function Club() {
  const navigate = useNavigate();
  const [showItemGallery, setShowItemGallery] = useState(false);
  const { t } = useTranslation('club');
  const [searchInput, setSearchInput] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const pageSize = 10;
  const [totalRows, setTotalRows] = useState(1);
  const [clubData, setClubData] = useState<ClubModel[]>([]);

  useEffect(() => {
    getClubData(searchInput, page, true);
  }, []);

  const getCountyName = (value: string) => {
    const county = countries.find(f => f.code === value);

    return county?.name;
  };

  const paginationOnChange = (pageNumber: number) => {
    getClubData(searchInput, pageNumber, false);
  };

  const getClubData = useCallback(async (searchData: string, page: number, isSearch: boolean) => {
    const search: SearchClubModel = {} as SearchClubModel;
    search.page = isSearch && searchData ? 1 : page;
    search.size = pageSize;
    search.keyword = searchData;
    setPage(page);

    const response = await clubService.getClubAllAsync(search);
    if (response.status === HttpStatusCode.OK) {
      setTotalRows(response.data.totalRows);
      setClubData(response.data.rows);
    }
  }, []);

  const handlerOnChange = (value: string) => {
    getClubData(value, page, true);
    setSearchInput(value);
  };

  const renderBodyTable = () => {
    return clubData.map((item, index) => (
      <tr key={index}>
        <td className='text-nowrap'>
          <ImageFirebase image={item.image} />
          <span className='ms-3'>{item.clubName}</span>
        </td>
        <td className='text-center text-nowrap'>
          {item.nationality ?
            <>
              <span className={`me-2 fp fp-rounded ${item.nationality.toLocaleLowerCase()}`} />
              {getCountyName(item.nationality)}
            </> : null}
        </td>
        <td className='text-end text-nowrap'>{item.totalPlayer}</td>
        <td><Button className='ms-1' variant='link' onClick={() => navigate(`/club/detail/${item.id}`)}><MdArrowForwardIos /></Button></td>
      </tr>
    ));
  };

  return (
    <div>
      <Form>
        <HeadLine title={t('club:club')} />
        <Row>
          <Col md={6} xs={10}>
            <Input onChange={handlerOnChange} placeholder={t('club:inputSearchPlaceholder')} name='search' icon='search' />
          </Col>
          <Col className='d-flex align-items-center justify-content-end' md={6} xs={2}>
            <Button variant='outline-light' onClick={() => setShowItemGallery(!showItemGallery)} className='mt-3'>
              {showItemGallery ? <IoIosList className='mb-1' /> : <IoGridOutline className='mb-1' />}
            </Button>
          </Col>
        </Row>
      </Form>

      {showItemGallery ? <Row><Cards data={clubData} /></Row> : <Table columns={COLUMNS} rows={renderBodyTable()} />}

      <div className='d-flex justify-content-center my-3'>
        {clubData && clubData.length > 0 ? <Pagination totalItemsPerPage={pageSize} totalItems={totalRows} onChange={paginationOnChange} /> : null}
      </div>
    </div>
  );
}

function ImageFirebase({ image }: {
  image: string,
}) {
  const [img, setImg] = useState<string>();

  useEffect(() => {
    if (image) {
      getImageAsync(image);
    }
  }, [image]);

  const getImageAsync = useCallback(async (image: string) => {
    const imgUrl = await firebase.getImageAsync(image);
    setImg(imgUrl);
  }, []);

  return (
    <>
      {img ?
        <Image alt={img} height={40} src={img} width={40} /> : null}
    </>
  );
}