import { useCallback, useEffect, useMemo, useState } from "react";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";

interface Item {
  id: string;
  channel: string;
  channelName: string;
  followers: number;
  link: string;
}

interface Props {
  items: Item[];
}

export function SocialMedia(props: Props) {
  const [items, setItems] = useState<Item[]>([]);

  useEffect(() => {
    setItems(props.items);
  }, [props]);

  const getSocialMedia = (item: Item) => {
    switch (item.channel) {
      case 'youtube': return createSocialMedia(<FaYoutube />, item);
      case 'facebook': return createSocialMedia(<FaFacebook />, item);
      case 'instagram': return createSocialMedia(<FaInstagram />, item);
    }
  };

  const createSocialMedia = (icon: JSX.Element, item: Item) => {
    return (
      <a
        key={item.id}
        href={item.link || '#'}
        className="item d-flex justify-content-center align-items-center flex-column text-center">
        <div className={`icon ${item.channel} mb-2`}>
          {icon}
        </div>
        <p className='m-0'>{getFollowers(item.followers)}</p>
      </a>
    );
  };

  const getFollowers = (followers: number) => {
    if (followers > 999) {
      const quotient = Math.floor(followers / 1000);
      const remainder = Math.floor((followers % 1000) / 100);

      return `${quotient}${remainder ? `.${remainder}` : ''}K`;
    }

    return followers;
  };

  const template = useCallback(() => {
    return (
      <>
        {items?.length ?
          <div className="d-flex justify-content-center gap-3">
            <>
              {items.map(item => getSocialMedia(item))}
            </>
          </div> : null}
      </>
    );
  }, [items]);

  return (
    <div className="social-media mt-5">
      {template()}
    </div>
  );
}