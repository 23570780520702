import { Image } from 'react-bootstrap';
import { TableGame, ModalDetailGame, CardHeadContent } from 'components';
import { MdOutlineClose } from "react-icons/md";
import { useState, useEffect } from 'react';
import { ModalSize } from 'utils';
import { useLoaderData } from "react-router-dom";
import { associationTournamentMatch } from 'services';
import { IAssociationTournamentMatchesResponse } from 'models/associationTournamentMatch';
import { TournamentLoader } from 'EventLayout';

interface GroupedMatches {
  round: number;
  matches: IAssociationTournamentMatchesResponse[];
}

export function TournamentEvent() {
  const [showModalLeague, setShowModalLeague] = useState(false);
  const [dataResponse, setDataResponse] = useState<IAssociationTournamentMatchesResponse[]>([]);
  const [groupedMatches, setGroupedMatches] = useState<GroupedMatches[]>([]);
  const loader = useLoaderData() as TournamentLoader;

  const [dataSelect, setDataSelect] = useState<IAssociationTournamentMatchesResponse>({} as IAssociationTournamentMatchesResponse);

  const fetchData = async () => {
    const { data } = await associationTournamentMatch.getAssociationTournamentMatchByTournamentIdAsync(loader.tournament.id, 1, 99);

    const dataResponse = data.data;
    setDataResponse(dataResponse);

    const groupedByRound = dataResponse.reduce<GroupedMatches[]>((acc, match) => {
      const { matchRound } = match;
      const roundObj = acc.find(item => item.round === matchRound);

      if (roundObj) {
        roundObj.matches.push(match);
      } else {
        acc.push({ round: matchRound, matches: [match] });
      }

      return acc;
    }, []);

    setGroupedMatches(groupedByRound.sort((a, b) => a.round - b.round));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const showModalOnSelect = (data: IAssociationTournamentMatchesResponse) => {
    console.log(data);
    setDataSelect(data);
    setShowModalLeague(!showModalLeague);
  }

  const converFullDate = (date: string) => {
    const dateObj = new Date(date);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();
    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }

  return (
    <>
      <div className='mt-5 event'>
        <div className='line-gradient-light my-5' />
        {
          groupedMatches.map((group, groupIndex) =>
            <TableGame key={groupIndex} ITitle='' ISubTitle={`ตารางการแข่งขัน รอบที่ : ${group.round}`} IHover>
              <tbody>
                {group.matches.map((data, index) => (
                  <tr key={index} onClick={() => showModalOnSelect(data)}>
                    <td className='text-end cursor-pointer' width={350}>{data.competitor[0].name}</td>
                    <td className='text-start cursor-pointer' width={250}>
                      <Image alt='' src={data.competitor[0].image} className='image-profile-team circle ms-3' />
                    </td>
                    <td className='text-center cursor-pointer' width={200}>
                      <p className='text-center mb-0'>{converFullDate(data.matchStartDate)}- {converFullDate(data.matchStartDate)}</p>
                      <div className='d-flex align-items-center justify-content-center'>
                        {(data.competitor[0].isWinner) ? <Image alt='' src='/images/arrow.png' width={15} /> : <div style={{ width: '15px' }} />}
                        <div style={{ width: '100px' }}>
                          <p className='fs-2 mb-0'>{`${data.competitor[0].point} - ${data.competitor[1].point}`}</p>
                        </div>
                        {(data.competitor[1].isWinner) ? <Image alt='' src='/images/arrow-right.png' width={15} /> : <div style={{ width: '15px' }} />}
                      </div>
                    </td>
                    <td className='text-end cursor-pointer' width={350}>
                      <Image alt='' src={data.competitor[1].image} className='image-profile-team circle me-3' />
                    </td>
                    <td className='text-start' width={250}>{data.competitor[1].name}</td>
                  </tr>
                ))}
              </tbody>
            </TableGame>)
        }
        {
          <TableGame ITitle='' ISubTitle='ตารางการแข่งขันทั้งหมด' IHover>
            <tbody>
              {dataResponse.map((data, index) => (
                <tr key={index} onClick={() => showModalOnSelect(data)}>
                  <td className='text-end cursor-pointer' width={350}>{data.competitor[0].name}</td>
                  <td className='text-start cursor-pointer' width={250}>
                    <Image alt='' src={data.competitor[0].image} className='image-profile-team circle ms-3' />
                  </td>
                  <td className='text-center cursor-pointer' width={200}>
                    <p className='text-center mb-0'>{converFullDate(data.matchStartDate)}- {converFullDate(data.matchStartDate)}</p>
                    <div className='d-flex align-items-center justify-content-center'>
                      {(data.competitor[0].isWinner) ? <Image alt='' src='/images/arrow.png' width={15} /> : <div style={{ width: '15px' }} />}
                      <div style={{ width: '100px' }}>
                        <p className='fs-2 mb-0'>{`${data.competitor[0].point} - ${data.competitor[1].point}`}</p>
                      </div>
                      {(data.competitor[1].isWinner) ? <Image alt='' src='/images/arrow-right.png' width={15} /> : <div style={{ width: '15px' }} />}
                    </div>
                  </td>
                  <td className='text-end cursor-pointer' width={350}>
                    <Image alt='' src={data.competitor[1].image} className='image-profile-team circle me-3' />
                  </td>
                  <td className='text-start' width={250}>{data.competitor[1].name}</td>
                </tr>
              ))}
            </tbody>
          </TableGame>
        }
      </div>
      <ModalDetailGame show={showModalLeague} className='modal-detail-content' size={ModalSize.LG}>
        <ModalDetailGame.Header>
          <p className="mb-0">ข้อมูลแมตช์ {loader.tournament.game.fullName}</p>
          <MdOutlineClose className="text-primary cursor-pointer" onClick={() => setShowModalLeague(!showModalLeague)} />
        </ModalDetailGame.Header>
        <ModalDetailGame.Body>
          <CardHeadContent
            title={loader.tournament.game.fullName}
            dateStart={dataSelect.matchStartDate}
            dateEnd={dataSelect.matchEndDate}
            matchStatus={dataSelect.status}
            imgProfile={loader.tournament.imagePath}
            location={loader.tournament.location} />
          <p className='text-center mb-1'>ภาพการแข่งขัน</p>
          <div className='line-gradient-primary my-1' />
          <div className='p-3 d-flex align-items-center flex-wrap'>
            {
              dataSelect.images ? dataSelect.images.map((img, imgIndex) => (
                <Image
                  alt=''
                  src={img}
                  className='me-3'
                  width={100}
                  height={100}
                  key={imgIndex}
                />
              )) : null
            }
          </div>
          <p className='text-center mb-1'>ไฮไลท์</p>
          <div className='line-gradient-primary my-1' />
          <div className='p-3 d-flex align-items-center flex-wrap'>
            {
              dataSelect.videos ? dataSelect.videos.map((highlight, highlightIndex) => (
                <video key={highlightIndex} className='me-3' width={100} height={100} controls>
                  <source src={highlight} type="video/mp4" />
                </video>
              )) : null
            }
          </div>
        </ModalDetailGame.Body>
      </ModalDetailGame>
    </>
  );
}