import { Image } from 'react-bootstrap';
interface Props {
  totalMoney: number;
  className?: string;
}

export function RewardMoney({ totalMoney, className }: Props) {
  return (
    <div className={`reward-money ${className}`}>
      <div className="polygon-up" />
      <p className='text-primary mb-0 fs-5'>เงินรางวัลรวมทั้งหมด</p>
      <div className='d-flex gap-2 align-items-center w-100 justify-content-center'>
        <Image
          src="/images/money.png"
          width={20}
          height={20}
          className='img-coin' />
        <p className='mb-0 text-number'>{new Intl.NumberFormat().format(totalMoney)}</p>
      </div>
      <div className="polygon-down" />
    </div>
  );
}