import { useAppSelector } from 'hooks';
import { Button, Row, Col } from 'react-bootstrap';
import { Input, Form, TextHeader, Select, Modal, DatePicker } from 'components';
import { useCallback } from 'react';
import { CompetitionHistory, Game, GamePlayed, User } from 'models';
import { game } from 'services';
import { HttpStatusCode, ModalSize } from 'utils';
import toast from 'utils/toast';
import { useLoaderData } from 'react-router-dom';

const TYPES = [
  { label: 'ออนไลน์', value: 'online' }
];

interface Props {
  show: boolean;
  onClose: (gamePlayedId?: string) => void;
}

export default function AddCompetitionModal(props: Props) {
  const loader = useLoaderData() as { user: User };
  const { games, gamesPlayed } = useAppSelector((state) => ({
    games: state.commonStore.games as Game[],
    gamesPlayed: state.commonStore.gamesPlayed as GamePlayed[],
  }));
  const formRule = {
    gamePlayedId: { required: true },
    date: { required: true },
    name: { required: true },
    ranking: { required: true },
    class: { required: true },
    type: { required: true },
    team: { required: true },
    result: { required: true },
    reward: { required: true },
  };

  const submitHandler = useCallback(async (data: CompetitionHistory) => {
    const response = await game.addCompetitionHistoryAsync(data);

    if (response.status === HttpStatusCode.CREATED) {
      toast.success('บันทึกข้อมูลสำเร็จ');

      props.onClose(data.gamePlayedId);
    }
  }, [loader]);

  const getGamesPlayedMaster = useCallback(() => {
    return gamesPlayed?.map(gamePlayed => {
      const game = games.find(f => f.id === gamePlayed.gameId);

      return { label: game?.shortName ?? '', value: gamePlayed.id };
    });
  }, [gamesPlayed]);

  return (
    <Modal show={props.show} size={ModalSize.LG} closeButton onClose={() => props.onClose()}>
      <Form onSubmit={submitHandler} rules={formRule}>
        <Modal.Body>
          <div className='my-4'>
            <TextHeader
              text='เพิ่มการแข่งขัน'
              position='center'
              size='sm'
              underline='center'
              width='large' />
          </div>
          <Row>
            <Col lg={6}>
              <Select label='เกมที่แข่ง' name='gamePlayedId' items={getGamesPlayedMaster()} />
            </Col>
            <Col lg={6}>
              <DatePicker label='วันที่แข่ง' name='date' />
            </Col>
            <Col xs={12}>
              <Input label='ชื่อการแข่ง' name='name' />
            </Col>
            <Col lg={6}>
              <Input label='อันดับ' name='ranking' />
            </Col>
            <Col lg={6}>
              <Input label='ระดับชั้น' name='class' />
            </Col>
            <Col lg={6}>
              <Select label='ประเภทการแข่ง' name='type' items={TYPES} />
            </Col>
            <Col lg={6}>
              <Input label='ทีม' name='team' />
            </Col>
            <Col lg={6}>
              <Input label='ผลการแข่งขัน' name='result' />
            </Col>
            <Col lg={6}>
              <Input label='เงินรางวัล' name='reward' type='number' />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className='justify-content-between'>
          <Button variant='outline-light' onClick={() => props.onClose()}>ยกเลิก</Button>
          <Button type='submit'>บันทึก</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}