interface Props {
  children?: JSX.Element | JSX.Element[] | string;
  className?: string;
  center?: boolean;
  right?: boolean;
}

export function Cell(props: Props) {
  return (
    <td
      className={`${props.className ? props.className : ''} ${props.center ? 'text-center' : ''} ${props.right ? 'text-end' : ''}`}>
      {props.children}
    </td>
  );
}