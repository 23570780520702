import { createBrowserRouter, LoaderFunctionArgs } from 'react-router-dom';
import App from 'App';
import EventLayout from './EventLayout';
import { club as clubService } from 'services';
import {
  Profile, Error, Home, Content, ContentDetail, ContentStreaming, Player, Demo, History, Events, Candidates, EventAll, CookiePolicy, PrivacyPolicy, TermsOfService, ClubDetail, Club, AcceptMember,
  TournamentEvent,
  CoppyEvent
} from 'pages';
import { account, game } from 'services';
import { AxiosResponse } from 'axios';
import { Applicant } from 'pages/Events/Detail/Applicant';
import { associationTournament } from 'services';
import { firebase } from 'services';
import { get } from 'http';
import { HttpStatusCode } from 'utils';

const getDataBeforeIntoProfileAsync = async ({ params }: LoaderFunctionArgs) => {
  const id = params.id ?? '';
  const [user, gamesPlayedWithPagination, gamesPlayed, games] = await Promise.all([
    account.getUserAsync(id),
    game.getGamesPlayedWithPaginationAsync(id, 1),
    game.getGamesPlayedAsync(id),
    game.getAllGamesAsync(),
  ]);

  const competitionHistoriesWithPagination = gamesPlayed.data.length ?
    await game.getCompetitionHistoriesWithPaginationAsync(id, gamesPlayed.data[0].id, 1) :
    {} as AxiosResponse;

  return {
    user: user.data,
    competitionHistoriesWithPagination: competitionHistoriesWithPagination.data,
    gamesPlayedWithPagination: gamesPlayedWithPagination.data,
    gamesPlayed: gamesPlayed.data,
    games: games.data,
  };
};

const getDataBeforeIntoClubPlayersAsync = async ({ params }: LoaderFunctionArgs) => {
  const clubId = params.id ?? '';

  const [club] = await Promise.all([
    clubService.getClubAsync(clubId),
  ]);

  return {
    club: club.data,
  };
};

const getDataTournament = async ({ params }: LoaderFunctionArgs) => {
  const tournamentId = params.id ?? '';
  const { data, status } = await associationTournament.getAssociationTournamentByIdAsync(tournamentId);

  if (status === HttpStatusCode.OK) {
    const gameImage = await getImage(data.game.img);
    const tournamentData = { ...data };
    tournamentData.game.img = gameImage;
    return {
      tournament: data,
    };
  }
  return '';
};

const getImage = async (image: string) => {
  return await firebase.getImageAsync(image);
};

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <Error />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/home',
        element: <Home />,
      },
      {
        path: '/content',
        element: <Content />,
      },
      {
        path: '/content/detail',
        element: <ContentDetail />,
      },
      {
        path: '/content/streaming',
        element: <ContentStreaming />,
      },
      {
        path: '/player',
        element: <Player />,
      },
      {
        path: '/profile/:id',
        element: <Profile />,
        loader: getDataBeforeIntoProfileAsync,
      },
      {
        path: '/demo',
        element: <Demo />,
      },
      {
        path: '/history',
        element: <History />,
      },
      {
        path: '/applicant',
        element: <Applicant />,
      },
      {
        path: '/event-all',
        element: <EventAll />,
      },
      {
        path: '/cookie/policy',
        element: <CookiePolicy />
      },
      {
        path: '/privacyPolicy',
        element: <PrivacyPolicy />
      },
      {
        path: '/termsOfService',
        element: <TermsOfService />
      },
      {
        path: '/club/detail/:id',
        element: <ClubDetail />,
        loader: getDataBeforeIntoClubPlayersAsync,
      },
      {
        path: '/club',
        element: <Club />,
      },
      // {
      //   path: '/event/copy',
      //   element: <CoppyEvent />
      // },
    ],
  },
  {
    path: '/event/:id/',
    element: <EventLayout />,
    errorElement: <Error />,
    loader: getDataTournament,
    children: [
      {
        path: '/event/:id/',
        element: <Events />,
        loader: getDataTournament,
      },
      {
        path: '/event/:id/candidates/',
        element: <Candidates />,
        loader: getDataTournament,
      },
      {
        path: '/event/:id/Tournament/',
        element: <TournamentEvent />,
        loader: getDataTournament,
      },
    ],
  },
  {
    path: '/AcceptMember/:id',
    element: <AcceptMember />,
    errorElement: <Error />,
  }
]);