import { Col, Row, Image } from "react-bootstrap";
import Information from "./Information";
import History from "./History";
import { useAppDispatch, useAppSelector, useUser } from "hooks";
import { HttpStatusCode, set } from "utils";
import { useTranslation } from "react-i18next";
import { FiDelete, FiEdit2 } from "react-icons/fi";
import { Button, Table, TextHeader } from "components";
import {
  AssociationMatchType,
  CompetitionHistory,
  Game,
  GamePlayed,
  GamePlayedWithPagination,
  Pagination,
  User,
} from "models";
import { useLoaderData } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { useCallback, useEffect, useState } from "react";
import AddGameModal from "./modals/AddGameModal";
import { game, account, tournament } from "services";
import EditProfileModal from "./modals/EditProfileModal";
import toast from "utils/toast";
import EditEventsDetail from "./modals/EditEventsDetail";
import Referee from "./Referee";
import Trainer from "./Trainer";

type Loader = {
  user: User;
  competitionHistoriesWithPagination: Pagination<CompetitionHistory>;
  gamesPlayedWithPagination: Pagination<GamePlayedWithPagination>;
  gamesPlayed: GamePlayed[];
  games: Game[];
};

type RegisterHistory = {
  Id: string;
  Title: string;
  Type: string;
  GameName: string;
  IsCreate: boolean;
  TeamTourId: string;
  ConditionId: string;
  TournamentId: string;
  RegitrationEndDate: string;
};

interface PlayerDetail {
  name: string;
  email: string;
  birthDate: string;
  gameName: string;
}

export function Profile() {
  const { t } = useTranslation(["profile"]);
  const loader = useLoaderData() as Loader;
  const user = useUser();
  const dispatch = useAppDispatch();
  const [editProfileModalIsOpened, setEditProfileModelIsOpened] =
    useState(false);
  const [addGameModelIsOpened, setAddGameModelIsOpened] = useState(false);
  const gamesPlayed = useAppSelector(
    (state) => state.commonStore.gamesPlayed as GamePlayed[]
  );
  const [registerHistory, setRegisterHistory] = useState<RegisterHistory[]>([]);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [userProfile, setUserProfile] = useState<User>();

  const [teamTourId, setTeamTourId] = useState('');
  const [conditionId, setConditionId] = useState('');
  const [tournamentId, setTournamentId] = useState('');
  const [registerTourId, setRegisterTourId] = useState('');


  useEffect(() => {
    dispatch(
      set([
        { key: "user", data: loader.user },
        {
          key: "competitionHistoriesWithPagination",
          data: loader.competitionHistoriesWithPagination,
        },
        {
          key: "gamesPlayedWithPagination",
          data: loader.gamesPlayedWithPagination,
        },
        { key: "gamesPlayed", data: loader.gamesPlayed },
        { key: "games", data: loader.games },
      ])
    );
  }, []);

  const getUserAsync = useCallback(async () => {
    const response = await account.getUserAsync(loader.user.id);

    if (response.status === HttpStatusCode.OK) {
      dispatch(set({ key: "user", data: response.data }));
      setUserProfile(response.data);
    }
  }, [loader]);

  const getGamesPlayedAsync = useCallback(async () => {
    const response = await game.getGamesPlayedAsync(loader.user.id);

    if (response.status === HttpStatusCode.OK) {
      dispatch(set({ key: "gamesPlayed", data: response.data }));
    }
  }, [loader]);

  const getGamesPlayedWithPaginationAsync = useCallback(async () => {
    const response = await game.getGamesPlayedWithPaginationAsync(
      loader.user.id,
      1
    );

    if (response.status === HttpStatusCode.OK) {
      dispatch(set({ key: "gamesPlayedWithPagination", data: response.data }));
    }
  }, []);

  const RenderButton = useCallback(() => {
    if (loader.user.id === user.data.id) {
      if ((loader.gamesPlayed && loader.gamesPlayed.length) || (gamesPlayed && gamesPlayed.length)) {
        return (
          <Button
            variant="danger"
            onClick={() => setAddGameModelIsOpened(true)}
          >
            <>
              <FaPlus className="me-2" />
              {t("addGame")}
            </>
          </Button>
        );
      }
    }
  }, [loader, gamesPlayed]);

  const getRegisterHistoryAsync = useCallback(async (id: string) => {
    const response = await tournament.getRegisterHistoryAsync(id);
    if (response.status === HttpStatusCode.OK) {
      console.log(response.data.rows);
      setRegisterHistory(response.data.rows);
    }
  }, []);

  const removeRegisterTour = async (id: string) => {
    const response = await tournament.deleteRegisterHistoryAsync(id);
    if (response.status === HttpStatusCode.OK) {
      toast.success('ยกเลิกข้อมุลสำเร็จ');
      if (user.data.id) {
        getRegisterHistoryAsync(user.data.id);
      }
    }
  };

  const removeRegisterTourTeam = async (regiterTourId: string, teamTourId: string) => {
    const response = await tournament.deleteRegisterHistoryTeamAsync(regiterTourId, teamTourId);
    if (response.status === HttpStatusCode.OK) {
      toast.success('ยกเลิกข้อมุลสำเร็จ');
      if (user.data.id) {
        getRegisterHistoryAsync(user.data.id);
      }
    }
  };

  const showEditDetailModal = (ConditionId: string, TournamentId: string, TeamTourId: string, registerTourId: string) => {
    setConditionId(ConditionId);
    setTournamentId(TournamentId);
    setTeamTourId(TeamTourId);
    setRegisterTourId(registerTourId);

    setShowModalDetail(true);
  };

  const getInRegisterPeriod = (enddate: string): boolean => {
    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() + 543);
    const registerEndDate = new Date(enddate);
    if (currentDate >= registerEndDate) { return false; }
    else { return true; }
  };

  const RenderRegisterHistory = useCallback(() => {
    if (userProfile?.memberType === 'player') {
      return (
        <>
          <Table>
            <thead>
              <tr className="text-center">
                <th>{t("tournamentName")}</th>
                <th>{t("tournamentType")}</th>
                <th>{t("gemeName")}</th>
                {/* <th>{t("action")}</th> */}
              </tr>
            </thead>
            <tbody>
              {registerHistory.map((rh, index) => (
                <tr key={index}>
                  <td className="text-center">{rh.Title}</td>
                  <td className="text-center">{rh.Type}</td>
                  <td className="text-center">{rh.GameName}</td>
                  {/* {rh.IsCreate && rh.Type === AssociationMatchType.Single && getInRegisterPeriod(rh.RegitrationEndDate) ? (
                    <td className="text-center">
                      <Button
                        variant="danger"
                        onClick={() => removeRegisterTour(rh.Id)}
                      >
                        <>
                          <FiDelete className="pe-1" />
                          {t("withdraw-applicant")}
                        </>
                      </Button>
                    </td>
                  ) : rh.IsCreate && getInRegisterPeriod(rh.RegitrationEndDate) ? (
                    <td className="text-center">
                      <Button
                        variant="danger"
                        onClick={() =>
                          removeRegisterTourTeam(rh.Id, rh.TeamTourId)
                        }
                      >
                        <>
                          <FiDelete className="pe-1" />
                          {t("withdraw-applicant")}
                        </>
                      </Button>
                      <Button
                        className="ms-2"
                        variant="light"
                        onClick={() =>
                          showEditDetailModal(rh.ConditionId, rh.TournamentId, rh.TeamTourId, rh.Id)
                        }
                      >
                        <>
                          <FiDelete className="pe-1" />
                          {t("edit")}
                        </>
                      </Button>

                    </td>
                  ) : (
                    <td></td>
                  )} */}
                </tr>
              ))}
            </tbody>
          </Table>

          <EditEventsDetail
            show={showModalDetail}
            onHide={() => { setShowModalDetail(false) }}
            onHideAfterSave={() => { setShowModalDetail(false); getRegisterHistoryAsync(loader.user.id); }}
            userData={loader.user}
            conditionId={conditionId}
            tournamentId={tournamentId}
            registerTourId={registerTourId}
            teamTourId={teamTourId} />
        </>
      )
    }

    return (<></>);
  }, [user]);

  useEffect(() => {
    if (loader) {
      getUserAsync();
      getGamesPlayedAsync();
      getGamesPlayedWithPaginationAsync();
    }
    if (user.data.id) {
      getRegisterHistoryAsync(user.data.id);
    }
  }, [loader, user.data.id]);

  const HistoryCheck = () => {
    if (userProfile?.memberType === "player") {
      return (
        <>
          {gamesPlayed?.length ? (
            <History />
          ) : (
            <div className="d-block text-center mb-5">
              <Image
                src="/images/game.png"
                alt="e-sport"
                className="mb-3"
                width={120}
                height={120}
              />
              <p>ไม่มีประวัติการเล่นเกม</p>
              {loader.user.id === user.data.id ? (
                <Button
                  variant="danger"
                  onClick={() => setAddGameModelIsOpened(true)}
                >
                  <>
                    <FaPlus className="me-2" />
                    {t("addGame")}
                  </>
                </Button>
              ) : null}
            </div>
          )}
        </>
      );
    }

    return (
      <></>
    );
  };

  return (
    <>
      <Row>
        <Col>
          <TextHeader position="center" text={t("profile")} className="mb-5">
            <>
              {loader.user.id === user.data.id ? (
                <Button
                  variant="danger"
                  onClick={() => setEditProfileModelIsOpened(true)}
                >
                  <>
                    <FiEdit2 className="me-2" />
                    {t("edit")}
                  </>
                </Button>
              ) : null}
            </>
          </TextHeader>
        </Col>
      </Row>
      <Row>
        <Col>
          <Information />
        </Col>
      </Row>
      {userProfile?.memberType === "player" &&
        <Row>
          <Col>
            <TextHeader text="ประวัติการเล่นเกม" className="mt-5 mb-5">
              {RenderButton()}
            </TextHeader>
          </Col>
        </Row>}
      <HistoryCheck />
      {userProfile?.memberType === 'referee' &&
        <Row>
          <Col>
            <Referee />
          </Col>
        </Row>
      }
      {userProfile?.memberType === 'trainer' &&
        <Row>
          <Col>
            <Trainer />
          </Col>
        </Row>}
      {userProfile?.memberType === 'player' &&
        <Row>
          <Col>
            <TextHeader text="ประวัติการลงทะเบียน" className="mt-5 mb-5" />
          </Col>
        </Row>
      }
      {RenderRegisterHistory()}
      <EditProfileModal
        show={editProfileModalIsOpened}
        onClose={async () => {
          await getUserAsync();
          setEditProfileModelIsOpened(false);
        }}
      />
      <AddGameModal
        show={addGameModelIsOpened}
        onClose={async () => {
          await getGamesPlayedWithPaginationAsync();
          await getGamesPlayedAsync();
          setAddGameModelIsOpened(false);
        }}
      />
    </>
  );
}

Profile.RequestAuth = true;
