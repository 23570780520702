import { FaFacebookF as FaFacebookFLib } from 'react-icons/fa';

interface Props {
  size?: 'sm' | 'lg';
  border?: boolean;
}

export default function FaFacebookF({ size, border }: Props) {
  const getSize = () => {
    if (size === 'sm') {
      return 'fs-6';
    } else if (size === 'lg') {
      return 'fs-4';
    } else {
      return 'fs-5';
    }
  };

  return (
    <div className={`icon${border ? '-border' : ''}`}>
      <FaFacebookFLib className={`${getSize()}`} />
    </div>
  );
}