import { IoGridOutline } from 'react-icons/io5';
import { Col, Row } from 'react-bootstrap';
import { Pagination } from '../../components/Pagination';
import { useCallback, useState, useEffect } from 'react';
import { IoIosList } from 'react-icons/io';
import { HeadLine } from '../../components/TextHeader/HeadLine';
import GameCheckbox from '../../components/Controls/GameCheckbox';
import CardPlayer from './CardPlayer';
import { useTranslation } from 'react-i18next';
import { Form } from '../../components/Form';
import { PlayerModel, SearchPlayerModel, shareValue } from 'models';
import { playerService } from 'services';
import { firebase } from 'services';
import { HttpStatusCode } from 'utils';
import { Button, Select, Table, Image } from 'components';
import countries from 'data/json/countries.json';
import { FaChevronRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Input from 'components/Controls/Input';
import shareValueService from 'services/shareValue';
import { ShareValueGroup } from 'utils/constants/shareValue';

export function Player() {
  const [searchInput, setSearchInput] = useState('');
  const [searchShortName, setSearchShortName] = useState<string[]>([]);
  const [totalRows, setTotalRows] = useState(1);
  const [playerData, setPlayerData] = useState<PlayerModel[]>([]);
  const [showItemGallery, setShowItemGallery] = useState(false);
  const [memberTypeItems, setMemberTypeItems] = useState<shareValue[]>([]);
  const [memberType, setMemberType] = useState<string>('player');
  const { t } = useTranslation('player');
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const navigate = useNavigate();

  const paginationOnChange = (pageNumber: number) => {
    getPlayerData(searchInput, searchShortName, memberType, pageNumber, false);
  };

  const filterGameChecked = (shortNames: string[]) => {
    getPlayerData(searchInput, shortNames, memberType, 1, false);
    setSearchShortName(shortNames);
  };

  const getPlayerData = useCallback(async (searchData: string, shortNames: string[], memberType: string, page: number, isSearch: boolean) => {
    const search: SearchPlayerModel = {} as SearchPlayerModel;
    search.page = isSearch && searchData ? 1 : page;
    search.size = size;
    search.keyword = searchData;
    search.shortNames = shortNames;
    search.memberType = memberType;
    setPage(page);

    const response = await playerService.getPlayerAsync(search);
    if (response.status === HttpStatusCode.OK) {
      setTotalRows(response.data.totalRows);
      setPlayerData(response.data.rows);
    }
    return;
  }, []);

  const handleChange = (value: string) => {
    setSearchInput(value);
  };

  useEffect(() => {
    setSize(10);
    getPlayerData(searchInput, searchShortName, memberType, page, true);
  }, [getPlayerData]);

  const getCountyName = (value: string) => {
    const county = countries.find(f => f.code === value);

    return county?.name;
  };

  const getMemberTypeAsync = async () => {
    const { data, status } = await shareValueService.getShareValueListAsync(ShareValueGroup.MemberType);

    if (status === HttpStatusCode.OK) {
      setMemberTypeItems(data);
    }
  };

  useEffect(() => {
    getMemberTypeAsync();
  }, []);

  const onChangeMemberType = (value?: string) => {
    if (value) {
      setMemberType(value);
      setSearchShortName([]);

      getPlayerData(searchInput, [], value, page, true);
    }
  }

  const mapMemberType = () => {
    if (memberType) {
      const memberTypeName = memberTypeItems.find(m => m.value === memberType);

      return memberTypeName?.label;
    }

    return "";
  };

  const onSearchPlayer = () => {
    getPlayerData(searchInput, searchShortName, memberType, page, true);
  };

  return (
    <div>
      <HeadLine title={t('player:eSportsPlayer')} />
      <Col md={2} xs={12}>
        <Select
          value={memberType}
          label={t('player:memberType')}
          name='memberType'
          items={memberTypeItems}
          onChange={(value) => onChangeMemberType(value)}
        />
      </Col>
      <Form onSubmit={onSearchPlayer}>
        <Row>
          <Col md={6} xs={10}>
            <Input
              onChange={handleChange}
              placeholder={t('player:inputSearchPlaceholder')}
              name='search'
              icon='search'
              value={searchInput}
              onSearch={onSearchPlayer} />
          </Col>
          <Button type='submit' className='d-none'>
            Search
          </Button>
          <Col className='d-flex align-items-center justify-content-end' md={6} xs={2}>
            <Button variant='outline-light' onClick={() => { setShowItemGallery(!showItemGallery); }} className='mt-3'>
              {showItemGallery ? <IoIosList className='mb-1' /> : <IoGridOutline className='mb-1' />}
            </Button>
          </Col>
        </Row>
      </Form>

      {memberType === 'player' ? <GameCheckbox valueChecked={filterGameChecked} /> : <></>}
      {showItemGallery ?
        <Row>
          <CardPlayer data={playerData} />
        </Row> :
        <Table>
          <thead>
            <tr className='text-center'>
              <th></th>
              <th>ประเภทสมาชิก</th>
              <th>ชื่อ</th>
              <th>ชื่อเล่น</th>
              <th>สัญชาติ</th>
              <th>ชื่อสโมสร</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {playerData.map((item) =>
              <tr key={item.id}>
                <td>
                  <ImageFirebase image={item.img} />
                </td>
                <td width={150} className='text-center'>{mapMemberType()}</td>
                <td width={300} className='ms-3'>{item.name}</td>
                <td width={150}>{item.nickname}</td>
                <td width={200} className='text-center'>
                  {item.nationality ?
                    <>
                      <span className={`me-2 fp fp-rounded ${item.nationality.toLocaleLowerCase()}`} />
                      {getCountyName(item.nationality)}
                    </> : null}
                </td>
                <td width={300}>{item.clubName}</td>
                <td className='text-center'>
                  <Button variant='link' onClick={() => navigate(`/profile/${item.id}`)}>
                    <FaChevronRight />
                  </Button>
                </td>
              </tr>)}
          </tbody>
        </Table>
      }

      <div className='d-flex justify-content-center my-3'>
        <Pagination
          totalItemsPerPage={size}
          totalItems={totalRows}
          onChange={paginationOnChange}
        />
      </div>
    </div>
  );
}

function ImageFirebase({ image }: {
  image: string,
}) {
  const [img, setImg] = useState<string>();

  useEffect(() => {
    getImageAsync(image);
  }, [image]);

  const getImageAsync = useCallback(async (image: string) => {
    if (image) {
      const imgUrl = await firebase.getImageAsync(image);
      setImg(imgUrl);
    }
  }, []);

  return (
    <div className='player-image'>
      <Image alt={img} height={40} src={img} width={40} />
    </div>
  );
}