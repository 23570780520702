import { Rules } from 'hooks/useForm';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import uuid from 'react-uuid';
import { ValidateResult } from 'hooks';

export enum EventType {
  ON_SUBMIT,
}

export type Event = {
  id: string;
  type: EventType;
};

interface State {
  rules: Rules
  listRules: { [key: string]: Rules },
  data: any;
  event: Event;
  validateResult: { [key: string]: ValidateResult };
}

const initialState: State = {
  rules: {} as Rules,
  listRules: {},
  data: {} as object,
  event: {} as Event,
  validateResult: {},
};

export const formStore = createSlice({
  name: 'form',
  initialState: initialState,
  reducers: {
    setRules: (state: State, action: PayloadAction<Rules>) => {
      state.rules = {
        ...state.rules,
        ...action.payload,
      };
    },
    setValidateResult: (state: State, action: PayloadAction<{ [key: string]: ValidateResult }>) => {
      state.validateResult = {
        ...state.validateResult,
        ...action.payload,
      };
    },
    setEvent: (state: State, action: PayloadAction<EventType>) => {
      state.event = {
        id: uuid(),
        type: action.payload,
      };
    },
    setData: (state: State, action: PayloadAction<{ key: string, value: string | number | boolean }>) => {
      state.data = {
        ...state.data,
        [action.payload.key]: action.payload.value,
      };
    },
  },
});

export const { setRules, setValidateResult, setEvent, setData } = formStore.actions;

export default formStore.reducer;