import { HttpStatusCode } from 'utils/constants/httpStatusCode';
import axios from 'axios';
import firebase from 'services/firebase';
import toast from 'utils/toast';

const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

Axios.interceptors.request.use(
  async (request) => {
    const token = firebase.cookies.get('token');

    if (token) {
      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${token}`,
        userId: firebase.cookies.get('userId'),
      };
    }

    return request;
  },
  error => error.response,
);

Axios.interceptors.response.use(
  response => response,
  async (error) => {
    switch (error.response?.status) {
      case HttpStatusCode.BAD_REQUEST:
        toast.error('คำขอไม่ถูกต้อง');
        break;
      case HttpStatusCode.UNAUTHORIZED:
        toast.error('ไม่ได้รับอนุญาต');
        break;
      case HttpStatusCode.FORBIDDEN:
        toast.error('หวงห้าม');
        break;
      case HttpStatusCode.METHOD_NOT_ALLOWED:
        toast.error('วิธีที่ใช้ไม่ได้รับการอนุญาต');
        break;
      case HttpStatusCode.INTERNAL_SERVER_ERROR:
        toast.error('เกิดความผิดพลาดที่เซิฟเวอร์');
        break;
      default:
    }

    return error.response;
  },
);

export default Axios;