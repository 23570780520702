import { User } from 'models';
import { TextHeader, Envelope, Discord, Steam, PlayStation, YouTube, Facebook, Instagram, TikTok } from 'components';
import { Col, Row } from 'react-bootstrap';
import { useMemo } from 'react';
import { SocialMedia } from 'utils/constants/socialMedia';

interface Props {
  data: User;
}

export default function Contact({ data }: Props) {
  const youtube = useMemo(() => {
    return data?.socialMedia?.find(f => f.channel === SocialMedia.YOUTUBE);
  }, [data]);

  const facebook = useMemo(() => {
    return data?.socialMedia?.find(f => f.channel === SocialMedia.FACEBOOK);
  }, [data]);

  const instagram = useMemo(() => {
    return data?.socialMedia?.find(f => f.channel === SocialMedia.INSTAGRAM);
  }, [data]);

  const tiktok = useMemo(() => {
    return data?.socialMedia?.find(f => f.channel === SocialMedia.TIKTOK);
  }, [data]);

  return (
    <>
      <TextHeader
        text='ข้อมูลการติดต่อ'
        underline='center'
        size='sm' />
      {data?.email ?
        <Row className='mb-2'>
          <Col className='d-flex justify-content-between align-items-center'>
            <Envelope border />
            <p className='m-0'>{data?.email}</p>
          </Col>
        </Row> : null}
      {data?.discordId ?
        <Row className='mb-2'>
          <Col className='d-flex justify-content-between align-items-center'>
            <Discord border />
            <p className='m-0'>{data?.discordId}</p>
          </Col>
        </Row> : null}
      {data?.steamId ?
        <Row className='mb-2'>
          <Col className='d-flex justify-content-between align-items-center'>
            <Steam border />
            <p className='m-0'>{data?.steamId}</p>
          </Col>
        </Row> : null}
      {data?.psnId ?
        <Row className='mb-2'>
          <Col className='d-flex justify-content-center align-items-center'>
            <PlayStation border />
            <p className='m-0'>{data?.psnId}</p>
          </Col>
        </Row> : null}
      {youtube ?
        <Row className='mb-2'>
          <Col className='d-flex justify-content-between align-items-center'>
            <YouTube border />
            {youtube.link ?
              <a href={youtube.link} target='blank'>{youtube.channelName}</a> :
              <p className='m-0'>{youtube.channelName}</p>}
          </Col>
        </Row> : null}
      {facebook ?
        <Row className='mb-2'>
          <Col className='d-flex justify-content-between align-items-center'>
            <Facebook border />
            {facebook.link ?
              <a href={facebook.link} target='blank'>{facebook.channelName}</a> :
              <p className='m-0'>{facebook.channelName}</p>}
          </Col>
        </Row> : null}
      {instagram ?
        <Row>
          <Col className='d-flex justify-content-between align-items-center'>
            <Instagram border />
            {instagram.link ?
              <a href={instagram.link} target='blank'>{instagram.channelName}</a> :
              <p className='m-0'>{instagram.channelName}</p>}
          </Col>
        </Row> : null}
      {tiktok ?
        <Row>
          <Col className='d-flex justify-content-between align-items-center'>
            <TikTok border />
            {tiktok.link ?
              <a href={tiktok.link} target='blank'>{tiktok.channelName}</a> :
              <p className='m-0'>{tiktok.channelName}</p>}
          </Col>
        </Row> : null}
    </>
  );
}