export const COLUMNS = [
  {
    header: 'ชื่อสโมสร',
    sort: true,
    className: 'text-start ps-5 text-nowrap',
  },
  {
    header: 'สัญชาติ',
    sort: true,
    className: 'text-center text-nowrap',
  },
  {
    header: 'จำนวนนักกีฬาในสโมสร',
    sort: true,
    className: 'text-end text-nowrap',
  },
];

export const ROWS = [
  {
    totalPlayer: '20',
    nationality: 'ไทย',
    name: 'BACON',
    img: '/images/demo/297340329_1501160093665147_3281579398975582092_n.jpg',
  },
  {
    totalPlayer: '20',
    nationality: 'ไทย',
    name: 'Buriram United',
    img: '/images/demo/600px-Buriram_United_Esports_allmode.png',
  },
];

export const CLUB_DETAIL =
{
  nameclub: 'Soniqss Club',
  imgclub: '/images/club-496x512.png',
  nationality: 'ไทย',
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
};