import {
	IAssociationTournamentResponse,
	IAssociationTournamentPaginationResponse,
	IPaginationResponse,
	IRegisterAssociationTournamentRequest,
	IRegisterTournamentResponse,
	IRegisterAssociationTournamentPlayerRequest
} from 'models';
import http from 'hooks/useHttp';

const getAssociationTournamentByIdAsync = async (id: string) => {
	return await http.get<IAssociationTournamentResponse>(`api/client/association/tournament/${id}`);
};

const getAssociationTournamentAsync = async (
	page: number,
	size: number) => {
	const params = {
		page,
		size
	};
	return await http.get<IPaginationResponse<IAssociationTournamentPaginationResponse[]>>(`/api/client/association/tournament`, { params });
};

const registerTournamentAsync = async (tournamentId: string, data: IRegisterAssociationTournamentRequest) => {
	return await http.post(`api/association/tournament/${tournamentId}/register`, data);
}

const checkRegisterTournamentAsync = async (tournamentId: string, userId: string) => {
	return await http.get(`api/association/tournament/${tournamentId}/user/${userId}/register`);
}

const getRegisterTournamentAsync = async (tournamentId: string) => {
	return await http.get<IRegisterTournamentResponse[]>(`api/association/tournament/${tournamentId}/register`);
}

const checkPlayerRegisterIdCardAsync = async (player: IRegisterAssociationTournamentPlayerRequest[]) => {
	return await http.post('api/association/tournament/verify/register/idcard', player);
}

const verifyPlayerRegisterAsync = async (player: IRegisterAssociationTournamentPlayerRequest[]) => {
	return await http.post('api/association/tournament/verify/register/player', player);
}

const verifyPlayerHasRegisteredTournamentAsync = async (tournamentId: string, player: IRegisterAssociationTournamentPlayerRequest[]) => {
	return await http.post(`api/association/tournament/${tournamentId}/verify/register/player`, player);
}

export default {
	getAssociationTournamentByIdAsync,
	getAssociationTournamentAsync,
	registerTournamentAsync,
	checkRegisterTournamentAsync,
	getRegisterTournamentAsync,
	checkPlayerRegisterIdCardAsync,
	verifyPlayerRegisterAsync,
	verifyPlayerHasRegisteredTournamentAsync
};