import { useAppDispatch, useAppSelector } from './redux';
import { setUser, resetUser, UserState } from '../utils/stores/userStore';
import firebase from 'services/firebase';

export function useUser() {
  const dispatch = useAppDispatch();

  const data = useAppSelector((state) => state.userStore as UserState);

  firebase.auth.onAuthStateChanged(async (user) => {
    if (user) {
      if (!data.signedIn) {
        const token = await user?.getIdTokenResult();

        const userState: UserState = {
          id: user?.uid,
          email: user?.email ?? '',
          accessToken: token?.token,
          refreshToken: user?.refreshToken,
          expirationTime: Number(token?.claims.exp),
        };

        dispatch(setUser(userState));
      }
    } else {
      dispatch(resetUser());
    }
  });

  return { data };
}