import { Col, Row, Image } from "react-bootstrap";
import { EventStatus } from 'components';
import { TournamentStatusType } from 'utils';
import { converFullDate } from 'utils/converters/dateConverter';
import { useCallback, useEffect, useState } from "react";
import { AssociationMatchType } from "models";

interface Props {
  nameEvent: string;
  subTitle: string;
  registrationStartDate: string;
  registrationEndDate: string;
  competitionStartDate: string;
  competitionEndDate: string;
  imgProfile: string;
  countType?: string;
  teamCount?: number;
  playerCount?: number;
}

export function TitleEvent({ 
  nameEvent, 
  subTitle, 
  registrationStartDate,
  registrationEndDate,
  competitionStartDate, 
  competitionEndDate, 
  imgProfile, 
  countType, 
  teamCount, 
  playerCount }: Props) {
  const [playersCount, setPlayersCount] = useState(0);
  const [teamsCount, setTeamsCount] = useState(0);

  useEffect(() => {
    if (playerCount != undefined && playerCount != 0) {
      setPlayersCount(playerCount);
    }

    if (teamCount != undefined && teamCount != 0) {
      setTeamsCount(teamCount);
    }
  }, [playerCount, teamCount]);

  const activeDateStart = useCallback((dateNow: Date) => {
    if (dateNow < new Date(registrationEndDate)) {
      return TournamentStatusType.OPENINGS;
    }
  
    if (dateNow >= new Date(registrationEndDate) && dateNow < new Date(competitionStartDate)) {
      return TournamentStatusType.CLOSE;
    }
  
    if (dateNow >= new Date(competitionStartDate) && dateNow <= new Date(competitionEndDate)) {
      return TournamentStatusType.CURRENTLY;
    }
  
    if (dateNow >= new Date(competitionEndDate)) {
      return TournamentStatusType.SUCCESS;
    }
    return '';
  }, [registrationStartDate,
    registrationEndDate,
    competitionStartDate, 
    competitionEndDate]);

  return (
    <Row className='event align-items-center'>
      <Col xs={12} lg={6}>
        <Image alt='logo' className='poster d-flex mx-auto ' src={imgProfile ? imgProfile : '/images/poster.png'} />
      </Col>
      <Col xs={12} lg={6}>
        <div className='mt-md-5 mt-2'>
          <EventStatus status={activeDateStart(new Date())} />
          <div className='mt-md-5 mt-2'>
            <p className='fs-1 text-gradient-light text-uppercase mb-0'>{nameEvent}</p> <br />
            <p className='fs-2 text-gradient-light text-uppercase mb-0'>{subTitle}</p>
            <p className='text-primary mt-3'>ช่วงเวลาแข่งขัน</p>
            <p className="fs-2">{`${converFullDate(competitionStartDate)} - ${converFullDate(competitionEndDate)}`}</p>
            {
              countType === AssociationMatchType.Single ?
                <>
                  <p className='text-primary mt-3'>จำนวนผู้เข้าแข่งขัน</p>
                  <p className='fs-2'>{playersCount} คน</p>
                </> :
                <>
                  <p className='text-primary mt-3'>จำนวนผู้เข้าร่วมการแข่งขันทั้งหมด</p>
                  <p className='fs-2'>{teamsCount} ทีม {playersCount} คน</p>
                </>
            }
          </div>
        </div>
      </Col>
    </Row>
  );
}