import tesfIcon from 'assets/icons/tesf-icon.jpeg';
import { Image as BtImage } from 'react-bootstrap';
import { useEffect, useState } from "react";

interface props {
    src?: string;
    alt?: string;
    className?: string;
    height?: number;
    width?: number;
}

function Image(props: props) {
    const [image, setImage] = useState<string>(tesfIcon);

    useEffect(() => {
        if (props.src && props.src.length > 0) {
            setImage(props.src);
        }
    }, [props.src]);

    return <BtImage alt={props.alt} src={image} className={props.className} width={props.width} height={props.height} />
}

export default Image;