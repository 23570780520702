import { Button, Col, Image, Row, Spinner } from 'react-bootstrap';
import { useCallback } from 'react';
import {
  Club,
  Club as ClubModel,
  ClubMember,
  SocialMediaClubs,
  TeamManagementResponse,
  TeamPlayerResponse,
  User
} from 'models';
import { useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import countries from 'data/json/countries.json';
import { account, firebase } from 'services';
import { HeadLine } from 'components/TextHeader/HeadLine';
import { FiEdit2 } from 'react-icons/fi';
import { SocialMedia, Input, Modal, Form, Card, Table } from 'components';
import { HttpStatusCode, ModalSize, showClubDetailModal } from 'utils';
import { club as clubService } from 'services';
import toast from 'utils/toast';
import { FaCrown, FaUser } from 'react-icons/fa';
import { useUser } from 'hooks';
import AddClubTeam from './modals/AddClubTeam';
import { ListPlayerModal } from './modals/ListPlayer';

type Loader = {
  club: ClubModel,
}

export function ClubDetail() {
  const loader = useLoaderData() as Loader;
  const [club, setClub] = useState<ClubModel>({} as ClubModel);
  const [socialMediaClubs, setSocialMediaClubs] = useState<SocialMediaClubs[]>([]);
  const [userData, setUserData] = useState<User>();
  const [teams, setTeams] = useState<TeamManagementResponse[]>([]);
  const user = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    setClub(loader.club);
    setSocialMediaClubs(loader.club.socialMediaClubs);
    addEventListener(loader.club.id ?? '');
    getTeamAsync(loader.club.id ?? '');
  }, []);

  useEffect(() => {
    if (user && !userData) {
      getUserAsync(user.data.id ?? '');
    }
  }, [user, userData]);

  const addEventListener = useCallback((id: string) =>
    document.addEventListener('onClubUpdated', async () => {
      await getClubAsync(id);
    }), []);

  const getClubAsync = useCallback(async (id: string) => {
    if (id) {
      const response = await clubService.getClubAsync(id);
      setClub(response.data);
      setSocialMediaClubs(response.data.socialMediaClubs);
    }
  }, []);

  const getUserAsync = useCallback(async (id: string) => {
    if (id) {
      const response = await account.getUserAsync(id);
      setUserData(response.data);
    }
  }, []);

  const getTeamAsync = useCallback(async (clubId: string) => {
    const { data, status } = await clubService.getListTeamAsync(clubId);
    if (status !== HttpStatusCode.OK) {
      toast.error('เกิดข้อผิดพลาด ขณะดึงข้อมูลทีม');

      return;
    }

    setTeams(data);
  }, []);

  const leave = async (userId: string, clubId: string) => {
    const response = await clubService.removeMemberAsync(userId, clubId);

    if (response.status === HttpStatusCode.OK) {
      toast.success('ออกจากสโมสรสำเร็จ');
      document.dispatchEvent(new CustomEvent('onLeavedClub'));
      navigate('/');
    }
  };

  return (
    <div className='club-detail'>
      <HeadLine title='ข้อมูลสโมสร'
        size='lg'
        className='mt-5' />
      <div className='d-flex justify-content-end'>
        {user.data.id === club.userId ?
          <Button type='button'
            className='d-flex align-items-center'
            variant='primary'
            onClick={() => showClubDetailModal(loader.club)}>
            <FiEdit2 className='me-2' />แก้ไข
          </Button> :
          null}
        {userData?.clubId === club.id && userData?.id !== club.userId ?
          <Button type='button'
            className='d-flex align-items-center'
            variant='primary'
            onClick={() => leave(user.data.id || '', loader.club.id || '')}>
            ออกจากสโมสร
          </Button>
          : null}
      </div>
      <ClubImage image={club.image} />
      <Name name={club.clubName}
        nationality={club.nationality} />
      <Detail detail={club.description} />
      <SocialMedia items={socialMediaClubs} />
      <div className='player-competition mt-5'>
        <Player
          onHideAfterSave={() => getTeamAsync(club.id ?? '')}
          member={club.clubMembers}
          club={club}
          teams={teams} />
      </div>
    </div>
  );
}

function ClubImage(props: { image: string }) {
  const [img, setImg] = useState<string>();

  useEffect(() => {
    getImageAsync();
  }, [props.image]);

  const getImageAsync = useCallback(async () => {
    if (props.image) {
      const imgUrl = await firebase.getImageAsync(props.image);

      setImg(imgUrl);
    }
  }, [props.image]);

  return (
    <div className='club-image'>
      {img ?
        <Image roundedCircle
          alt={img}
          className='m-auto d-block image-profile big circle'
          src={img} /> :
        <Spinner animation='border'
          variant='primary' />}
    </div>
  );
}

function Name(props: { name: string, nationality: string }) {
  const [name, setName] = useState('');
  const [nationality, setNationality] = useState('');

  useEffect(() => {
    if (props.name && props.nationality) {
      setName(props.name);
      setNationality(props.nationality);
    }
  }, [props.name, props.nationality]);

  const getCountyName = (value: string) => {
    const county = countries.find(f => f.code === value);

    return county?.name;
  };

  return (
    <div className='club-name my-5'>
      <div className='content'>
        <div className='line' />
        <h1 className='name my-3'>{name}</h1>
        <div className='d-flex justify-content-center gap-3 mb-3'>
          <span className={`w-10 fp fp-rounded ${nationality.toLocaleLowerCase()}`} /> {getCountyName(nationality)}
        </div>
        <div className='line' />
      </div>
    </div>
  );
}

function Detail(props: { detail: string }) {
  return (
    <>
      {props.detail ?
        <div className='club-detail'>
          <h4>รายละเอียดสโมสร</h4>
          <p>{props.detail}</p>
        </div> : null}
    </>

  );
}

function Player(props: { member: ClubMember[], club: Club, teams: TeamManagementResponse[], onHideAfterSave: () => void }) {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const user = useUser();
  const [showAddTeamModal, setShowAddTeamModal] = useState(false);

  function AddMemberModal(props: { show: boolean, clubId: string, onClose: () => void }) {
    const [isDisabled, setIsDisabled] = useState(false);

    const submitHandlerAsync = async (data: { email: string }) => {
      setIsDisabled(true);

      const response = await clubService.addMemberAsync(data.email, props.clubId);

      if (response.status === HttpStatusCode.OK) {
        toast.success('เพิ่มผู้เล่นเข้าสู่สโมสรสำเร็จ');
        setShowModal(false);
        document.dispatchEvent(new CustomEvent('onClubUpdated'));

        return;
      }
      
      if (response.status === HttpStatusCode.NO_CONTENT) {
        toast.error('ไม่พบอีเมลนี้');
        setIsDisabled(false);

        return;
      }

      if (response.status === HttpStatusCode.CONFLICT) {
        toast.error('อีเมลสมัครเข้าสโมสรแล้ว กรุณาเปลี่ยนเป็นอีเมลอื่น');
        setIsDisabled(false);

        return;
      }

      if (response.status !== HttpStatusCode.OK) {
        toast.error('เกิดข้อผิดพลาด ขณะเพิ่มผู้เล่นเข้าสู่สโมสร');
        setIsDisabled(false);

        return;
      }

      setIsDisabled(false);
    };

    return (
      <Modal show={props.show}
        onClose={props.onClose}>
        <Modal.Body>
          <Form onSubmit={submitHandlerAsync}
            rules={{ email: { required: true } }}>
            <Input label='อีเมล'
              name='email' />
            <div className='d-flex justify-content-center'>
              <Button type='submit'
                disabled={isDisabled}>
                เชิญ
                {isDisabled ?
                  <Spinner className='ms-2'
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true' /> : null}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  const remove = async (userId: string, clubId: string) => {
    const response = await clubService.removeMemberAsync(userId, clubId);

    if (response.status === HttpStatusCode.OK) {
      toast.success('นำผู้เล่นออกสำเร็จ');
      document.dispatchEvent(new CustomEvent('onClubUpdated'));
    }
  };

  const [showListPlayerModal, setShowListPlayerModal] = useState(false);
  const [player, setPlayer] = useState<TeamManagementResponse>({} as TeamManagementResponse);

  const showMemberModal = (player: TeamManagementResponse) => {
    setPlayer(player);
    setShowListPlayerModal(true);
  };

  return (
    <div className='player'>

      <div className='d-flex justify-content-between'>
        <h4 className='m-0 d-flex'>ผู้เล่นในสโมสร</h4>
        {
          props.club.userId === user.data.id ?
            <Button className='me-2'
              variant='primary'
              onClick={() => setShowModal(true)}>เพิ่มผู้เล่น
            </Button>
            : null
        }
      </div>
      <Row className='mt-3'>
        <>
          {props.member?.map((player) =>
            <Col key={player.id}
              sm='6'
              md='4'
              lg='3'
              className='mb-3'>
              <InfoCard club={props.club}
                userId={player.userId ?? ''}
                onClick={() => navigate(`/profile/${player.userId}`)}
                img={player.user.img}
                fullName={`${player.user.firstName} ${player.user.middleName ?? ''} ${player.user.lastName ?? ''}`}
                nickname={player.user.nickname}
                nationality={player.user.nationality}
                onRemove={() => remove(player.userId, player.clubId)}
                status={player.status}
                isHost={player.host} />
            </Col>)}
        </>
      </Row>

      <div className='d-flex justify-content-between'>
        <h4 className='m-0 d-flex'>ทีมในสโมสร</h4>
        {
          props.club.userId === user.data.id ?
            <Button className='me-2'
              variant='primary'
              onClick={() => setShowAddTeamModal(true)}>เพิ่มทีม
            </Button> : null
        }
      </div>

      <Row className='mt-3'>
        <>
          {props.teams?.map((player) =>
            <Col key={player?.id}
              sm='6'
              md='4'
              lg='3'
              className='mb-3'>
              <InfoTeamCard
                onClick={() => showMemberModal(player)}
                img={player.logoImage}
                teamName={player.name} />
            </Col>)}
        </>
      </Row>
      <AddMemberModal show={showModal}
        clubId={props.club.id ?? ''}
        onClose={() => setShowModal(false)} />

      <AddClubTeam
        clubId={props.club.id ?? ''}
        show={showAddTeamModal}
        clubMember={props.member}
        onHide={() => {
          setShowAddTeamModal(false);
        }}
        onHideAfterSave={() => {
          setShowAddTeamModal(false);
          props.onHideAfterSave();
          // getRegisterHistoryAsync(loader.user.id);
        }} />
      <ListClubMemberModal
        show={showListPlayerModal}
        onHide={() => setShowListPlayerModal(false)}
        teamClub={player} />
    </div>
  );
}

function InfoCard(props: {
  userId: string;
  club: Club;
  img: string;
  fullName: string;
  nickname?: string;
  nationality?: string;
  status: string;
  isHost: boolean;
  onClick?: () => void;
  onRemove: () => void;
}) {
  const [img, setImg] = useState<string>();
  const [alt, setAlt] = useState<string>();
  const user = useUser();

  useEffect(() => {
    getImageAsync();
  }, [props.img]);

  const onClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  const getCountyName = (code: string) => {
    const county = countries.find(f => f.code === code);

    return county?.name;
  };

  const getImageAsync = useCallback(async () => {
    if (props.img) {
      const imgUrl = await firebase.getImageAsync(props.img);

      setImg(imgUrl);
      setAlt(props.img);
    }
  }, [props.img]);

  return (
    <>
      <Card onClick={onClick}
        className='custom-info-card'>
        <div className='header'>
          <Row className='my-3'>
            <Col className='img d-flex justify-content-center align-items-center'>
              {props.img ?
                img ?
                  <Image alt={alt}
                    src={img} /> :
                  <Spinner animation='border'
                    variant='primary' />
                : <FaUser />}
            </Col>
          </Row>
          <Row>
            <Col className='text-center'>
              <h5>{props.fullName}{props.club.userId === props.userId ? <FaCrown className='ms-2' /> : null}</h5>
            </Col>
          </Row>
          <Row>
            <Col className='text-center text-muted'>
              <h6>{props.nickname || <>&nbsp;</>}</h6>
            </Col>
          </Row>
          <Row>
            <Col className='text-center text-muted'>
              {props.isHost
                ? <h6>(หัวหน้าสโมสร)</h6>
                : <h6>{props.status === 'Accept' && '(สมาชิก)'}</h6>
              }
            </Col>
          </Row>
        </div>
        <div className='body border-top'>
          <Row>
            <Col>
              <div className='nationality d-flex align-items-center justify-content-center gap-2'>
                {props.nationality ?
                  <>
                    <span className={`fp fp-rounded ${props.nationality.toLocaleLowerCase()}`} /> {getCountyName(props.nationality)}
                  </>
                  : null}
              </div>
            </Col>
          </Row>
        </div>
      </Card>
      {props.club.userId === props.userId || props.club.userId !== user.data.id ?
        null :
        <Button variant='primary'
          size='sm'
          className='w-100 mt-2'
          onClick={props.onRemove}>นำออก
        </Button>}
    </>
  );
}

function InfoTeamCard(props: {
  img: string;
  teamName: string;
  onClick: () => void;
}) {
  const [img, setImg] = useState<string>();
  const [alt, setAlt] = useState<string>();

  useEffect(() => {
    getImageAsync();
  }, [props.img]);

  const getImageAsync = useCallback(async () => {
    if (props.img) {
      const imgUrl = await firebase.getImageAsync(props.img);

      setImg(imgUrl);
      setAlt(props.img);
    }
  }, [props.img]);

  return (
    <>
      <Card className='custom-info-card' onClick={() => props.onClick()}>
        <div className='header'>
          <Row className='my-3'>
            <Col className='img d-flex justify-content-center align-items-center'>
              {props.img ?
                img ?
                  <Image alt={alt}
                    src={img} /> :
                  <Spinner animation='border'
                    variant='primary' />
                : <FaUser />}
            </Col>
          </Row>
          <Row>
            <Col className='text-center'>
              <h5>{props.teamName}</h5>
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
}

interface ListClubMemberModalProps {
  show: boolean;
  onHide: () => void;
  teamClub: TeamManagementResponse;
}
const ListClubMemberModal = (props: ListClubMemberModalProps) => {
  return (
    <Modal show={props.show}
      size={ModalSize.LG}
      closeButton
      onClose={() => props.onHide()}>
      <Modal.Body>
        <Table>
          <thead>
            <tr className='text-center'>
              <th>ชื่อในเกมส์</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {
              props.teamClub?.clubMembers?.map((player, index) =>
                <tr key={index}>
                  <td width={200} className='ms-3'>
                    {`${player.user.firstName} ${player.user.middleName ?? ''} ${player.user.lastName ?? ''}`}
                  </td>
                  <td width={300}>{player.user.email}</td>
                </tr>)
            }
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
}