export default function IconSort() {
  return (
    <svg fill='none' height='15' viewBox='0 0 14 15' width='14' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1770_16433)'>
        <path d='M5.25001 1.43359C5.25001 1.31756 5.29611 1.20628 5.37815 1.12423C5.4602 1.04219 5.57148 0.996094 5.68751 0.996094C5.80354 0.996094 5.91482 1.04219 5.99687 1.12423C6.07892 1.20628 6.12501 1.31756 6.12501 1.43359V12.19C6.12499 12.2764 6.09936 12.361 6.05134 12.4329C6.00332 12.5048 5.93507 12.5608 5.85521 12.594C5.77535 12.6271 5.68746 12.6358 5.60264 12.6191C5.51782 12.6023 5.43987 12.5608 5.37864 12.4997L1.31164 8.43359C1.27089 8.39295 1.23857 8.34468 1.21651 8.29152C1.19446 8.23837 1.18311 8.18139 1.18311 8.12384C1.18311 8.0663 1.19446 8.00932 1.21651 7.95616C1.23857 7.90301 1.27089 7.85473 1.31164 7.81409H1.31339C1.3954 7.73234 1.50647 7.68644 1.62226 7.68644C1.73806 7.68644 1.84913 7.73234 1.93114 7.81409L5.25001 11.1356V1.43359ZM7.87501 2.05222C7.8752 1.96583 7.90097 1.88143 7.94905 1.80966C7.99714 1.73789 8.06539 1.68197 8.14522 1.64893C8.22504 1.6159 8.31286 1.60724 8.3976 1.62404C8.48234 1.64084 8.56021 1.68235 8.62139 1.74334L12.6884 5.80859C12.7291 5.84923 12.7615 5.89751 12.7835 5.95066C12.8056 6.00382 12.8169 6.0608 12.8169 6.11834C12.8169 6.17589 12.8056 6.23287 12.7835 6.28602C12.7615 6.33918 12.7291 6.38745 12.6884 6.42809H12.6866C12.6046 6.50985 12.4936 6.55575 12.3778 6.55575C12.262 6.55575 12.1509 6.50985 12.0689 6.42809L8.75001 3.10834V12.8086C8.75001 12.9246 8.70392 13.0359 8.62187 13.118C8.53982 13.2 8.42854 13.2461 8.31251 13.2461C8.19648 13.2461 8.0852 13.2 8.00315 13.118C7.92111 13.0359 7.87501 12.9246 7.87501 12.8086V2.05222Z' fill='white' />
      </g>

      <defs>
        <clipPath id='clip0_1770_16433'>
          <rect fill='white' height='14' transform='translate(0 0.121094)' width='14' />
        </clipPath>
      </defs>
    </svg>
  );
}
