import { useEffect, useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import sessionStore from 'pages/store/sessionStore';
import { useTranslation } from 'react-i18next';
import { Session, SessionTimeOut } from 'utils/constants/enum';

export function CookieConsent() {
  const [isShow, setIsShow] = useState(true);
  const { t } = useTranslation('cookie');

  useEffect(() => {
    setIsShow(!sessionStore.get(Session.ACCEPT_COOKIES));
  }, []);

  const handleSetCookies = () => {
    setIsShow(false);
    
    sessionStore.set(Session.ACCEPT_COOKIES, true, SessionTimeOut.ONE_YEAR);
  };

  return (
    <>
      {
        isShow ?
          <div className='alert-terms'>
            <Container>
              <div className='d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between'>
                <div className='d-flex align-items-center'>
                  <p className='fw-normal text-white fs-confirm mb-0 ms-3'>
                    {t('message')} 
                    <a href='/cookie/policy' target='_blank' rel="noopener noreferrer" className='pointer'> {t('readMore')}</a>
                  </p>
                </div>
                <Button
                  variant='primary'
                  className='mx-1 px-4'
                  onClick={() => handleSetCookies()}>
                  {t('accept')}
                </Button>
              </div>
            </Container>
          </div>
          : ''
      }
    </>
  );
}