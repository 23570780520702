import Image from 'react-bootstrap/Image';
import { Social } from 'pages';
import { Club, User } from 'models';
import { FaUser } from 'react-icons/fa';
import { Col, Row } from 'react-bootstrap';
import { Line } from 'components';
import Contact from './Contact';
import GamePlayed from './GamePlayed';
import { useAppSelector } from 'hooks';
import { useEffect, useState } from 'react';
import firebase from 'services/firebase';
import countries from 'data/json/countries.json';
import { useTranslation } from 'react-i18next';
import { convertToDateOnly } from 'utils';
import { club as clubService } from 'services';
import { useLoaderData } from 'react-router-dom';

type Loader = {
  user: User,
}

export default function Information() {
  const hostUrl = `${window.location.protocol}//${window.location.host}`;
  const { user, gamesPlayed } = useAppSelector((state) => ({
    user: state.commonStore.user as User,
    gamesPlayed: state.commonStore.gamesPlayedWithPagination,
  }));
  const [imgUrl, setImgUrl] = useState<string>();
  const [clubImg, setClubImg] = useState<string>();
  const { t } = useTranslation('profile');
  const [club, setClub] = useState<Club>({} as Club);
  const loader = useLoaderData() as Loader;

  useEffect(() => {
    if (loader.user !== undefined && user?.img) {
      getProfileImageAsync(user?.img);
    } else {
      setImgUrl(undefined);
    }

    if (user !== undefined && user?.clubId) {
      getClubAsync(user.clubId);
    }
  }, [user, loader]);

  const getClubAsync = async (id: string) => {
    if (id) {
      const response = await clubService.getClubAsync(id);
      setClub(response.data);

      await getClubImageAsync(response.data.image);
    }
  };

  const getProfileImageAsync = async (path: string) => {
    const imgUrl = await firebase.getImageAsync(path);

    setImgUrl(imgUrl);
  };

  const getClubImageAsync = async (path: string) => {
    const imgUrl = await firebase.getImageAsync(path);

    setClubImg(imgUrl);
  };

  const getCountyName = (value: string) => {
    const county = countries.find(f => f.code === value);

    return county?.name;
  };

  const getGender = (value: string) => {
    if (value === 'male') {
      return t('male');
    } else if (value === 'female') {
      return t('female');
    }
  };

  const getAge = (birthday: string) => {
    if (birthday) {
      const splits = birthday.toString().split('-');
      const date = new Date(+splits[2], +splits[1] - 1, +splits[0]);

      return new Date().getFullYear() - date.getFullYear();
    }
  };

  return (
    <div className='user-information'>
      <Row>
        <Col lg={6}>
          <Row>
            <Col className='profile d-flex align-items-center justify-content-center'>
              {imgUrl ?
                <div className='image-profile'>
                  <Image alt={user?.img} src={imgUrl} width={'100%'} height={'100%'} />
                </div>
                :
                <div className='icon d-flex align-items-center justify-content-center'>
                  <FaUser />
                </div>
              }
            </Col>
          </Row>
          <Row className='my-3'>
            <Col>
              <Line />
              <h3 className='text-center m-0'>
                {user?.firstName}{user?.middleName ? ` ${user?.middleName}` : ''} {user?.lastName}
              </h3>
              {user?.nickname ? <h5 className='text-center mb-0 mt-3'>{user?.nickname}</h5> : null}
              <Line />
            </Col>
          </Row>
          <Row>
            <Col className='club'>
              <div className='border border-2 rounded-3 logo-club'>
                <h6 className='text-center my-3 d-flex justify-content-center align-items-center gap-2'>
                  สโมสร
                  {user?.clubId ?
                    <>
                      <Image className='img-club small circle' src={clubImg} />
                      {club.clubName}
                    </> :
                    <span className='mb-0 ms-3'>ไม่มี</span>}
                </h6>
                {user?.birthday || user?.gender || user?.nationality ?
                  <div className='border-top border-2 my-3'>
                    <h6 className='text-center my-3 d-flex justify-content-center align-items-center'>
                      {user?.birthday ?
                        <>
                          {convertToDateOnly(user?.birthday)}
                          <span className='ms-2'>({getAge(user?.birthday)} ปี)</span>
                        </> : null}
                      {user?.gender ?
                        <span className='ms-3'>{getGender(user.gender)}</span>
                        : null}
                      {user?.nationality ?
                        <span className='ms-3'>
                          <span className={`fp fp-rounded ${user?.nationality.toLocaleLowerCase()}`} /> {getCountyName(user?.nationality)}
                        </span>
                        : null}
                    </h6>
                  </div> : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Social data={user?.socialMedia} />
            </Col>
          </Row>
        </Col>
        <Col lg={6}>
          <div className='border border-2 rounded-3 p-4'>
            {user?.desc ?
              <Row>
                <Col>
                  <p className='text-center text-wrap text-break mb-0'>
                    {user?.desc}
                  </p>
                </Col>
              </Row> : null}
            <Row>
              <Col>
                <Contact data={user} />
              </Col>
            </Row>
            {gamesPlayed?.totalRows && user.memberType === 'player' ?
              <Row>
                <Col>
                  <GamePlayed />
                </Col>
              </Row> : null}
          </div>
        </Col>
      </Row>
    </div>
  );
}