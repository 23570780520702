import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import { Image } from 'react-bootstrap';
import { Banner } from 'models/banner';
import { useNavigate } from 'react-router-dom';
import { BannerTypes } from 'utils/constants/bannerType';
import { associationTournament } from 'services';
import { HttpStatusCode } from 'utils';

import 'swiper/css';
import 'swiper/css/pagination';

interface Props {
  bannerData: Banner[];
}

export default function BannerSwiper(props: Props) {
  const delay = 5000;
  const navigate = useNavigate();
  const handleClickBanner = async (bannerType: string, relationId: string) => {
    const { data, status } = await associationTournament.getAssociationTournamentByIdAsync(relationId);
    if (status === HttpStatusCode.OK) {
      if (bannerType === BannerTypes.TOURNAMENT) {
        navigate(`/event/${data.id}`);
      }
    }
    //TO DO: NAVIGATE TO NEWS DETAIL PAGE
    return;
  };

  return (
    <Swiper
      autoplay={{ delay: delay, disableOnInteraction: false }}
      modules={[Pagination, Autoplay]}
      pagination={{ clickable: true }}
      className='banner-swiper'>
      {props.bannerData?.map((data, index) => (
        <SwiperSlide key={index} className='cursor-pointer' onClick={() => handleClickBanner(data.bannerType, data.relationId)}>
          <Image className='h-100 w-100' src={data.image} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}