import { Row, Col } from 'react-bootstrap';
import { Content } from 'models/content';
import NewsCard from 'components/NewsCard/NewsCard';
import { HeadLine } from 'components/TextHeader/HeadLine';

const newses: Content[] = [
  { id: '1', topic: 'SAZKA eLEAGUE - Season 1 - Winter', imagePath: '/images/rov-260x168.png', desc: '', date: new Date(), tag: 'การแข่งขัน' },
  { id: '2', topic: 'Asia Pacific Predator League 2022 Grand Finals', imagePath: '/images/rov-260x168.png', desc: '', date: new Date(), tag: 'การแข่งขัน' },
  { id: '3', topic: 'Asia Pacific Predator League 2022 Grand Finals', imagePath: '/images/rov-260x168.png', desc: '', date: new Date(), tag: 'การแข่งขัน' },
];

export function News() {
  return (
    <div className='my-5'>
      <HeadLine title="ข่าวสารทั้งหมด" />
      <Row>
        {Array.from(Array(3)).map((_, i) => (
          <Col key={i} className='mb-4' xs={12} lg={6}>
            <NewsCard data={newses[i]} />
          </Col>
        ))}
      </Row>
    </div>
  );
}