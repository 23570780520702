import { AssociationMatchType } from "./associationTournament";

// #region Request
export interface IAssociationTournamentMatchesCreateRequest {
  matchStartDate: string;
  matchEndDate: string;
  matchTeams: IAssociationTournamentCompetitor[];
  status: TournamentMatchStatus;
  images: string[];
  modifyBy: string;
}
// #endregion

// #region Response
export interface IAssociationTournamentMatchesResponse {
  id: string;
  tournamentId: string;
  matchRound: number;
  matchStartDate: string;
  matchEndDate: string;
  competitor: IAssociationTournamentCompetitor[];
  status: TournamentMatchStatus;
  images: string[];
  videos: string[];
  createdDate: string;
  updatedDate?: string;
}

export interface IAssociationTournamentCompetitor {
  competitorIdentifier: string;
  point: number;
  isWinner: boolean;
  name?: string;
  image?: string;
  matchType?: string;
}
// #endregion

// #region Enum

export enum TournamentMatchStatus {
  Pending = "Pending",
  Started = "Started",
  Rejected = "Rejected",
  Ended = "Ended",
}
// #endregion