import { Card, Col, Row, Image, Spinner } from 'react-bootstrap';
import countries from 'data/json/countries.json';
import { useCallback, useEffect, useState } from 'react';
import { firebase } from 'services';
import { FaUser } from 'react-icons/fa';

interface Props {
  img: string;
  fullName: string;
  nickname?: string;
  nationality?: string;
  club?: { img: string; name: string };
  footer?: JSX.Element;
  onClick?: () => void;
}

export function InfoCard(props: Props) {
  const [img, setImg] = useState<string>();
  const [alt, setAlt] = useState<string>();

  useEffect(() => {
    getImageAsync();
  }, [props.img]);

  const onClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  const getCountyName = (code: string) => {
    const county = countries.find(f => f.code === code);

    return county?.name;
  };

  const getImageAsync = useCallback(async () => {
    if (props.img) {
      const imgUrl = await firebase.getImageAsync(props.img);

      setImg(imgUrl);
      setAlt(props.img);
    }
  }, [props.img]);

  return (
    <Card
      onClick={onClick}
      className='custom-info-card'>
      <div className='header'>
        <Row className='my-3'>
          <Col className='img d-flex justify-content-center align-items-center'>
            {props.img ?
              img ?
                <Image alt={alt} src={img} /> :
                <Spinner animation='border' variant='primary' />
              : <FaUser />}
          </Col>
        </Row>
        <Row>
          <Col className='text-center'>
            <h5>{props.fullName}</h5>
          </Col>
        </Row>
        <Row>
          <Col className='text-center text-muted'>
            <h6>{props.nickname}</h6>
          </Col>
        </Row>
      </div>
      {props.nationality || props.club ?
        <div className='body border-top'>
          <Row>
            {props.nationality ?
              <Col>
                <div className='nationality'>
                  <span className={`fp fp-rounded ${props.nationality.toLocaleLowerCase()}`} /> {getCountyName(props.nationality)}
                </div>
              </Col> : null}
            {props.club ?
              <Col>
                <div className='club'>
                  <Image src={props.club.img} /> {props.club.name}
                </div>
              </Col> : null}
          </Row>
        </div> : null}
      {props.footer ?
        <div className='footer'>
          {props.footer}
        </div> : null}
    </Card >
  );
}