import { useAppDispatch } from 'hooks';
import { Button, Row, Col, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { Form, Input, Checkbox, ModalType, Modal } from 'components';
import { set, HttpStatusCode } from 'utils';
import { account, email } from 'services';
import toast from 'utils/toast';

interface Props {
  show: boolean;
  onClose: () => void;
  next: (value: ModalType) => void;
}

export default function CheckExistEmailModal(props: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['modal', 'privacyPolicy', 'termsOfService'], { keyPrefix: 'register.checkExistEmail' });
  const [isDisabled, setIsDisabled] = useState(false);
  const formRules = {
    email: { required: true },
    termsAndConditions: {
      required: {
        value: true,
        message: 'กรุณายอมรับข้อกำหนดและเงื่อนไข',
      },
    },
  };

  const submitHandler = useCallback(async (data: { email: string }) => {
    setIsDisabled(true);

    const response = await account.checkExistEmailAsync(data.email);

    if (!response.data) {
      const sendEmailResponse = await email.verifyAsync(data.email);

      if (sendEmailResponse.status === HttpStatusCode.OK) {
        dispatch(set([
          { key: 'email', data: data.email },
          { key: 'otpRef', data: sendEmailResponse.data },
        ]));

        props.next(ModalType.VERIFY_OTP);
      }
    } else {
      toast.error(t('thisEmailIsUsed'));
    }

    setIsDisabled(false);
  }, []);

  return (
    <Modal show={props.show} closeButton onClose={() => props.onClose()}>
      <Modal.Body>
        <div className='title'>
          <h4>
            {t('register')}
          </h4>
        </div>
        <div className='content'>
          <Form onSubmit={submitHandler} rules={formRules}>
            <Row>
              <Col>
                <Input label={t('email')} name='email' />
              </Col>
            </Row>
            <Row>
              <Col className='mb-3'>
                <Checkbox name='termsAndConditions'>
                  <>
                    {t('acceptTheTermsAndConditions')} <a href='/termsOfService' target='_blank' rel="noopener noreferrer" className='pointer'>เงื่อนไขและข้อตกลงการใช้บริการ</a>
                    <p className='m-0'>
                      และ <a href='/privacyPolicy' target='_blank' rel="noopener noreferrer" className='pointer'>นโยบายคุ้มครองข้อมูลส่วนบุคคล</a>
                    </p>
                  </>
                </Checkbox>
              </Col>
            </Row>
            <Row>
              <Col className='d-grid'>
                <Button
                  className='btn-login'
                  variant='primary'
                  type='submit'
                  disabled={isDisabled}>
                  {t('sendOTP')}
                  {isDisabled ?
                    <Spinner
                      className='ms-1'
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true' /> : null}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}