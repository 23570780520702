import { useForm, useAppSelector } from 'hooks';
import { Button, Row, Col, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Form, Input, Modal, Select } from 'components';
import { Register, shareValue } from 'models';
import { firebase, account } from 'services';
import { HttpStatusCode } from 'utils';
import { ShareValueGroup } from 'utils/constants/shareValue';
import toast from 'utils/toast';
import shareValueService from 'services/shareValue';

interface Props {
  show: boolean;
  onClose: () => void;
}

export default function RegisterForm(props: Props) {
  const form = useForm();
  const { t } = useTranslation(['modal', 'form']);
  const mounted = useRef(false);
  const store = useAppSelector((state) => state.commonStore);
  const [isDisabled, setIsDisabled] = useState(false);
  const [memberTypeItems, setMemberTypeItems] = useState<shareValue[]>([]);
  const formRules = {
    firstName: { required: true },
    lastName: { required: true },
    password: { required: true, minLength: 8 },
    confirmPassword: {
      required: true,
      matching: { value: 'password', message: t('form:passwordDoNotMatch') },
    },
    memberType: { required: true },
  };

  const createForm = useCallback(() => {
    form.create({

    });
  }, [form]);

  useEffect(() => {
    if (!mounted.current) {
      createForm();
    }

    return () => {
      mounted.current = true;
    };
  }, [createForm]);

  useEffect(() => {
    getMemberTypeAsync();
  }, []);

  const submitHandler = useCallback(async (data: Register) => {
    setIsDisabled(true);

    await firebase.createUserWithEmailAndPasswordAsync(store.email, data.password)
      .then(async responseCreated => {
        const response = await account.registerAsync({
          ...data,
          id: responseCreated.user.uid,
          email: store.email,
        });

        if (response.status !== HttpStatusCode.CREATED) {
          await firebase.deleteUserAsync();

          toast.error(t('register.registerForm.signUpWasNotSuccessful'));
        } else {
          toast.success(t('register.registerForm.signUpWasSuccessful'));
          toast.success(t('register.registerForm.signInWasSuccessful'));
        }

        props.onClose();
      })
      .finally(() => setIsDisabled(false));
  }, [store]);

  const getMemberTypeAsync = async () => {
    const { data, status } = await shareValueService.getShareValueListAsync(ShareValueGroup.MemberType);

    if (status === HttpStatusCode.OK) {
      setMemberTypeItems(data);
    }
  };

  return (
    <Modal show={props.show} closeButton onClose={() => props.onClose()}>
      <Modal.Body>
        <div className='title'>
          <h4>
            {t('register.registerForm.register')}
          </h4>
        </div>
        <div className='content'>
          <Form onSubmit={submitHandler} rules={formRules}>
            <Row>
              <Col>
                <Select
                  label={t('register.registerForm.memberType')}
                  name='memberType'
                  items={memberTypeItems} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input label={t('register.registerForm.firstName')} name='firstName' />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input label={t('register.registerForm.lastName')} name='lastName' />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  label={t('register.registerForm.password')}
                  name='password'
                  type='password' />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  label={t('register.registerForm.confirmPassword')}
                  name='confirmPassword'
                  type='password' />
              </Col>
            </Row>
            <Row>
              <Col className='d-grid'>
                <Button
                  className='btn-login'
                  variant='outline-primary'
                  type='submit'
                  disabled={isDisabled}>
                  {t('register.registerForm.register')}
                  {isDisabled ?
                    <Spinner
                      className='ms-1'
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true' /> : null}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}