import { Button } from "components/Button";
import { Input } from "components/Controls";
import { Form } from "components/Form";
import { Modal } from "components/Modal";
import { useState } from "react";
import { firebase } from "services";
import { AuthenticationError } from "utils";
import toast from "utils/toast";

export default function ModalForgotPassword(props: { show: boolean, onHide: () => void }) {
  const [email, setEmail] = useState("");

  const formRules = {
    email: { required: true },
  };

  const onSubmit = () => {
    sendEmailToResetPasswordAsync();
  };

  const sendEmailToResetPasswordAsync = async () => {
    await firebase.resetPasswordAsync(email)
      .then(() => {
        toast.success('กรุณาตรวจสอบอีเมลเพื่อทำการเปลี่ยนรหัสผ่าน');

        onHideModal();
      })
      .catch(error => {
        switch (error.code) {
          case AuthenticationError.USER_NOT_FOUND:
            toast.error('ไม่พบอีเมลนี้ในระบบ');
            break;
          case AuthenticationError.INVALID_EMAIL:
            toast.error('อีเมลไม่ถูกต้อง');
            break;
          case AuthenticationError.WRONG_PASSWORD:
            toast.error('รหัสผ่านผิด');
            break;
          default:
        }
      })
  };

  const onHideModal = () => {
    setEmail("");
    props.onHide();
  };

  return (
    <Modal show={props.show} closeButton onClose={onHideModal}>
      <Modal.Body>
        <div className='title'>
          <h4>
            ลืมรหัสผ่าน
          </h4>
        </div>
        <Form onSubmit={onSubmit} rules={formRules}>
          <Input label="กรอก Email" name='email' placeholder='example@xxx.com' value={email} onChange={(value) => setEmail(value)} />
          <div className="d-flex justify-content-end">
            <Button type="submit" variant='primary'>
              ยืนยัน
            </Button>
          </div>
        </Form>
      </Modal.Body>

    </Modal>
  )
}