import { Col, Row } from 'react-bootstrap';
import { BackgroundImage } from 'components/BackgroundImage';
import { RewardMoney, Stepper, BoxDetailReward } from 'components';
import { useLoaderData } from "react-router-dom";
import { TournamentLoader } from 'EventLayout';

export function Events() {
  const loader = useLoaderData() as TournamentLoader;

  const sumMoney = function () {
    let total = 0;
    if (loader.tournament.associationTournamentRewards) {
      for (let i = 0; i < loader.tournament.associationTournamentRewards.length; i++) {
        total = total + loader.tournament.associationTournamentRewards[i].prize;
      }
      return total;
    }
    return total;
  };

  return (
    <div className='event'>
      <BackgroundImage src={loader.tournament.imagePath ? loader.tournament.imagePath : `/images/rov-1440x439.png`} />
      <div className='title-event'>
        <p className='fs-h1 text-gradient-light text-uppercase mb-0 text-center'>{loader.tournament.name}</p>
        <p className='fs-h4 text-gradient-light text-uppercase mb-0 text-center'>{loader.tournament.game.fullName}</p>
      </div>
      <RewardMoney totalMoney={sumMoney()} />
      <p className='fs-4 text-center mb-0 mt-sm-4'>รายละเอียดการแข่งขัน</p>
      <p className='fs-5 text-spacing text-center text-uppercase mb-5'>COMPETITION DETAIL</p>
      {loader.tournament.description ? (
        <div dangerouslySetInnerHTML={{ __html: (`${loader.tournament.description}`) }}></div>
      ) : null}
      <Stepper
        competitionEndDate={loader.tournament.competitionEndDate}
        competitionStartDate={loader.tournament.competitionStartDate}
        endRegistrationDate={loader.tournament.endRegistrationDate}
        startRegistrationDate={loader.tournament.startRegistrationDate}
        IClassName='my-5' />
      <Row className='justify-content-center my-5'>
        <Col xs={12} lg={5}>
          <p className='text-primary text-center fs-6'>สถานที่แข่งขัน</p>
          <p className='text-center fs-5 text-white'>{loader.tournament.location}</p>
          <p className='text-center fs-3 text-white'>รางวัล</p>
          <p className='text-spacing text-center text-uppercase fs-3 text-white'>REWARDS</p>
        </Col>
      </Row>
      <BoxDetailReward
        typeTeams={loader.tournament.associationMatchType}
        IReward={loader.tournament.associationTournamentRewards!}
        noNumTeams={loader.tournament.teamAmount}
        numTeams={loader.tournament.players}
        imgProfile={loader.tournament.imagePath}
      />
      <p className='fs-4 text-center mb-0 mt-5'>คุณสมบัติผู้แข่งขัน</p>
      <p className='fs-5 text-spacing text-center text-uppercase mb-5'>QUALIFICATION</p>
      {loader.tournament.rule ? (
        <div dangerouslySetInnerHTML={{ __html: (`${loader.tournament.rule}`) }}></div>
      ) : null}
    </div>
  );
}