import { FaTiktok } from 'react-icons/fa';

interface Props {
  size?: 'sm' | 'lg';
  border?: boolean;
  canHover?: boolean;
}

export default function TikTok({ size, border, canHover }: Props) {
  const getSize = () => {
    if (size === 'sm') {
      return 'fs-6';
    } else if (size === 'lg') {
      return 'fs-4';
    } else {
      return 'fs-5';
    }
  };

  return (
    <div className={`icon${border ? '-border' : ''} tiktok ${canHover ? 'can-hover' : ''}`}>
      <FaTiktok className={`${getSize()}`} />
    </div>
  );
}