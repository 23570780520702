import { Card, Pagination as PaginationComponent, TextHeader } from 'components';
import { Image, Col, Row, Spinner } from 'react-bootstrap';
import { Pagination, GamePlayedWithPagination, User } from 'models';
import { firebase, game } from 'services';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { HttpStatusCode, set } from 'utils';
import { useLoaderData } from 'react-router-dom';

export default function GamePlayed() {
  const data = useAppSelector((state) =>
    state.commonStore.gamesPlayedWithPagination as Pagination<GamePlayedWithPagination>);
  const loader = useLoaderData() as { user: User };
  const dispatch = useAppDispatch();

  const handlerPaginationOnChange = useCallback(async (value: number) => {
    await getGamesPlayedWithPaginationAsync(value);
  }, []);

  const getGamesPlayedWithPaginationAsync = useCallback(async (page?: number) => {
    const response = await game.getGamesPlayedWithPaginationAsync(loader.user.id, page ?? 1);

    if (response.status === HttpStatusCode.OK) {
      dispatch(set({ key: 'gamesPlayedWithPagination', data: response.data }));
    }
  }, []);

  return (
    <div className='game'>
      <TextHeader
        text='เกม'
        underline='center'
        size='sm' />
      <Row>
        {data?.rows.map((item, i) =>
          <Col key={i} xs={4} className={`${i < 3 ? 'mb-3' : ''}`}>
            <Item data={item} />
          </Col>)}
      </Row>
      {data?.totalRows > 6 ?
        <Row className='mt-3'>
          <Col>
            <div className='d-flex justify-content-center'>
              <PaginationComponent
                totalItems={data?.totalRows}
                totalItemsPerPage={6}
                onChange={handlerPaginationOnChange} />
            </div>
          </Col>
        </Row> : null}
    </div>
  );
}

function Item({ data }: {
  data: GamePlayedWithPagination,
}) {
  const [img, setImg] = useState<string>();
  const [alt, setAlt] = useState<string>();

  useEffect(() => {
    if (data !== undefined && data.img) {
      getImageAsync(data);
    }
  }, [data]);

  const getImageAsync = useCallback(async (data: GamePlayedWithPagination) => {
    const imgUrl = await firebase.getImageAsync(data.img);

    setImg(imgUrl);
    setAlt(data.img);
  }, []);

  return (
    <Card>
      <p className='mb-0 text-center'>{data.startingYear} - {data.endingYear}</p>
      <div className='d-flex justify-content-center'>
        {img ?
          <Image alt={alt} className='mt-2' height={60} src={img} width={60} /> :
          <Spinner animation='border' variant='primary' className='mt-2' />}
      </div>
    </Card>
  );
}