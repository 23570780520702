import { IPaginationResponse, TournamentTeamsCandidate } from 'models';
import http from 'hooks/useHttp';
import { IAssociationTournamentMatchesResponse } from 'models/associationTournamentMatch';

const getAssociationTournamentMatchIdAsync = async (tournamentId: string, id: string) => {
	return await http.get<IAssociationTournamentMatchesResponse>(`api/association/tournament/${tournamentId}/match/${id}`);
}

const getAssociationTournamentMatchByTournamentIdAsync = async (
	tournamentId: string,
	page: number,
	size: number) => {

	const params = {
		page,
		size
	};

	return await http.get<IPaginationResponse<IAssociationTournamentMatchesResponse[]>>(`/api/association/tournament/${tournamentId}/match`, { params });
};

const getAssociationTournamentMatch = async (
	page: number,
	size: number) => {
	const params = {
		page,
		size
	};
	return await http.get<IPaginationResponse<IAssociationTournamentMatchesResponse[]>>(`/api/association/tournament/match`, { params });
};

const getAssociationTournamentTeamsCandidate = async (
	tournamentId: string) => {
	return await http.get<TournamentTeamsCandidate[]>(`/api/association/tournament/teams/${tournamentId}/candidate`);
};

const getAssociationTournamentTeamsCandidateCountAsync = async (
	tournamentId: string) => {
	return await http.get<{ teamCount: number, playerCount: number }>(`/api/association/tournament/teams/${tournamentId}/candidate/count`);
};

export default {
	getAssociationTournamentMatchIdAsync,
	getAssociationTournamentMatchByTournamentIdAsync,
	getAssociationTournamentMatch,
	getAssociationTournamentTeamsCandidate,
	getAssociationTournamentTeamsCandidateCountAsync
};