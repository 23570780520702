import { TournamentStatusType } from 'utils';
import { MdFiberManualRecord } from "react-icons/md";
import { TournamentMatchStatus } from 'models/associationTournamentMatch';

interface Props {
  status: string;
}

export const EventStatus = ({ status }: Props) => {
  switch (status) {
    case TournamentStatusType.CURRENTLY:
      return (
        <span className='badge-status bg-warning'>
          <MdFiberManualRecord className='me-1 text-warning' /> {TournamentStatusType.CURRENTLY}
        </span>
      );
    case TournamentStatusType.OPENINGS:
      return (
        <span className='badge-status bg-success'>
          <MdFiberManualRecord className='me-1 text-success' /> {TournamentStatusType.OPENINGS}
        </span>
      );
    case TournamentStatusType.CLOSE:
      return (
        <span className='badge-status bg-primary'>
          <MdFiberManualRecord className='me-1 text-primary' /> {TournamentStatusType.CLOSE}
        </span>
      );
    default:
      return (
        <span className='badge-status bg-primary'>
          <MdFiberManualRecord className='me-1 text-primary' /> {TournamentStatusType.SUCCESS}
        </span>
      );
  }
};

export const EventMatchStatus = ({ status }: Props) => {
  switch (status) {
    case TournamentMatchStatus.Pending:
      return (
        <span className='badge-status bg-success'>
          <MdFiberManualRecord className='me-1 text-success' /> รอแข่งขัน
        </span>
      );
    case TournamentMatchStatus.Started:
      return (
        <span className='badge-status bg-warning'>
          <MdFiberManualRecord className='me-1 text-warning' /> อยู่ในช่วงแข่งขัน
        </span>
      );
    case TournamentMatchStatus.Rejected:
      return (
        <span className='badge-status bg-danger'>
          <MdFiberManualRecord className='me-1 text-danger' /> ยกเลิกการแข่งขัน
        </span>
      );
    case TournamentMatchStatus.Ended:
      return (
        <span className='badge-status bg-danger'>
          <MdFiberManualRecord className='me-1 text-danger' /> สิ้นสุดการแข่งขัน
        </span>
      );
    default:
      return null;
  }
};