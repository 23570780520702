import DatePicker, { CalendarContainer, CalendarContainerProps } from 'react-datepicker';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { CustomValidate, Rules, useValidate, ValidateResult, ValidateType } from 'hooks';
import { connect } from 'react-redux';
import { FormState } from 'utils';
import { Event, EventType } from 'utils/stores/formStore';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  label?: string;
  name: string;
  id?: string;
  placeholder?: string;
  value?: string | Date;
  disabled?: boolean;
  onChange?: (value: string) => void;

  // props from store
  rules: Rules;
  event: Event | undefined;
}

function Control(props: Props) {
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [value, setValue] = useState<string>('');
  const [valueHidden, setValueHidden] = useState<string>('');
  const [rule, setRule] = useState<ValidateType | null>();
  const [validateResult, setValidateResult] = useState<ValidateResult>();
  const validate = useValidate();

  useEffect(() => {
    if (props.rules) {
      setRule(props.rules[props.name]);
    }
  }, [props.rules]);

  useEffect(() => {
    if (props.event?.type === EventType.ON_SUBMIT) {
      handlerOnSubmit();
    }
  }, [props.event]);

  useEffect(() => {
    if (props.value) {
      const splits = props.value.toString().split('-');

      setSelectedDate(new Date(+splits[2], +splits[1] - 1, +splits[0]));
      setValue(props.value.toString());
      setValueHidden(props.value.toString());
    }
  }, [props.value]);

  const isRequired = useMemo(() => {
    if (props.rules && props.name) {
      const rule = props.rules[props.name];

      if (rule) {
        if (typeof rule.required === typeof {}) {
          return (rule.required as CustomValidate).value;
        }

        return rule.required ?? false;
      }
    }

    return false;
  }, [props.rules, props.name]);

  const handlerOnChange = (date: Date) => {
    const value = `${('0' + date.getDate()).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;

    setValue(value);
    setValueHidden(value);

    onValidate(value);

    if (props.onChange) {
      props.onChange(value);
    }
  };

  const onValidate = useCallback((value: string | number) => {
    const result = validate.process(value, rule);

    setValidateResult(result);
  }, [rule]);

  const handlerOnSubmit = useCallback(() => {
    onValidate(value);
  }, [value, rule]);

  const container = ({ className, children }: CalendarContainerProps) => {
    return (
      <CalendarContainer className={className}>
        {children}
      </CalendarContainer>
    );
  };

  return (
    <div className='date-picker mb-3'>
      <Form.Label>
        {props.label}
        {isRequired ? <span className='text-danger'> *</span> : null}
      </Form.Label>
      <InputGroup
        hasValidation>
        <DatePicker
          value={value}
          className={`${validateResult?.hasError ? 'is-invalid' : ''}`}
          customInput={<Form.Control />}
          selected={selectedDate}
          calendarContainer={container}
          locale={th}
          showMonthDropdown
          showYearDropdown
          dropdownMode='select'
          dateFormat='dd/MM/yyyy'
          onChange={(date: Date) => (handlerOnChange(date), setSelectedDate(date))}
          id={props.id}
          placeholderText={props.placeholder}
          disabled={props.disabled} />
        <input type='hidden' value={valueHidden} name={props.name} className='control' />
        <div className='date-picker-invalid-feedback'>
          <span className={`${validateResult?.hasError ? 'message-is-showed' : 'message'}`} >
            {validateResult?.message}
          </span>
        </div>
      </InputGroup>
    </div>
  );
}

const mapStateToProps = (state: FormState) => state.formStore;

const InputDatePicker = connect(mapStateToProps)(Control);

export default InputDatePicker;