import { setImage as setImageStore, setText as setTextStore } from '../utils/stores/backgroundStore';
import { useAppDispatch, useAppSelector } from './redux';

export function useBackground() {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.backgroundStore);

  const image = data.image;

  const text = data.text;

  const setImage = (value: string) => dispatch(setImageStore(value));

  const setText = (value: string) => dispatch(setTextStore(value));

  return {
    image,
    text,
    setImage,
    setText,
  };
}