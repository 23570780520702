import { Col, Row, Image } from "react-bootstrap";
import { IAssociationTournamentReward } from 'models';

interface TypesReward {
  name: string;
  money: number;
}

interface Props {
  typeTeams: string;
  numTeams: number;
  noNumTeams: number;
  IClassName?: string;
  imgProfile?: string;
  IReward: IAssociationTournamentReward[];
}

export function BoxDetailReward({ IReward, typeTeams, numTeams, noNumTeams, IClassName, imgProfile }: Props) {
  const listItems = IReward.map((data, i) => {
    return (
      <Row key={i} className="align-items-center">
        <Col cols={12} className="py-1">
          <p className="text-end pe-5 mb-0"> {new Intl.NumberFormat().format(data.prize)}</p>
        </Col>
        <Col cols={12} className="py-1">
          <p className="text-start mb-0">อันดับ {data.rating}</p>
        </Col>
      </Row>
    );
  });
  return (
    <div className={`box-detail-reward ${IClassName && IClassName}`}>
      <Image alt='profile' src={imgProfile ? imgProfile : `/images/userdf.png`} className="img-profile" />
      <p className="fs-5">{typeTeams} </p>
      <p className="fs-5">จํานวน {noNumTeams} ทีม &nbsp;&nbsp; : &nbsp;&nbsp; ทีมละ {numTeams} คน</p>
      <div className="list-reward">
        {listItems}
      </div>
    </div>
  );
}