import { useFormDispatch, useFormSelector } from 'hooks/redux';
import { Rules } from 'hooks/useForm';
import { FormEvent, useCallback, useEffect, useRef } from 'react';
import { Form as FormBT } from 'react-bootstrap';
import { Provider } from 'react-redux';
import formConfigure from 'utils/stores/configure/formConfigure';
import { EventType, setEvent, setRules } from 'utils/stores/formStore';
import { useValidate } from 'hooks/useValidate';

type ControlType = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

type DataType = { [key: string]: any };

interface Props {
  name?: string;
  children: JSX.Element | JSX.Element[];
  onSubmit?: (data: any) => void;
  rules?: Rules;
}

export function Form(props: Props) {
  return (
    <Provider store={formConfigure}>
      <FormManagement {...props}>
        {props.children}
      </FormManagement>
    </Provider>
  );
}

function FormManagement(props: Props) {
  const ref = useRef({} as HTMLFormElement);
  const dispatch = useFormDispatch();
  const formStore = useFormSelector((state) => state.formStore);
  const validate = useValidate();

  useEffect(() => {
    if (props.rules) {
      dispatch(setRules(props.rules));
    }
  }, [props.rules, dispatch]);

  const submitHandler = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    dispatch(setEvent(EventType.ON_SUBMIT));

    let data = {} as DataType;
    const form = ref.current as HTMLFormElement;
    let controls: ControlType[] = [];
    controls = [...Array.from(form.querySelectorAll('input.control')) as HTMLInputElement[]];
    controls = [...controls, ...Array.from(form.querySelectorAll('select.control')) as HTMLSelectElement[]];
    controls = [...controls, ...Array.from(form.querySelectorAll('textarea.control')) as HTMLTextAreaElement[]];
    const result = validateControls(controls, formStore.rules);

    if (result.hasError) {
      return;
    }

    data = { ...result.data };

    const formList = (Array.from(form.querySelectorAll('div.form-list')) as HTMLDivElement[]);

    for (let i = 0; i < formList.length; i++) {
      const id = formList[i].id;
      let controls: ControlType[] = [];
      controls = [...Array.from(formList[i].querySelectorAll('input.control')) as HTMLInputElement[]];
      controls = [...controls, ...Array.from(formList[i].querySelectorAll('select.control')) as HTMLSelectElement[]];
      controls = [...controls, ...Array.from(formList[i].querySelectorAll('textarea.control')) as HTMLTextAreaElement[]];
      const result = validateControls(controls, formStore.rules);

      if (result.hasError) {
        return;
      }

      const previous = data[id];

      if (previous) {
        data = { ...data, [id]: [...data[id], result.data] };
      } else {
        data = { ...data, [id]: [result.data] };
      }
    }

    if (props.onSubmit) {
      props.onSubmit(data);
    }
  }, [formStore]);

  const validateControls = (controls: ControlType[], rules?: Rules) => {
    let data = {};

    for (let i = 0; i < controls.length; i++) {
      const type = controls[i].type;
      const value = type === 'checkbox' ? (controls[i] as HTMLInputElement).checked : controls[i].value;
      const name = controls[i].name;
      const rule = rules ? rules[name] : null;

      const result = validate.process(value, rule);

      if (result.hasError) {
        return { hasError: true, data: {} };
      }

      data = { ...data, [name]: value };
    }

    return { hasError: false, data: data };
  };

  return (
    <FormBT name={props.name} ref={ref} onSubmit={submitHandler}>
      {props.children}
    </FormBT>
  );
}

function List(props: {
  name: string;
  children: JSX.Element | JSX.Element[];
}) {
  return (
    <div id={props.name} className='form-list'>
      {props.children}
    </div>
  );
}

Form.List = List;