import { 
  AuthCredential, 
  createUserWithEmailAndPassword, 
  deleteUser, 
  EmailAuthProvider, 
  FacebookAuthProvider, 
  fetchSignInMethodsForEmail, 
  getAuth, 
  GoogleAuthProvider, 
  linkWithCredential, 
  signInWithEmailAndPassword, 
  signInWithPopup, 
  signOut, 
  SignInMethod, 
  User } from 'firebase/auth';
import { FirebaseError, FirebaseOptions, initializeApp } from 'firebase/app';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { sendPasswordResetEmail } from 'firebase/auth';
import Cookies from 'universal-cookie';
import toast from 'utils/toast';
import { AuthenticationError } from '../utils/constants/firebase';
import { UploadImageType } from 'utils/constants/uploadImageType';

type Provider = EmailAuthProvider | GoogleAuthProvider | FacebookAuthProvider;

const config: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const cookies = new Cookies();

const app = initializeApp(config);

const auth = getAuth(app);

const storage = getStorage(app);

auth.onAuthStateChanged(async (user) => {
  const token = await user?.getIdToken();

  cookies.set('token', token);
  cookies.set('userId', user?.uid);
  cookies.set('creationTime', user?.metadata.creationTime);
});

const signInWithEmailAndPasswordAsync = async (email: string, password: string) => {
  return await signInWithEmailAndPassword(auth, email, password);
};

const signInWithGoogleAsync = async (provider?: Provider) => {
  return await signInWithPopupAsync(provider ? provider : new GoogleAuthProvider());
};

const resetPasswordAsync = async (email: string) => {
  return await sendPasswordResetEmail(auth, email);
};

const signInWithFacebookAsync = async (provider?: Provider) => {
  return await signInWithPopupAsync(provider ? provider : new FacebookAuthProvider())
    .catch(async (error: FirebaseError) => {
      if (error.code === AuthenticationError.ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL) {
        const credential = FacebookAuthProvider.credentialFromError(error);

        if (credential) {
          const email = error.customData?.email as string;

          const methods = await fetchSignInMethodsForEmailAsync(email);

          if (methods.length > 0 && methods[0] === SignInMethod.GOOGLE) {
            const googleProvider = new GoogleAuthProvider();

            googleProvider.setCustomParameters({ login_hint: email });

            const result = await signInWithPopupAsync(googleProvider);

            return await linkWithCredentialAsync(result.user, credential);
          }
        }

        throw error;
      }

      throw error;
    });
};

const signInWithPopupAsync = async (provider: Provider) => {
  return await signInWithPopup(auth, provider);
};

const linkWithCredentialAsync = async (user: User, credential: AuthCredential) => {
  return await linkWithCredential(user, credential);
};

const fetchSignInMethodsForEmailAsync = async (email: string) => {
  return await fetchSignInMethodsForEmail(auth, email);
};

const signOutAsync = async () => {
  return await signOut(auth)
    .then(() => {
      toast.success('ออกจากระบบสำเร็จ');

      window.location.href = '/home';
    });
};

const createUserWithEmailAndPasswordAsync = async (email: string, password: string) => {
  return await createUserWithEmailAndPassword(auth, email, password);
};

const deleteUserAsync = async () => {
  if (auth.currentUser) {
    return await deleteUser(auth.currentUser);
  }
};

const uploadBytesAsync = async (file: File | Blob, fileName: string, type: UploadImageType) => {
  const storageRef = ref(storage, `${type}/${fileName}`);

  return await uploadBytes(storageRef, file);
};

const uploadUrlAsync = async (url: string, fileName: string, type: UploadImageType) => {
  return await fetch(url)
    .then(response => response.blob())
    .then(async (blob) => {
      await uploadBytesAsync(blob, fileName, type)
    });
};

const getImageAsync = async (fileName: string) => {
  const storageRef = ref(storage, fileName);

  return await getDownloadURL(storageRef);
};

export default {
  cookies,
  auth,
  storage,
  signInWithEmailAndPasswordAsync,
  signInWithFacebookAsync,
  signInWithGoogleAsync,
  signOutAsync,
  createUserWithEmailAndPasswordAsync,
  deleteUserAsync,
  uploadBytesAsync,
  uploadUrlAsync,
  getImageAsync,
  resetPasswordAsync,
};