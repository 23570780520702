import { Form, InputGroup } from 'react-bootstrap';
import { FormState } from 'utils/stores/configure/formConfigure';
import { connect } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { useValidate, ValidateResult, Rules, ValidateType } from 'hooks';
import { Event, EventType } from 'utils/stores/formStore';

interface Props {
  label?: string;
  name: string;
  id?: string;
  className?: string;
  checked?: boolean;
  children?: JSX.Element | JSX.Element[];
  onChange?: (value: boolean) => void;

  // props from store
  rules: Rules;
  event: Event | undefined;
}

function Control(props: Props) {
  const [checked, setChecked] = useState<boolean>(false);
  const validate = useValidate();
  const [rule, setRule] = useState<ValidateType | null>();
  const [validateResult, setValidateResult] = useState<ValidateResult>();

  useEffect(() => {
    if (props.rules) {
      setRule(props.rules[props.name]);
    }
  }, [props.rules]);

  useEffect(() => {
    if (props.event?.type === EventType.ON_SUBMIT) {
      handlerOnSubmit();
    }
  }, [props.event]);

  useEffect(() => {
    if (props.checked) {
      setChecked(props.checked);
    }
  }, [props.checked]);

  const onValidate = useCallback((value: boolean) => {
    const result = validate.process(value, rule);

    setValidateResult(result);
  }, [rule, props.name]);

  const handlerOnChange = useCallback((event: HTMLInputElement) => {
    const value = event.checked;

    setChecked(value);

    onValidate(value);

    if (props?.onChange) {
      props.onChange(value);
    }
  }, [onValidate]);

  const handlerOnSubmit = useCallback(() => {
    onValidate(checked);
  }, [checked, rule]);

  return (
    <div className={`checkbox mb-3 ${props.className ? props.className : ''}`}>
      <InputGroup
        hasValidation>
        <Form.Check>
          <Form.Check.Input
            checked={checked}
            type='checkbox'
            name={props.name}
            onChange={event => handlerOnChange(event.target as HTMLInputElement)}
            className={`control cursor-pointer ${validateResult?.hasError ? 'is-invalid' : ''}`} />
          {props.label ? <Form.Check.Label>{props.label}</Form.Check.Label> : null}
          {props.children ? <Form.Check.Label>{props.children}</Form.Check.Label> : null}
        </Form.Check>
        {validateResult?.hasError ?
          <div className='invalid-feedback'>
            {validateResult?.message}
          </div> : null}
      </InputGroup>
    </div>
  );
}

const mapStateToProps = (state: FormState) => state.formStore;

const Checkbox = connect(mapStateToProps)(Control);

export default Checkbox;