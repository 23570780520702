import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export function CookiePolicy() {
  const { t } = useTranslation(['cookie']);

  return (
    <Container fluid='sm' className='text-white cookie pb-5'>
      <p className='fs-3 text-center pt-5'>{t('title')}</p>
      <div className='mb-0 text-justify fw-light-12 fs-5'>
        <p><strong>Cookie คืออะไร?</strong></p>
        <p>เว็บไซต์นี้ใช้คุกกี้ซึ่งเป็นไฟล์หรือส่วนของข้อมูลเล็ก ๆ ที่ดาวน์โหลดมายังคอมพิวเตอร์ของคุณเพื่อปรับปรุงประสบการณ์ใช้งานของคุณ
          หน้านี้อธิบายข้อมูลที่คุกกี้เก็บรวบรวม วิธีที่เราใช้งานคุกกี้ และเหตุผลที่บางครั้งเราจำเป็นต้องเก็บคุกกี้เหล่านี้ นอกจากนี้เรายังจะอธิบายวิธีที่คุณสามารถป้องกันคุกกี้เหล่านี้ไม่ให้ถูกจัดเก็บ
          อย่างไรก็ตาม เมื่อคุณดำเนินการดังกล่าว อาจเป็นการลดระดับการใช้งานหรือ &rsquo;หยุด&rsquo; องค์ประกอบบางอย่างของการทำงานของเว็บไซต์นี้ <br /><br />
          สำหรับข้อมูลทั่วไปเพิ่มเติมเกี่ยวกับคุกกี้ คุณสามารถดูบทความ Wikipedia เกี่ยวกับ HTTP Cookies
        </p>
        <p><strong>เราใช้งานคุกกี้อย่างไร?</strong></p>
        <p>เราใช้คุกกี้ด้วยเหตุผลหลายประการตามรายละเอียดด้านล่าง ในปัจจุบันส่วนใหญ่ยังไม่มีตัวเลือกมาตรฐานอุตสาหกรรมสำหรับการปิดการใช้งานคุกกี้โดย
          ไม่ต้องปิดการใช้งานฟังก์ชั่นและฟีเจอร์สำหรับการใช้งานเว็บไซต์นี้อย่างสมบูรณ์ เราขอแนะนำให้คุณเปิดการใช้งานคุกกี้ไว้ทั้งหมด หากคุณไม่แน่ใจว่าต้องการ
          ใช้ฟีเจอร์เหล่านั้นหรือไม่
        </p>

        <p><strong>การปิดการใช้งานคุกกี้</strong></p>
        <p>คุณสามารถปิดการใช้งานคุกกี้ได้โดยปรับการตั้งค่าในเว็บเบราว์เซอร์ของคุณ (ดูตัวช่วยเหลือในเบราว์เซอร์ของคุณเพื่อทำสิ่งนี้) โปรดทราบว่าการปิด
          ใช้งานคุกกี้จะส่งผลต่อการทำงานของเว็บไซต์นี้และเว็บไซต์อื่น ๆ ที่คุณเข้าชม การปิดใช้งานคุกกี้จะส่งผลให้เกิดการปิดใช้งานฟังก์ชั่นและคุณสมบัติบางอย่าง
          ของเว็บไซต์นี้ด้วย ดังนั้นขอแนะนำให้คุณเปิดการใช้งานคุกกี้ไว้
        </p>

        <p><strong>คุกกี้ที่เราใช้งาน</strong></p>
        <p className='fs-6'><strong>คุกกี้ที่เกี่ยวข้องกับบัญชีของคุณ</strong></p>
        <p>หากคุณสร้างบัญชีกับเรา เราจะใช้คุกกี้สำหรับการจัดการกระบวนการสมัครสมาชิกและการจัดการข้อมูลบัญชีทั่วไป
          คุกกี้เหล่านี้จะถูกลบเมื่อคุณออกจากระบบ อย่างไรก็ตามในบางกรณีคุกกี้อาจยังคงอยู่หลังจากนั้น
          เพื่อจดจำการตั้งค่าเว็บไซต์ของคุณหลังคุณทำการออกจากระบบ
        </p>

        <p className='fs-6'><strong>คุกกี้ที่เกี่ยวข้องกับการลงชื่อเข้าใช้งานระบบ</strong></p>
        <p>เราใช้คุกกี้เวลาที่คุณได้ลงชื่อเข้าใช้งานระบบเพื่อสำหรับจดจำการเข้าใช้งานดังกล่าว สิ่งนี้ทำให้คุณไม่ต้อง
          ลงชื่อเข้าสู่ระบบทุกครั้งที่คุณเข้าชมหน้าลิงค์ใหม่
          โดยทั่วไปแล้วคุกกี้เหล่านี้จะถูกลบหรือล้างออกเมื่อคุณออกจากระบบเพื่อให้แน่ใจว่าคุณสามารถเข้าถึงฟีเจอร์และ
          บางส่วนของเว็บไซต์ที่ถูกจำกัดเฉพาะเมื่อคุณได้ทำการเข้าสู่ระบบแล้วเท่านั้น
        </p>

        <p className='fs-6'><strong>คุกกี้สำหรับการรับข่าวสารทางอีเมล์</strong></p>
        <p>เว็บไซต์นี้ให้บริการลงทะเบียนเพื่อรับข่าวสารทางอีเมล์หรือบริการสมัครสมาชิกทางอีเมล์ และคุกกี้อาจถูกใช้เพื่อจดจำว่าคุณได้
          ลงทะเบียนรับข่าวสารหรือบริการเหล่านั้นแล้ว และจะแสดงการแจ้งเตือนบางอย่างซึ่งจะแสดงสำหรับผู้ที่ได้ทำการลงทะเบียนรับข่าวสาร
          หรือสำหรับผู้ที่ไม่ได้ลงทะเบียนรับข่าวสารเท่านั้น
        </p>

        <p className='fs-6'><strong>คุกกี้ที่ใช้สำหรับการสั่งซื้อบัตรหรือสร้างออเดอร์</strong></p>
        <p>เว็บไซต์นี้มีส่วนของการสั่งซื้อ หรือสร้างออเดอร์ (อีคอมเมิร์ซ) และการชำระเงิน โดยที่มีการใช้คุกกี้เพื่อใช้จดจำคำสั่งซื้อของคุณ
          ระหว่างหน้าเว็บไซต์ในขั้นตอนต่างๆ เพื่อให้วีแบดจ์สามารถดำเนินการเหล่านั้นได้อย่างสมบูรณ์
        </p>

        <p className='fs-6'><strong>คุกกี้สำหรับฟีเจอร์ภายในงานอีเว้นท์ (แบบสอบถาม, ระบบถามตอบ, ระบบโหวต)</strong></p>
        <p>ในบางครั้งเรามีการสำรวจผู้ใช้งานและแบบสอบถาม การใช้ระบบถามตอบแบบเรียลไทม์ และ ระบบการโหวตแบบเรียลไทม์
          เพื่อให้คุณสามารถมีส่วนร่วมภายในงานอีเว้นท์ รวมทั้งเป็นการเก็บข้อมูลเชิงลึกที่น่าสนใจ เพื่อให้วีแบดจ์ สามารถปรับปรุงพัฒนา
          เครื่องมือที่เป็นประโยชน์ หรือเข้าใจฐานผู้ใช้ของเราอย่างดียิ่งขึ้น ฟีเจอร์เหล่านี้อาจใช้คุกกี้เพื่อจดจำว่าใครเป็นส่วนหนึ่งของ
          กิจกรรมหรือเพื่อให้ผลลัพธ์ที่ถูกต้องแก่คุณหลังจากที่คุณเปลี่ยนหน้าเว็บไซต์
        </p>

        <p className='fs-6'><strong>คุกกี้ที่เกี่ยวกับการกรอกแบบฟอร์ม</strong></p>
        <p>เมื่อคุณส่งข้อมูลทางแบบฟอร์ม เช่น แบบฟอร์มติดต่อ หรือแบบฟอร์มลงทะเบียน วีแบดจ์จะมีการใช้คุกกี้เพื่อตั้งค่าให้จดจำรายละเอียด
          ผู้ใช้งานของคุณสำหรับการติดต่อในอนาคต
        </p>

        <p className='fs-6'><strong>คุกกี้สำหรับระบุการตั้งค่าเว็บไซต์</strong></p>
        <p>เพื่อมอบประสบการณ์ที่ยอดเยี่ยมให้กับคุณในการใช้งานเว็บไซต์นี้ คุณสามารถทำการตั้งค่าในการใช้งานเว็บไซต์ได้ ทั้งนี้
          ในการจดจำการตั้งค่าของคุณเราจำเป็นต้องใช้คุกกี้เพื่อให้สามารถจดจำการตั้งค่าดังกล่าว เมื่อคุณเข้ามาใช้งานเว็บไซต์ของเรา
          ในหน้าที่เกี่ยวข้องกับการตั้งค่าดังกล่าว
        </p>
      </div>

      <p className='fs-3 pt-5'>คุกกี้ของบุคคลที่สาม </p>
      <div className='mb-0 text-justify fw-light-12 fs-5'>
        <p>ในบางกรณีเรายังใช้คุกกี้ที่จัดทำโดยบุคคลที่สามที่เชื่อถือได้ รายละเอียดส่วนต่อไปนี้คือคุกกี้ของบุคคลที่สามที่คุณอาจพบผ่านเว็บไซต์นี้</p>

        <p>เว็บไซต์นี้ใช้ Google Analytics ซึ่งเป็นหนึ่งในโซลูชันการวิเคราะห์เว็บไซต์ที่น่าเชื่อถือและแพร่หลายที่สุด เพื่อช่วยให้เราเข้าใจว่า
          คุณใช้เว็บไซต์ของเราอย่างไร รวมถึงวิธีที่เราสามารถปรับปรุงประสบการณ์ของคุณให้ดีขึ้นได้ คุกกี้เหล่านี้อาจติดตามสิ่งต่าง ๆ
          เช่นระยะเวลาที่คุณใช้จ่ายในเว็บไซต์และหน้าเว็บที่คุณเยี่ยมชมเพื่อให้เราสามารถจัดเตรียมเนื้อหาที่น่าสนใจและเหมาะสมต่อไป
        </p>
        <p>สำหรับข้อมูลเพิ่มเติมเกี่ยวกับคุกกี้ของ Google Analytics โปรดดูหน้าเว็บไซต์ทางการของ Google Analytics</p>

        <p>วีแบดจ์ มีการใช้ระบบการวิเคราะห์โดยบุคคลที่สาม ในการติดตามและวัดการใช้งานเว็บไซต์นี้เพื่อให้เราสามารถจัดเตรียม
          เนื้อหาที่น่าสนใจ คุกกี้เหล่านี้อาจติดตามสิ่งต่าง ๆ เช่นระยะเวลาที่คุณใช้จ่ายในเว็บไซต์และหน้าเว็บที่คุณเยี่ยมชมเพื่อให้
          เราสามารถจัดเตรียมเนื้อหาที่น่าสนใจและเหมาะสมต่อไป
        </p>

        <p>ในบางครั้ง เราจะทดสอบฟีเจอร์การใช้งานใหม่ และทำการเปลี่ยนแปลงเล็กน้อยกับวิธีการเข้าใช้งานเว็บไซต์ เมื่อขณะที่
          เรายังคงทดสอบฟีเจอร์ใหม่ ๆ คุกกี้เหล่านี้อาจถูกใช้เพื่อให้แน่ใจว่าคุณได้รับประสบการณ์ที่ไร้รอยต่อในขณะที่อยู่ในเว็บไซต์
          ขณะเดียวกันก็ทำให้แน่ใจว่าเราเข้าใจว่าการเพิ่มฟีเจอร์ใดที่ตอบโจทย์การใช้งานสำหรับผู้ใช้งานจริง
        </p>

        <p>เนื่องจากเราขายบัตรอีเว้นท์ และบริการอื่นๆที่เกี่ยวข้อง จึงเป็นสิ่งสำคัญที่เราจะต้องเข้าใจสถิติเกี่ยวกับจำนวนผู้เข้าชมเว็บไซต์
          ของเราที่ได้ทำการซื้อจริง และเป็นข้อมูลประเภทที่คุกกี้จะติดตาม อันเป็นสิ่งสำคัญสำหรับคุณ เนื่องจากเราสามารถคาดการณ์ผลลัพธ์
          ในการดำเนินธุรกิจได้อย่างแม่นยำ ซึ่งทำให้เราสามารถตรวจสอบต้นทุนการโฆษณาและผลิตภัณฑ์ของเราเพื่อให้แน่ใจว่าราคาที่ดีที่
          สุดสำหรับลูกค้าเช่นคุณ
        </p>

        <p>เราใช้โฆษณาเพื่อชดเชยค่าใช้จ่ายในการใช้เว็บไซต์นี้และเพื่อเป็นเงินทุนสำหรับการพัฒนาต่อไป คุกกี้โฆษณาเชิงพฤติกรรมที่ใช้โดย
          เว็บไซต์นี้ออกแบบมาเพื่อให้แน่ใจว่าเราให้บริการโฆษณาที่เกี่ยวข้องมากที่สุดเท่าที่จะทำได้ โดยการติดตามความสนใจของคุณโดยไม่ระบุตัวตน
          และนำเสนอสิ่งที่คล้ายกันซึ่งอาจเป็นที่คุณสนใจ
        </p>

        <p>นอกจากนี้เรายังใช้ปุ่มโซเชียลมีเดียและ / หรือปลั๊กอินในเว็บไซต์นี้ เพือให้คุณเชื่อมต่อกับเครือข่ายโซเชียลของคุณได้หลายวิธี
          เพื่อให้เว็บไซต์โซเชียลมีเดียดังต่อไปนี้รวมถึง; Facebook, Instagram, Twitter, Youtube, LINE จะตั้งค่าคุกกี้ผ่านเว็บไซต์ของเรา
          ซึ่งอาจใช้เพื่อปรับปรุงโปรไฟล์ของคุณในเว็บไซต์ของแพลตฟอร์มดังกล่าว หรือสนับสนุนข้อมูลที่พวกเขาเก็บไว้เพื่อวัตถุประสงค์ต่างๆ
          ที่ระบุไว้ในนโยบายความเป็นส่วนตัวของแพลตฟอร์มเหล่านั้น
        </p>
      </div>
    </Container>
  );
}