import { Modal } from 'components';
import { HttpStatusCode, set } from 'utils';
import { ModalSize } from 'utils';
import { TeamTournament, TournamentCondition, User } from 'models';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from 'hooks';
import Team from './Team';
import { tournament } from 'services';

interface Props {
  conditionId: string;
  tournamentId: string;
  teamTourId: string;
  registerTourId: string;
  show: boolean;
  userData: User;
  onHide: () => void;
  onHideAfterSave: () => void;
}

interface PlayerDetail {
  name: string;
  email: string;
  birthDate: string;
  gameName: string;
  user: User;
}

export default function EditEventsDetail(props: Props) {
  const [user, setUser] = useState<User>({} as User);
  const [playerList, setPlayerList] = useState<PlayerDetail[]>({} as PlayerDetail[]);
  const [conditionId, setConditionId] = useState(props.conditionId);
  const [tournamentId, setTournamentId] = useState(props.tournamentId);
  const [teamTourId, setTeamTourId] = useState(props.teamTourId);
  const [registerTourId, setRegisterTourId] = useState(props.registerTourId);
  const [teamTournament, setTeamTournament] = useState<TeamTournament>({} as TeamTournament);

  useEffect(() => {
    if(props.registerTourId){
      setRegisterTourId(props.registerTourId);
    }
    if(props.teamTourId){
      setTeamTourId(props.teamTourId);
    }    
    if(props.conditionId){
      setConditionId(props.conditionId);
    }
    if(props.tournamentId){
      setTournamentId(props.tournamentId);
    }
  }, [props]);

  const dispatch = useAppDispatch();

  const clearState = () => {
    dispatch(set({ key: 'teamName', data: "" }));
    dispatch(set({ key: 'teamTag', data: "" }));
    dispatch(set({ key: 'tel', data: "" }));
    dispatch(set({ key: 'lineId', data: "" }));
    dispatch(set({ key: 'logoImage', data: "" }));
  };

  const onHideTeam = () => {
    clearState();

    props.onHideAfterSave();
  };

  const getUserAsync = useCallback(async (userData: User) => {
    setUser(userData);
  }, []);

  const getPlayerListAsync = useCallback(async (id:string) => {
    const {data, status} = await tournament.getTeamTournamentByIdAsync(id);

    if (status === HttpStatusCode.OK) {
      setTeamTournament(pre => ({...pre, 
        teamName: data.name, 
        lineId: data.lineId, 
        logoImage: data.logoImage, 
        phone: data.phone, 
        teamTag: data.tag, 
        teamTournamentPlayer: data.teamTournamentPlayer}));
        const newList = [] as PlayerDetail[];

        data.teamTournamentPlayer.forEach((t: any) => {
          const gameName = t.gameName;
          const email = t.user.email;
          newList.push({birthDate: '', name: '', user: t.user, email, gameName});
        });

      setPlayerList(newList);
      return;
    }
  }, []);

  useEffect(() => {
    if (props.userData) {
      getUserAsync(props.userData);
    }

    if(props.teamTourId){
      getPlayerListAsync(props.teamTourId);
    }    
  }, [props.userData, props.conditionId]);

  return (
    <Modal size={ModalSize.LG} show={props.show} onClose={() => props.onHide()}>
      <Modal.Body>
        <Team         
          onHide={() => onHideTeam()}
          userData={user}
          conditionId={conditionId}
          tournamentId={tournamentId}
          teamTourId={teamTourId}
          teamTournament={teamTournament}
          registerTourId={registerTourId}
          playerList={playerList} />
      </Modal.Body>
    </Modal>
  );
}