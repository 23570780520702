import { converFullDate } from 'utils/converters/dateConverter';

interface Props {
  competitionEndDate: string
  competitionStartDate: string
  endRegistrationDate: string
  startRegistrationDate: string
  IClassName?: string
}

export function Stepper({ competitionEndDate, competitionStartDate, endRegistrationDate, startRegistrationDate, IClassName }: Props) {

  const endRegistrationDateWithHours = new Date(endRegistrationDate);
  const startRegistrationDateWithHours = new Date(startRegistrationDate);
  const competitionStartDateDateWithHours = new Date(competitionStartDate);
  const competitionEndDateDateWithHours = new Date(competitionEndDate);

  const todaysDate = new Date();
  todaysDate.setHours(0, 0, 0, 0);
  endRegistrationDateWithHours.setHours(0, 0, 0, 0);
  startRegistrationDateWithHours.setHours(0, 0, 0, 0);
  competitionStartDateDateWithHours.setHours(0, 0, 0, 0);
  competitionEndDateDateWithHours.setHours(0, 0, 0, 0);

  const activeDateStart = () => {
    if (todaysDate >= startRegistrationDateWithHours && todaysDate <= endRegistrationDateWithHours) {
      return 'active';
    }
    return '';
  };

  const activeDateTournament = () => {
    if (todaysDate >= competitionStartDateDateWithHours && todaysDate <= competitionEndDateDateWithHours) {
      return 'active';
    }
    return '';
  };

  const activeEndTournament = () => {
    if (todaysDate >= competitionEndDateDateWithHours) {
      return 'active';
    }
    return '';
  };

  return (
    <div className={`stepper-wrapper ${IClassName && IClassName}`}>
      <div className={`stepper-item ${activeDateStart()}`}>
        <div className="step-counter" />
        <div className="step-name"> เปิดรับสมัคร </div>
        <div className="step-date"> {converFullDate(startRegistrationDate, endRegistrationDate)} </div>
      </div>
      <div className={`stepper-item ${activeDateTournament()}`}>
        <div className="step-counter" />
        <div className="step-name"> อยู่ระหว่างแข่งขัน </div>
        <div className="step-date"> {converFullDate(competitionStartDate, competitionEndDate)} </div>
      </div>
      <div className={`stepper-item ${activeEndTournament()}`}>
        <div className="step-counter" />
        <div className="step-name"> จบการแข่งขัน </div>
        <div className="step-date"> {converFullDate(competitionEndDate)} </div>
      </div>
    </div>
  );
}