import { HeaderCard } from '../../../components/HeaderCard';
import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useBackground } from '../../../hooks/useBackground';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { Card } from '../../../components/Card';
import { BackgroundImage } from '../../../components/BackgroundImage';
import { useAppSelector } from '../../../hooks/redux';
import { useSearchParams } from 'react-router-dom';
import TextLogo from '../../../components/Background/TextLogo';

export function ContentDetail() {
  const hostUrl = `${window.location.protocol}//${window.location.host}`;
  const background = useBackground();
  const [searchParams] = useSearchParams();
  const textBagde = searchParams.get('textBagede');
  const id = searchParams.get('id');

  const data = useAppSelector((state) =>
    state.commonStore.tournamentDetail);

  const BREADCRUMB = [
    { label: 'หน้าแรก', path: '/home' },
    { label: 'ข่าวสาร', path: '/demo' },
  ];

  useEffect(() => {
    background.setImage(`${hostUrl}/images/rov-1440x439.png`);
  }, []);

  return (
    <>
      <BackgroundImage src={`${hostUrl}/images/rov-1440x439.png`} />
      <Breadcrumb className='my-5' menus={BREADCRUMB} />
      <Row className='mb-5'>
        <Col>
          <HeaderCard
            date={new Date()}
            image={`${hostUrl}/images/rov-1440x439.png`}
            tag={`${textBagde}`}
            topic={'news'} />
        </Col>
      </Row>
      <Row>
        <TextLogo text='PUBG' />
        <Col>
          <Card className='mb-5'>
            <div dangerouslySetInnerHTML={{ __html: '<></>' }}></div>
          </Card>
        </Col>
      </Row>
    </>
  );
}