interface Props {
  text: string;
}

export default function TextLogo({ text }: Props) {
  return (
    <>
      <div className='text-logo-left'>
        <span className='text' data-bg-text={text} />
        <span className='text-in' data-bg-text={text} />
      </div>
      <div className='text-logo-right'>
        <span className='text' data-bg-text={text} />
        <span className='text-in' data-bg-text={text} />
      </div>
    </>
  );
}