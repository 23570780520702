import { Input, Form, Button, Modal, ModalType, FaGoogle, FaFacebookF } from 'components';
import { Row, Col, Spinner } from 'react-bootstrap';
import { AuthenticationError } from 'utils';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import firebase from 'services/firebase';
import toast from 'utils/toast';
import account from 'services/account';
import { UserCredential } from 'firebase/auth';
import uuid from 'react-uuid';
import { UploadImageType } from 'utils/constants/uploadImageType';

interface FormData {
  email: string;
  password: string;
}

interface Props {
  show: boolean;
  onClose: () => void;
  next: (value: ModalType) => void;
  onForgotPassword?: () => void;
}

export default function LoginModal(props: Props) {
  const { t } = useTranslation('modal');
  const [isDisabled, setIsDisabled] = useState(false);
  const formRules = {
    email: { required: true },
    password: { required: true },
  };

  const submitHandler = useCallback(async (data: FormData) => {
    setIsDisabled(true);

    await firebase.signInWithEmailAndPasswordAsync(data.email, data.password)
      .then(async userCredential => {
        toast.success('เข้าสู่ระบบสำเร็จ');

        if (userCredential.user.email) {
          await account.checkExistEmailAsync(userCredential.user.email, userCredential.user.metadata.creationTime);
        }

        props.onClose();
      })
      .catch(error => {
        switch (error.code) {
          case AuthenticationError.USER_NOT_FOUND:
            toast.error('ไม่พบอีเมลนี้ในระบบ');
            break;
          case AuthenticationError.INVALID_EMAIL:
            toast.error('อีเมลไม่ถูกต้อง');
            break;
          case AuthenticationError.WRONG_PASSWORD:
            toast.error('รหัสผ่านผิด');
            break;
          default:
        }
      })
      .finally(() => setIsDisabled(false));
  }, []);

  const signInWithGoogleAsync = async () => {
    await firebase.signInWithGoogleAsync().then(async userCredential => {
      if (userCredential.user.email) {
        const response = await account.checkExistEmailAsync(userCredential.user.email, userCredential.user.metadata.creationTime);

        if (!response.data) {
          const imageName = uuid();

          if (userCredential.user.photoURL) {
            await firebase.uploadUrlAsync(userCredential.user.photoURL.replace('=s96-c', ''), imageName, UploadImageType.IMAGE_PROFILE);
          }

          await insertProfileAsync(userCredential, `${UploadImageType.IMAGE_PROFILE}/${imageName}`);
        }

        toast.success('เข้าสู่ระบบด้วย Google สำเร็จ');
        props.onClose();
      }
    });
  };

  const signInWithFacebookAsync = async () => {
    await firebase.signInWithFacebookAsync().then(async userCredential => {
      if (userCredential.user.email) {
        const response = await await account.checkExistEmailAsync(userCredential.user.email, userCredential.user.metadata.creationTime);

        if (!response.data) {
          const imageName = uuid();

          if (userCredential.user.photoURL) {
            await firebase.uploadUrlAsync(`${userCredential.user.photoURL}?height=5000`, imageName, UploadImageType.IMAGE_PROFILE);
          }

          await insertProfileAsync(userCredential, `${UploadImageType.IMAGE_PROFILE}/${imageName}`);
        }

        toast.success('เข้าสู่ระบบด้วย Facebook สำเร็จ');
        props.onClose();
      }
    });
  };

  const insertProfileAsync = async (userCredential: UserCredential, imageName: string) => {
    if (userCredential.user.email) {
      await account.registerAsync({
        id: userCredential.user.uid,
        email: userCredential.user.email,
        firstName: userCredential.user.displayName ?? '',
        lastName: '',
        password: '',
        img: `${imageName}`,
        memberType: 'player',
      });
    }
  };

  return (
    <Modal show={props.show} closeButton onClose={() => props.onClose()}>
      <div className='login-modal'>
        <Modal.Body>
          <div className='title'>
            <h4>
              {t('login.login')}
            </h4>
          </div>
          <div className='content'>
            <Form onSubmit={submitHandler} rules={formRules}>
              <Row>
                <Col>
                  <Input label={t('login.email')} name='email' />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input label={t('login.password')} name='password' type='password' />
                </Col>
              </Row>
              <Row>
                <Col className='d-flex justify-content-end mb-3'>
                  <Button variant='link' type='button' onClick={props.onForgotPassword}>
                    {t('login.forgotPassword')}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col className='d-grid'>
                  <Button
                    className='btn-login'
                    type='submit'
                    variant='primary'
                    disabled={isDisabled}>
                    <>
                      {t('login.login')}
                      {isDisabled ?
                        <Spinner
                          className='ms-1'
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true' /> : null}
                    </>
                  </Button>
                </Col>
              </Row>
            </Form>
            <Row>
              <Col className='section-line d-flex'>
                <div className='line-start border-light border-bottom border-primary flex-grow-1' />
                <div className='position-relative top-50 px-4'>
                  {t('login.or')}
                </div>
                <div className='line-end border-light border-bottom border-primary flex-grow-1' />
              </Col>
            </Row>
            <Row>
              <Col className='d-grid pe-sm-1 mb-2 mb-sm-0' sm={6} xs={12}>
                <Button variant='outline-primary' onClick={() => signInWithGoogleAsync()}>
                  <><FaGoogle size='sm' /><span className='ms-2'>{t('login.google')}</span></>
                </Button>
              </Col>
              <Col className='d-grid ps-sm-1' sm={6} xs={12}>
                <Button variant='primary' onClick={() => signInWithFacebookAsync()}>
                  <><FaFacebookF size='sm' /><span className='ms-2'>{t('login.facebook')}</span></>
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className='section-line d-flex'>
                <div className='line-start border-light border-bottom border-primary flex-grow-1' />
                <div className='position-relative top-50 px-4'>
                  {t('login.doNotYouHaveAnAccount')}
                </div>
                <div className='line-end border-light border-bottom border-primary flex-grow-1' />
              </Col>
            </Row>
            <Row>
              <Col className='d-grid'>
                <Button
                  className='btn-login'
                  variant='outline-primary'
                  onClick={() => props.next(ModalType.CHECK_EXIST_EMAIL)}>
                  {t('login.register')}
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
}