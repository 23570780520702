import { useValidate, Rules, ValidateType, ValidateResult } from 'hooks';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { FormState } from 'utils';
import { connect } from 'react-redux';
import { Event as EventStore, EventType } from 'utils/stores/formStore';

interface Props {
  name: string;
  onChange?: (value: string) => void;

  // props from store
  rules: Rules;
  event: EventStore | undefined;
}

interface Otp {
  one: string;
  two: string;
  three: string;
  four: string;
  five: string;
  six: string;
}

function Control(props: Props) {
  const [otp, setOtp] = useState<Otp>({ one: '', two: '', three: '', four: '', five: '', six: '' });
  const [value, setValue] = useState<string | number>('');
  const validate = useValidate();
  const [rule, setRule] = useState<ValidateType | null>();
  const [validateResultOne, setValidateResultOne] = useState<ValidateResult>();
  const [validateResultTwo, setValidateResultTwo] = useState<ValidateResult>();
  const [validateResultThree, setValidateResultThree] = useState<ValidateResult>();
  const [validateResultFour, setValidateResultFour] = useState<ValidateResult>();
  const [validateResultFive, setValidateResultFive] = useState<ValidateResult>();
  const [validateResultSix, setValidateResultSix] = useState<ValidateResult>();
  const oneRef = useRef({} as HTMLInputElement);
  const twoRef = useRef({} as HTMLInputElement);
  const threeRef = useRef({} as HTMLInputElement);
  const fourRef = useRef({} as HTMLInputElement);
  const fiveRef = useRef({} as HTMLInputElement);
  const sixRef = useRef({} as HTMLInputElement);

  useEffect(() => {
    if (props.rules) {
      setRule(props.rules[props.name]);
    }
  }, [props.rules]);

  useEffect(() => {
    if (props.event?.type === EventType.ON_SUBMIT) {
      handlerOnSubmit();
    }
  }, [props.event]);

  const handlerOnChange = (event: Event) => {
    const inputEvent = event as InputEvent;
    const input = event.target as HTMLInputElement;
    let data = {} as Otp;

    if (inputEvent.data) {
      const matched = /^[0-9]+$/.test(inputEvent.data);
      const value = matched ? inputEvent.data : '';

      data = { ...otp, [input.id]: value };

      if (value) {
        if (input.tabIndex < 5) {
          const nextTabIndex = input.tabIndex + 1;
          const nextInput = input.form?.elements[nextTabIndex] as HTMLInputElement;

          nextInput.focus();
        }
      }
    } else {
      data = { ...otp, [input.id]: '' };

      if (!otp[input.id as keyof Otp] && input.tabIndex > 0) {
        const nextTabIndex = input.tabIndex - 1;
        const nextInput = input.form?.elements[nextTabIndex] as HTMLInputElement;

        nextInput.focus();
      }
    }

    setOtp(data);

    const valueCombined = `${data.one}${data.two}${data.three}${data.four}${data.five}${data.six}`;

    setValue(valueCombined)

    onValidate(input.id, input.value);

    if (props.onChange) {
      props.onChange(valueCombined);
    }
  };

  const getRef = (name: string) => {
    switch (name) {
      case 'one': return oneRef;
      case 'two': return twoRef;
      case 'three': return threeRef;
      case 'four': return fourRef;
      case 'five': return fiveRef;
      case 'six': return sixRef;
      default: return {} as MutableRefObject<HTMLInputElement>;
    }
  };

  const onValidate = useCallback((id: string, value: string | number) => {
    const result = validate.process(value, rule);

    switch (id) {
      case 'one': setValidateResultOne(result); break;
      case 'two': setValidateResultTwo(result); break;
      case 'three': setValidateResultThree(result); break;
      case 'four': setValidateResultFour(result); break;
      case 'five': setValidateResultFive(result); break;
      case 'six': setValidateResultSix(result); break;
    }
  }, [rule]);

  const handlerOnSubmit = useCallback(() => {
    onValidate('one', otp.one);
    onValidate('two', otp.two);
    onValidate('three', otp.three);
    onValidate('four', otp.four);
    onValidate('five', otp.five);
    onValidate('six', otp.six);
  }, [otp, rule]);

  const getMessageError = useMemo(() => {
    if (validateResultOne?.hasError) {
      return validateResultOne.message ?? '';
    }

    if (validateResultTwo?.hasError) {
      return validateResultTwo.message ?? '';
    }

    if (validateResultThree?.hasError) {
      return validateResultThree.message ?? '';
    }

    if (validateResultFour?.hasError) {
      return validateResultFour.message ?? '';
    }

    if (validateResultFive?.hasError) {
      return validateResultFive.message ?? '';
    }

    if (validateResultSix?.hasError) {
      return validateResultSix.message ?? '';
    }
  }, [
    validateResultOne,
    validateResultTwo,
    validateResultThree,
    validateResultFour,
    validateResultFive,
    validateResultSix,
  ]);

  return (
    <div className='otp-input mb-3'>
      <InputGroup
        hasValidation
        className='d-flex flex-row justify-content-center'>
        <Form.Control
          id='one'
          ref={getRef('one')}
          type='text'
          autoComplete='off'
          autoFocus
          value={otp['one']}
          tabIndex={0}
          onChange={event => handlerOnChange(event.nativeEvent)}
          className={`${validateResultOne?.hasError ? 'is-invalid' : ''} me-3`} />
        <Form.Control
          id='two'
          ref={getRef('two')}
          type='text'
          autoComplete='off'
          value={otp['two']}
          tabIndex={1}
          onChange={event => handlerOnChange(event.nativeEvent)}
          className={`${validateResultTwo?.hasError ? 'is-invalid' : ''} me-3`} />
        <Form.Control
          id='three'
          ref={getRef('three')}
          type='text'
          autoComplete='off'
          value={otp['three']}
          tabIndex={2}
          onChange={event => handlerOnChange(event.nativeEvent)}
          className={`${validateResultThree?.hasError ? 'is-invalid' : ''} me-3`} />
        <Form.Control
          id='four'
          ref={getRef('four')}
          type='text'
          autoComplete='off'
          value={otp['four']}
          tabIndex={3}
          onChange={event => handlerOnChange(event.nativeEvent)}
          className={`${validateResultFour?.hasError ? 'is-invalid' : ''} me-3`} />
        <Form.Control
          id='five'
          ref={getRef('five')}
          type='text'
          autoComplete='off'
          value={otp['five']}
          tabIndex={4}
          onChange={event => handlerOnChange(event.nativeEvent)}
          className={`${validateResultFive?.hasError ? 'is-invalid' : ''} me-3`} />
        <Form.Control
          id='six'
          ref={getRef('six')}
          type='text'
          autoComplete='off'
          value={otp['six']}
          tabIndex={5}
          onChange={event => handlerOnChange(event.nativeEvent)}
          className={`${validateResultSix?.hasError ? 'is-invalid' : ''}`} />
        <Form.Control.Feedback type='invalid'>
          {getMessageError}
        </Form.Control.Feedback>
      </InputGroup>
      <input type='hidden' value={value} name={props.name} className='control' />
    </div>
  );
}

const mapStateToProps = (state: FormState) => state.formStore;

const Otp = connect(mapStateToProps)(Control);

export default Otp;