import { Modal } from 'components';
import { ModalSize } from 'utils';
import Team from './Team';
import { ClubMember } from '../../../models';

interface Props {
  show: boolean;
  onHide: () => void;
  onHideAfterSave: () => void;
  clubId: string;
  clubMember: ClubMember[]
}

export default function AddClubTeam(props: Props) {
  const onHideTeam = () => {
    props.onHideAfterSave();
  };

  return (
    <Modal size={ModalSize.LG} show={props.show} onClose={() => props.onHide()}>
      <Modal.Body>
        <Team
          clubId={props.clubId}
          clubMember={props.clubMember}
          onHide={() => onHideTeam()} />
      </Modal.Body>
    </Modal>
  );
}