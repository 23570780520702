import { useEffect } from 'react';
import { Col, Row, Container, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export function Footer() {
  const { t } = useTranslation('footer');
  const year = '2023';

  function createWater() {
    const water = document.querySelector('.water') as HTMLElement;
    water.style.top = Math.random() * 150 + 'px';
    water.style.left = Math.random() * 100 + '%';

    const animContainer = document.querySelector('.animation-water');
    let waterClone: HTMLElement | null = null;

    if (animContainer) {
      waterClone = water.cloneNode(true) as HTMLElement;
      animContainer.appendChild(waterClone);
    }

    setTimeout(() => {
      if (waterClone) {
        waterClone.remove();
      }
    }, 8000);
  }

  useEffect(() => {
    const water = document.querySelector('.water') as HTMLElement;
    let myHTML = '';

    for (let i = 0; i < 6; i++) {
      myHTML += '<div class="bubbles"></div>';
    }
    water.innerHTML = myHTML;
    setInterval(createWater, 8000);
  });

  return (
    <div className='custom-footer'>
      <Container className='pt-5 pb-3'>
        <Row>
          <Col className='mb-lg-3 text-center text-lg-start pt-5' xs={12}>
            <h3><span className='text-primary'>E</span>sports</h3>
            <p className='footer-detail pt-2'>
              <span className='fs-6'>Version 1.1.9</span>
            </p>
          </Col>
        </Row>
        <Row className='align-items-end'>
          <Col className='mt-lg-0 text-center text-lg-start pb-3' xs={12} lg={6}>
            <a target='_blank' href="#" rel='noopener noreferrer'>
              <Image src='/icons/facebook.png' alt='facebook-icon' className='mx-3' width={15} />
            </a>
            <a target='_blank' href="#" rel='noopener noreferrer'>
              <Image src='/icons/twitter.png' alt='twitter-icon' className='mx-3' width={30} />
            </a>
          </Col>
          <Col className='mt-lg-0' xs={12} lg={6}>
            <div className='d-flex justify-content-lg-end justify-content-center mb-3'>
              <a
                href="https://forms.gle/9WMwUiAdRo72VGAA7"
                target='_blank'
                className='btn btn-primary'
                rel='noopener noreferrer'>
                <Image src='/warning.png' className='me-2' width={23} />
                {t('report')}
              </a>
            </div>
            <ul className='d-flex justify-content-lg-end justify-content-center conditions mb-0'>
              <li className='border-end border-white pe-2 pe-md-3'>
                <a href='#' target='_blank' className='pointer text-white'>{t('termsTitle')}</a>
              </li>
              <li className='mx-md-3 pe-2 pe-md-3 ps-1'>
                <a href='#' target='_blank' className='pointer text-white'>{t('privacyTitle')}</a>
              </li>
              <li>
                © {year} Code(Hard);
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <div className="animation-water">
        <div className="container">
          <div className="water" />
        </div>
      </div>
    </div>
  );
}