import { InfoCard } from 'components';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface Props {
  data: any[];
}

export default function CardPlayer({ data }: Props) {
  const navigate = useNavigate();

  return (
    <Row>
      {data.map((data, i) =>
        <Col key={i} sm='6' md='4' lg='3' className='mb-3'>
          <InfoCard
            onClick={() => navigate(`/club/detail/${data.id}`)}
            img={data.image}
            fullName={data.clubName}
            nickname={data.nickName}
            nationality={data.userNationality} />
        </Col>
      )}
    </Row>
  );
}