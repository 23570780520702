import { Button, Image, Tab, Nav } from 'react-bootstrap';
import Table from 'components/Table/TablePlayer';
import { Pagination } from 'components/Pagination';
import { HISTORY_GAME, TABLE_HISTORY_COLUMNS, TABLE_HISTORY_ROW } from '../../data/makeDataHistory';
import { Breadcrumb } from 'components/Breadcrumb';
import { FiEdit2 } from 'react-icons/fi';
import { MdOutlineAdd } from 'react-icons/md';

const BREADCRUMB = [
  { label: 'โปรไฟล์ของฉัน', path: '/profile' },
  { label: 'แก้ไขประวัติ', path: '/history' },
];

export function History() {
  const paginationOnChange = (value: number) => {
    value;
  };

  const renderBodyTable = () => {
    return TABLE_HISTORY_ROW.map((data, index) => (
      <tr key={index}>

        <td className='text-start text-nowrap'>{data.year}</td>
        <td className='text-start text-nowrap'>{data.name}</td>
        <td className='text-nowrap'>
          {data.logoGame ?
            <Image alt={data.logoGame} className='me-2' src={data.logoGame} width={40} height={40} />
            : null}
          {data.game}
        </td>
        <td className='text-center text-nowrap'>{data.type}</td>
        <td className='text-center text-nowrap'>{data.results}</td>
        <td className='text-end text-nowrap'>{data.score}</td>
        <td className='text-center text-nowrap'>{data.playingPosition}</td>
        <td className='text-center text-nowrap'>{data.heroName}</td>
        <td><Button className='ms-1' variant='link'><FiEdit2 /></Button></td>
      </tr>
    ));
  };

  return (
    <>
      <Breadcrumb className='my-3' menus={BREADCRUMB} />
      <div className='text-center mb-5'>
        <h3 className='text-gradient-light'>ประวัติ</h3>
      </div>
      <Tab.Container defaultActiveKey={1}>
        <Nav className='nav-games no-img' variant='pills'>
          {HISTORY_GAME.map((data: any, index) => (
            <Nav.Item key={index}>
              <Nav.Link eventKey={data.id}>
                <div className='content'>
                  <div className='d-flex flex-column align-items-center'>
                    {data.name}
                  </div>
                </div>
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
        <div className='d-block text-end my-3'>
          <Button variant='primary'><MdOutlineAdd className='me-2' />เพิ่ม</Button>
        </div>
        <Tab.Content>
          {HISTORY_GAME.map((data: any, index) => (
            <Tab.Pane key={index} eventKey={data.id}>
              <Table columns={TABLE_HISTORY_COLUMNS} rows={renderBodyTable()} />
            </Tab.Pane>
          ))}
          <div className='d-flex justify-content-center my-3'>
            <Pagination totalItems={100} onChange={paginationOnChange} />
          </div>
        </Tab.Content>
      </Tab.Container>
    </>
  );
}

History.RequestAuth = true;